import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import LogoListUi from "../../ui/LogoList";
import { LogoListBlock } from "../../lib/storyblok/types/blocks/LogoListBlock";
import ImageVaultImage from "../ImageVaultImage";
import useMediaQuery from "../../hooks/useMatchMedia";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";

const { List, Logo } = LogoListUi;

const Agenda: StoryblokBlockComponent<LogoListBlock> = ({
  title,
  logos,
  scrollOnDesktop,
  spacing,
}) => {
  const isDesktop = useMediaQuery("(max-width: 1024px)");

  return (
    <LogoListUi title={title} spacing={spacing}>
      <List shouldScroll={isDesktop || scrollOnDesktop}>
        {logos?.map((logo) => (
          <Logo
            key={logo._uid}
            logo={
              isValidImageData(logo.logo) ? (
                <ImageVaultImage
                  image={logo.logo}
                  layout="fill"
                  sizes={imageSizes("240px")}
                  objectFit="contain"
                  alt={logo.title}
                />
              ) : null
            }
          />
        ))}
      </List>
    </LogoListUi>
  );
};

export default Agenda;
