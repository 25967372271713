import React, { ElementType, HTMLProps } from "react";
import s from "../SearchHits.module.scss";
import cn from "classnames";

type HitImageProps = HTMLProps<HTMLDivElement> & {
  as?: ElementType;
};

const HitImage = ({ className, ...props }: HitImageProps) => (
  <div {...props} className={cn(className, s.imageContainer)} />
);

export default HitImage;
