import React, { useCallback, useMemo, useState, useEffect } from "react";
import Image from "next/image";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import {
  InteractiveGridBlock,
  InteractiveGridItemBlock,
} from "../../lib/storyblok/types/blocks/InteractiveGridBlock";
import Button from "../../ui/Button";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";
import InteractiveGridUi from "../../ui/InteractiveGrid";
import useMediaQuery from "../../hooks/useMatchMedia";
import { useDeviceDetect } from "../../hooks/useTouchDevice";
import ImageVaultImage from "../ImageVaultImage";
import s from "../../ui/InteractiveGrid/InteractiveGrid.module.scss";
import { useContentStyle } from "../../hooks/useStyle";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";
import videoThumbnailFallback from "../../public/images/chapter-divider-02.png";
import VideoPlayer from "../../ui/Common/VideoPlayer";

const InteractiveGrid: StoryblokBlockComponent<InteractiveGridBlock> = ({
  title,
  description,
  buttons,
  innerSpacing,
  outerSpacing,
  style,
  blockStyle,
  gridDesktopColumnCount,
  items,
}) => {
  const isIntroInverted = useContentStyle(style);
  const isBlockInverted = useContentStyle(blockStyle);
  const resolveLink = useLinks();
  const { isTouchDevice } = useDeviceDetect();
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const [isGridReady, setIsGridReady] = useState(false);
  const isMobile = useMediaQuery("(max-width: 480px)");
  const isTablet = useMediaQuery("(min-width: 481px) and (max-width:900px)");
  const isLaptop = useMediaQuery("(min-width: 901px) and (max-width:1200px)");

  const itemsPerRow = useMemo(() => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    switch (Number(gridDesktopColumnCount)) {
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
        if (isLaptop) return 3;
        return 4;
      default:
        if (isLaptop) return 3;
        return 4;
    }
  }, [isMobile, isTablet, isLaptop, gridDesktopColumnCount]);

  useEffect(() => {
    if (!isTouchDevice && itemsPerRow) {
      const timer = setTimeout(() => {
        setIsGridReady(true);
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isTouchDevice, itemsPerRow]);

  const gridRows = useMemo(() => {
    if (isTouchDevice) return null;

    const rows: InteractiveGridItemBlock[][] = [];
    for (let i = 0; i < items.length; i += itemsPerRow) {
      rows.push(items.slice(i, i + itemsPerRow));
    }
    return rows;
  }, [items, itemsPerRow, isTouchDevice]);

  const renderButtons = useCallback(
    () =>
      buttons?.map((button) => (
        <Link
          key={button._uid}
          href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
          passHref
        >
          <Button
            variant={button.buttonType ?? "default"}
            href={resolveLink(button.buttonLink)}
          >
            {button.buttonText}
          </Button>
        </Link>
      )),
    [buttons, resolveLink]
  );

  return (
    <InteractiveGridUi
      title={title}
      description={description}
      buttons={renderButtons()}
      style={style}
      blockStyle={blockStyle}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      inverted={isIntroInverted}
    >
      {isTouchDevice ? (
        <div
          key={`grid-touch-${itemsPerRow}`}
          className={s.mobileGrid}
          data-columns={itemsPerRow}
        >
          {items.map((item, index) => (
            <InteractiveGridUi.ItemTouch
              key={item._uid}
              index={index}
              style={blockStyle}
              title={item.title}
              description={item.description}
              link={resolveLink(item.link)}
              image={
                item.image && isValidImageData(item.image) ? (
                  <ImageVaultImage
                    image={item.image}
                    layout="fill"
                    sizes={imageSizes("720px")}
                    objectFit="cover"
                    priority
                    alt={item.title}
                  />
                ) : null
              }
              inverted={isBlockInverted}
            />
          ))}
        </div>
      ) : isGridReady ? (
        <div
          key={`grid-nontouch-${itemsPerRow}`}
          className={s.desktopGrid}
          data-items-per-row={itemsPerRow}
        >
          {gridRows?.map((row, rowIndex) => (
            <div key={rowIndex} className={s.row}>
              {row.map((item, columnIndex) => (
                <InteractiveGridUi.ItemNonTouch
                  key={`${item._uid}-${itemsPerRow}`}
                  style={blockStyle}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  link={resolveLink(item.link)}
                  rowIndex={rowIndex}
                  columnIndex={columnIndex}
                  isHovered={hoveredItemId === item._uid}
                  hasHoveredItemInRow={
                    hoveredItemId !== null &&
                    row.some((rowItem) => rowItem._uid === hoveredItemId)
                  }
                  onHoverChange={(isHovered) => {
                    setHoveredItemId(isHovered ? item._uid : null);
                  }}
                  image={
                    item.image && isValidImageData(item.image) ? (
                      <ImageVaultImage
                        image={item.image}
                        layout="fill"
                        sizes="720px"
                        objectFit="cover"
                        priority
                        alt={item.title}
                      />
                    ) : null
                  }
                  video={
                    item.video?.filename && (
                      <VideoPlayer
                        key="video"
                        videoUrl={item.video?.filename}
                        thumbnail={
                          item.image && isValidImageData(item.image) ? (
                            <ImageVaultImage
                              image={item.image}
                              layout="fill"
                              sizes="100vw"
                              objectFit="cover"
                              priority
                            />
                          ) : (
                            <Image
                              src={videoThumbnailFallback}
                              layout="fill"
                              objectFit="cover"
                            />
                          )
                        }
                        thumbnailMode="thumbnailOnly"
                        isActive={hoveredItemId === item._uid}
                      />
                    )
                  }
                  inverted={isBlockInverted}
                  visualType={item.video?.filename ? "video" : "image"}
                />
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </InteractiveGridUi>
  );
};

export default InteractiveGrid;
