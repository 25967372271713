import React, { ReactNode, useCallback, useRef } from "react";
import cn from "classnames";
import s from "./Header.module.scss";
import c from "../../styles/container.module.scss";

type Props = {
  isDesktopSubNavOpen: boolean;
  isTobiiGroup?: boolean;
  activeSubNav: ReactNode;
  children: ReactNode;
  onCloseSubNav: () => void;
  stickToTheTop?: boolean;
};

const Header = ({
  isTobiiGroup,
  children,
  activeSubNav,
  isDesktopSubNavOpen,
  onCloseSubNav,
  stickToTheTop,
}: Props) => {
  const headerRef = useRef<HTMLElement>(null);

  const handleOverlayInteraction = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      if (
        event.type === "click" ||
        (event as React.KeyboardEvent).key === "Enter"
      ) {
        onCloseSubNav();
      }
    },
    [onCloseSubNav]
  );

  return (
    <>
      <header
        ref={headerRef}
        className={cn(s.header, {
          [s.tobiiGroup]: isTobiiGroup,
          [s.stickToTheTop]: stickToTheTop,
        })}
        data-tobii-group={isTobiiGroup ? "true" : undefined}
      >
        <div className={cn(s.container, c.xxWide)}>{children}</div>

        {activeSubNav}
      </header>
      {isDesktopSubNavOpen && (
        <div
          className={s.subNavOverlay}
          onClick={handleOverlayInteraction}
          onKeyDown={handleOverlayInteraction}
          tabIndex={0}
          role="button"
          aria-label="Close"
        />
      )}
    </>
  );
};

export default Header;
