/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { GatedFormBlock } from "../../lib/storyblok/types/blocks/GatedFormBlock";
import cn from "classnames";
import Image from "next/image";
import { useInView } from "react-intersection-observer";
import s from "../../ui/CTAForm/CTAForm.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import c from "../../styles/container.module.scss";
import { DynamicBlocks } from "../DynamicBlock";
import swooshDefaultTop from "../../public/images/divider-block-swoosh-default-top.png";
import swooshDefaultBottom from "../../public/images/divider-block-swoosh-default-bottom.png";
import swooshInvertedTop from "../../public/images/divider-block-swoosh-light-top.png";
import swooshInvertedBottom from "../../public/images/divider-block-swoosh-light-bottom.png";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";
import ImageVaultImage from "../ImageVaultImage";
import { useContentStyle } from "../../hooks/useStyle";
import BlockBackground from "../../ui/Common/BlockBackground";
import BlockIntro from "../../ui/Common/BlockIntro";
import Loader from "../../ui/Common/Loader";

const GatedForm: StoryblokBlockComponent<GatedFormBlock> = ({
  _uid,
  block,
  text,
  title,
  copy,
  image,
  formUrl,
  spacing,
  style,
  divider,
}) => {
  const contentStyle = useContentStyle(style);
  const isInverted = style === "" || style === undefined ? false : contentStyle;
  const [submitted, setSubmitted] = useState(false);
  const [presubmittedId, setPresubmittedId] = useState<string | null>(null);
  const spacingNormalized = spacing === undefined ? "both" : spacing;
  const [height, setHeight] = useState(1000);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [resizeAttempts, setResizeAttempts] = useState(0);
  const maxResizeAttempts = 3;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { ref: formRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "300px",
  });

  let originUrl = "";
  if (formUrl) {
    try {
      originUrl = new URL(formUrl).origin;
    } catch (error) {
      console.error("Invalid formUrl:", formUrl);
    }
  }

  // Event handler for postMessages
  const onMessage = useCallback(
    (message) => {
      if (message.data.type === "pardotFormPreSubmit" && message.data.id) {
        if (!presubmittedId) {
          setPresubmittedId(message.data.id);
        }
      }

      if (message.data.type === "pardotFormSubmit" && presubmittedId) {
        setSubmitted(true);
        setPresubmittedId(null);
      }

      if (message.origin !== originUrl && !message.origin.includes("localhost"))
        return;

      const resizeTypes = [
        `pardotFormResize-${formUrl}#${_uid}`,
        `ctaFormResize-${formUrl}#${_uid}`,
        `pardotFormResize-${formUrl}`,
        `ctaFormResize-${formUrl}`,
      ];

      const isResizeMessage =
        resizeTypes.some(
          (type) =>
            message.data.type && message.data.type.includes(type.split("#")[0])
        ) && typeof message.data.height === "number";

      if (isResizeMessage) {
        setHeight(message.data.height + 2);
        setIsFormLoaded(true);
        setResizeAttempts(0);
      }
    },
    [presubmittedId, _uid, formUrl, originUrl]
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    if (inView && formUrl) {
      const baseUrl = `${formUrl}#${_uid}`;
      setIframeUrl(baseUrl);

      if (typeof window !== "undefined") {
        const timer = setTimeout(() => {
          const fullPath = window.location.pathname
            .toLowerCase()
            .replace(/^\//, "");

          const separator = formUrl.includes("?") ? "&" : "?";
          const urlWithParams = `${formUrl}${separator}converting_page=${encodeURIComponent(
            fullPath
          )}#${_uid}`;

          setIframeUrl(urlWithParams);
        }, 2000);

        return () => clearTimeout(timer);
      }
    }
  }, [inView, formUrl, _uid]);

  useEffect(() => {
    if (
      inView &&
      iframeRef.current &&
      !isFormLoaded &&
      resizeAttempts < maxResizeAttempts
    ) {
      const timer = setTimeout(() => {
        try {
          if (iframeRef.current && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
              { type: "requestResize" },
              "*"
            );
          }
        } catch (e) {}

        const adaptiveHeights = [500, 700, 1000];
        setHeight(
          adaptiveHeights[resizeAttempts] ||
            adaptiveHeights[adaptiveHeights.length - 1]
        );

        setResizeAttempts((prev) => prev + 1);

        if (resizeAttempts === maxResizeAttempts - 1) {
          setIsFormLoaded(true);
        }
      }, 1000 + resizeAttempts * 1000);

      return () => clearTimeout(timer);
    }
  }, [inView, isFormLoaded, resizeAttempts, iframeUrl]);

  const handleIframeLoad = () => {
    const timer = setTimeout(() => {
      if (!isFormLoaded) {
        try {
          if (iframeRef.current && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
              { type: "requestResize" },
              "*"
            );
          }
        } catch (e) {}

        setHeight(700);

        setResizeAttempts(1);
      }
    }, 500);

    return () => clearTimeout(timer);
  };

  return !submitted ? (
    <div className={cn(s.ctaForm, bs[spacingNormalized], s[style])}>
      <BlockBackground style={style} opacity={100} />

      {divider === "swooshBlueTop" || divider === "swooshBlueBoth" ? (
        <div className={s.topImage}>
          <Image
            src={isInverted ? swooshInvertedTop : swooshDefaultTop}
            alt="Swoosh Top"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}

      <div className={cn(c.wide, s.wrapper)}>
        <div className={cn(s.content)}>
          <BlockIntro
            alignment={"alignLeft"}
            inverted={isInverted}
            title={title}
            description={text}
            copy={copy}
            width={"full"}
            spaceHorizontal={false}
          />
          {image ? (
            <div className={cn(s.imageContainer)}>
              <>
                {isValidImageData(image) ? (
                  <ImageVaultImage
                    sizes={imageSizes("720px")}
                    image={image}
                    layout="intrinsic"
                    contain
                  />
                ) : null}
              </>
            </div>
          ) : null}
        </div>

        {formUrl && (
          <div className={cn(s.form)} ref={formRef}>
            <div>
              {inView ? (
                <iframe
                  title={formUrl}
                  ref={iframeRef}
                  width="100%"
                  height={height}
                  src={iframeUrl}
                  onLoad={handleIframeLoad}
                  style={{
                    opacity: isFormLoaded ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                    minHeight: "400px",
                  }}
                />
              ) : null}
              {(!inView || !isFormLoaded) && (
                <div
                  className={cn(s.loadingContainer)}
                  style={{
                    minHeight: inView ? "400px" : "auto",
                  }}
                >
                  <Loader variant="default" size="medium" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {divider === "swooshBlueBottom" || divider === "swooshBlueBoth" ? (
        <div className={s.bottomImage}>
          <Image
            src={isInverted ? swooshInvertedBottom : swooshDefaultBottom}
            alt="Swoosh Bottom"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}
    </div>
  ) : (
    <DynamicBlocks blocks={block ?? []} />
  );
};

export default GatedForm;
