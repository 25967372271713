import React from "react";
import dynamic from "next/dynamic";

const DynamicSinglePressRelease = dynamic(
  () => import("../../ui/SinglePressRelease"),
  {
    ssr: false,
  }
);

const PressreleasePage = ({ language }) => {
  return <DynamicSinglePressRelease language={language} />;
};

export default PressreleasePage;
