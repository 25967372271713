import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./TabsGrid.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import type { StoryblokRichTextFieldtype } from "../../lib/storyblok/types/fieldtypes/richText";

import BlockIntro from "../Common/BlockIntro";
import Tab from "./components/Tab";
import TabHeader from "./components/TabHeader";
import TabGrid from "./components/TabGrid";
import TabGridItem from "./components/TabGridItem";

type Props = {
  title: string;
  description: StoryblokRichTextFieldtype;
  buttons?: ReactNode;
  children: ReactNode;
  outerSpacing: string;
  width: string;
};

const subComponents = {
  Tab,
  TabHeader,
  TabGrid,
  TabGridItem,
};
type SubComponents = typeof subComponents;

const TabsGridUi: CWS<Props, SubComponents> = ({
  title,
  buttons,
  children,
  outerSpacing,
  width,
}) => {
  return (
    <section className={cn(s.tabsGrid, bs[outerSpacing], c[width])}>
      <div className={s.tabsGridInner}>
        <BlockIntro
          inverted={false}
          title={title}
          buttons={buttons}
          alignment={"alignCenter"}
          description={""}
        />

        <div className={s.tabsContent}>{children}</div>
      </div>
    </section>
  );
};

TabsGridUi.Tab = Tab;
TabsGridUi.TabHeader = TabHeader;
TabsGridUi.TabGrid = TabGrid;
TabsGridUi.TabGridItem = TabGridItem;

export default TabsGridUi;
