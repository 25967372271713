import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./WithSidebar.module.scss";
import c from "../../../styles/container.module.scss";

import Sidebar from "./components/Sidebar";

type Props = {
  children: ReactNode;
};

const subComponents = { Sidebar };
type SubComponents = typeof subComponents;

const WithSidebar: CWS<Props, SubComponents> = ({ children }) => (
  <section className={cn(c.wide, s.withSidebar)}>{children}</section>
);

WithSidebar.Sidebar = Sidebar;

export default WithSidebar;
