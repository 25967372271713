import s from "../Tags.module.scss";
import b from "../../../ui/Button/Button.module.scss";
import cn from "classnames";

type Props = {
  tag: string;
  url?: string;
  style?: "default" | "gray";
};

const Tag = ({ tag, url, style }: Props) => (
  <li>
    {url ? (
      <a
        href={url}
        title={tag}
        className={cn(b.button, b.tag, {
          [b.gray]: style === "gray",
        })}
      >
        {tag}
      </a>
    ) : (
      <span className={s.tag}>{tag}</span>
    )}
  </li>
);

export default Tag;
