import React, { useEffect, useMemo, useState } from "react";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { DynamicBlocks } from "../DynamicBlock";
import FilterPageGrid from "../../ui/PageTemplates/FilterPage";
import Button from "../../ui/Button";
import { useTranslation } from "next-i18next";
import { ImagevaultGalleryPageStory } from "../../lib/storyblok/types/stories/ImagevaultGalleryPageStory";
import ImageWrapper from "../../ui/MediaBank";
import FilterList from "../../ui/FilterList";
import ContentGrid from "../../ui/ContentGrid";
import { ImageVaultGalleryPageViewModelExtension } from "../../lib/viewModel/handleImagevaultGalleryStory";
import { staticTags } from "../../ui/MediaBank/components/staticTags";
import Pagination from "../../ui/Pagination";
import ImageVaultImage from "../ImageVaultImage";
import { imageSizes } from "../../lib/utils/imageUtils";
import SearchBox from "../Nestable/SearchBox";

type VM = ViewModel<ImagevaultGalleryPageStory> &
  ImageVaultGalleryPageViewModelExtension;

const ImagevaultGalleryPage: StoryblokStoryComponent<VM> = ({ story }) => {
  const { hero, body, imageWrapper } = story.content;
  const { t } = useTranslation(["searchpage", "common", "mediaBank"]);

  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const [keyword, setKeyword] = useState("");

  const filteredImageWrapper = useMemo(() => {
    const filteredItems = imageWrapper?.filter((item) => {
      const tagMatch =
        activeFilters.length === 0 ||
        activeFilters.some((filter) => item.taggedMediaBank?.includes(filter));

      const keywordMatch =
        keyword.trim() === "" ||
        item.image?.alt?.toLowerCase().includes(keyword.toLowerCase());

      return tagMatch && keywordMatch;
    });
    return filteredItems;
  }, [activeFilters, imageWrapper, keyword]);

  const categoriesWithCount = staticTags
    .map<[string, number]>((tag) => [
      tag,
      filteredImageWrapper?.filter((gi) => gi.taggedMediaBank?.includes(tag))
        .length,
    ])
    .filter(
      ([category, count]) => activeFilters.includes(category) || count > 0
    )
    .sort(([catA]) => (activeFilters.includes(catA) ? 0 : 1))
    .sort(
      ([catA, countA], [catB, countB]) =>
        countB * 10 - countA * 10 + catA.localeCompare(catB)
    );

  const pageCount = Math.ceil(filteredImageWrapper?.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [activeFilters]);

  const currentPageImages = filteredImageWrapper?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
    setCurrentPage(1);
  };

  const currentRefinements = useMemo(() => {
    const refinements = [...activeFilters];
    if (keyword.trim() !== "") {
      refinements.push("search");
    }
    return refinements;
  }, [activeFilters, keyword]);

  // Update your clearAll function
  const clearRefinements = () => {
    setActiveFilters([]);
  };

  const clearAll = () => {
    clearRefinements();
    setKeyword("");
  };

  return (
    <>
      {hero && <DynamicBlocks blocks={hero} />}
      <FilterPageGrid>
        <FilterPageGrid.Preamble>
          {body && <DynamicBlocks blocks={body} />}
        </FilterPageGrid.Preamble>

        <FilterPageGrid.Toolbar layout="altOne">
          <FilterPageGrid.Input>
            <SearchBox
              defaultValue={keyword}
              type="small"
              customOnChange={handleKeywordChange}
              customOnClear={() => setKeyword("")}
              useInstantSearch={false}
            />
          </FilterPageGrid.Input>
          <FilterPageGrid.Filter>
            <FilterList label={t(`category`, { ns: "common" })}>
              {categoriesWithCount.map(([category, count]) => {
                const checked = activeFilters.includes(category);
                return (
                  <FilterList.Filter
                    key={category}
                    value={category}
                    amount={count}
                    checked={checked}
                    onChange={() => {
                      setActiveFilters((prev) =>
                        checked
                          ? prev.filter((c) => c !== category)
                          : [...prev, category]
                      );
                    }}
                  >
                    {t(category, { ns: "common" })}
                  </FilterList.Filter>
                );
              })}
            </FilterList>
          </FilterPageGrid.Filter>
        </FilterPageGrid.Toolbar>
        <FilterPageGrid.Status>
          <FilterPageGrid.Heading
            clearLabel={currentRefinements.length > 0 ? t("clear-all") : ""}
            onClearClick={clearAll}
          />
          <FilterPageGrid.NumberOfHits>
            {t("number-of-hits_other", { count: filteredImageWrapper?.length })}
          </FilterPageGrid.NumberOfHits>
        </FilterPageGrid.Status>
        <FilterPageGrid.Body>
          <ContentGrid spacing="noTop" container="fluid">
            {currentPageImages?.map(({ image, taggedMediaBank, _uid }) => {
              const imageUrl = image?.filename || "";
              const imageAlt = image?.alt || "";

              return (
                <div key={_uid}>
                  <ImageWrapper
                    title={imageAlt}
                    taggedMediaBank={taggedMediaBank}
                    setActiveFilters={setActiveFilters}
                  >
                    <ImageWrapper.SingleImage
                      image={
                        <ImageVaultImage
                          sizes={imageSizes("100vw", { desktop: "50vw" })}
                          image={image}
                          layout="fill"
                        />
                      }
                    />
                  </ImageWrapper>
                  <Button
                    href={imageUrl}
                    download
                    size="small"
                    variant="default"
                  >
                    {t("download", { ns: "common" })}
                  </Button>
                </div>
              );
            })}
          </ContentGrid>
          {pageCount > 1 && (
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              labelPrevious={t("previous")}
              labelNext={t("next")}
              labelPage={t("page")}
            />
          )}
        </FilterPageGrid.Body>
      </FilterPageGrid>
    </>
  );
};

export default ImagevaultGalleryPage;
