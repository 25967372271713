import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import TestimonialsUi from "../../ui/Testimonials";
import { TestimonialsBlock } from "../../lib/storyblok/types/blocks/TestimonialsBlock";

const Testimonials: StoryblokBlockComponent<TestimonialsBlock> = ({
  title,
  spacing,
  testimonials,
}) => {
  return (
    <TestimonialsUi
      title={title}
      spacing={spacing}
      testimonials={testimonials}
    />
  );
};

export default Testimonials;
