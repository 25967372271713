/* eslint-disable */
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import ListPressReleases from "../../ui/ListPressReleases";
import { DynamicBlocks } from "../DynamicBlock";

const PressreleaseFinderPage = ({ story }) => {
  const router = useRouter();
  const { hero } = story.content;

  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;

      if (data.type === "from_single_iframe") {
        const path = router.asPath.split("?s=")[0];
        const slug = router.asPath.split("?s=")[1];
        const updatedPath = path
          .replace(/^\/_sites/, "")
          .replace(/\/investor/, "");

        /*
        console.log("Full router:", {
          asPath: router.asPath,
          pathname: updatedPath,
          slug: slug,
        });
        */

        router.push(
          {
            pathname: updatedPath,
            query: { s: slug },
          },
          undefined
        );
      }

      // Back to homepage from Disclaimer
      if (data.type === "disclaimer_back_click") {
        const path = router.locale === "sv" ? "/sv" : "/";
        router.push(path);
      }

      // Resolve the issue with opening links in new tab
      if (data.type === "navigate") {
        router.push(data.url);
      }

      // Handle disclaimer acceptance and iframe reload
      if (data.type === "disclaimer_accepted" && data.slug === router.query.s) {
        setTimeout(() => {
          const iframe = document.querySelector("iframe");
          iframe?.contentWindow?.location.reload();
        }, 500);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [router]);

  return (
    <div
      key={
        Array.isArray(router.query.s)
          ? router.query.s.join(",")
          : router.query.s || "default"
      }
    >
      <DynamicBlocks blocks={hero} />
      <ListPressReleases />
    </div>
  );
};

// Prevent caching single press release pages
export async function getServerSideProps(context) {
  const urlPath = context.req.url || "";

  if (
    urlPath.startsWith("/newsroom/press-releases?s=") ||
    urlPath.startsWith("/sv/media/pressmeddelanden?s=")
  ) {
    const cacheControlHeader = "no-store, no-cache, must-revalidate, max-age=0";
    context.res.setHeader("Cache-Control", cacheControlHeader);

    const response = await fetch(`https://corporate.tobii.com${urlPath}`, {
      headers: { "Cache-Control": cacheControlHeader },
    });

    const data = await response.json();

    return {
      props: { story: data },
    };
  }

  const response = await fetch(`https://corporate.tobii.com${urlPath}`);
  const data = await response.json();

  return {
    props: { story: data },
  };
}

export default PressreleaseFinderPage;
