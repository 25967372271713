import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";
import c from "../../../../styles/container.module.scss";
import Icon from "../../../Icon";
import TobiiLogo from "../../../Logos/TobiiLogo";
import { useTranslation } from "next-i18next";

type Props = {
  onLangClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSearchClick?: React.MouseEventHandler<HTMLAnchorElement>;
  onLogoClick?: React.MouseEventHandler<HTMLAnchorElement>;
  searchHref?: string;
  isTobiiGroup?: boolean;
  children: ReactNode;
  stickToTheTop?: boolean;
};

export const DesktopMenu = ({
  onLangClick,
  onSearchClick,
  onLogoClick,
  isTobiiGroup = false,
  searchHref,
  children,
  stickToTheTop,
}: Props) => {
  const siteTitle = isTobiiGroup ? "Tobii Corporate" : "Tobii";
  const { t } = useTranslation();

  return (
    <div
      className={cn(s.desktopMenu, {
        [s.tobiiGroup]: isTobiiGroup,
        [s.stickToTheTop]: stickToTheTop,
      })}
      data-tobii-group={isTobiiGroup ? "true" : undefined}
    >
      <div className={cn(c.xxWide, s.wrapper)}>
        <a
          href="/"
          onClick={onLogoClick}
          title={siteTitle}
          className={cn(s.logo, { [s.logoCorporate]: isTobiiGroup })}
        >
          <span className="visuallyHidden">{t("home")}</span>
          <TobiiLogo color={isTobiiGroup ? s.iconColorInverted : s.iconcolor} />
          {isTobiiGroup && <span>Corporate</span>}
        </a>
        {children}
        <div className={s.langAndSearch}>
          <button onClick={onLangClick} title={t("change-language")}>
            <span className="visuallyHidden">{t("change-language")}</span>
            <Icon
              icon="world"
              width={18}
              color={isTobiiGroup ? s.iconColorInverted : s.iconColor}
            />
          </button>
          <a
            href={searchHref}
            onClick={onSearchClick}
            className={s.searchIcon}
            title={t("search")}
          >
            <span className="visuallyHidden">{t("search")}</span>
            <Icon
              icon="search"
              color={isTobiiGroup ? s.iconColorInverted : s.iconColor}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
