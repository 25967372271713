import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ImageWrapperUi from "../../ui/MediaBank";
import { ImageWrapperBlock } from "../../lib/storyblok/types/blocks/ImageWrapperBlock";

const ImageWrapper: StoryblokBlockComponent<ImageWrapperBlock> = ({
  title,
  taggedMediaBank,
  setActiveFilters,
}) => {
  return (
    <ImageWrapperUi
      title={title}
      taggedMediaBank={taggedMediaBank || []}
      setActiveFilters={setActiveFilters}
    />
  );
};

export default ImageWrapper;
