import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { HeaderSubNavigationPanel as PanelType } from "../../../lib/storyblok/types/blocks/header/HeaderSubNavigationPanel";
import {
  DesktopSubNavigationPanel,
  DesktopSubNavigationPanelList,
  DesktopSubNavigationPanelDiv,
  DesktopSubNavigationPanelLink,
  DesktopSubNavigationPanelItemWrapper,
  DesktopSubNavigationPanelItemWrapperInner,
  DesktopSubNavigationCTA,
  DesktopSubNavigationSidebar,
  DesktopSubNavigationGroup,
} from "../../../ui/Header";
import Image from "next/image";
import ImageVaultImage from "../../../components/ImageVaultImage";
import { useLinks } from "../../../lib/storyblok/useLinks";
import SubNavigationItemList from "./components/SubNavigationItemList";
import s from "../../../ui/Header/components/desktop/DesktopMenu.module.scss";
import { isValidImageData } from "../../../lib/utils/imageUtils";

type Props = {
  item: PanelType;
};

const HeaderSubNavigationPanel: React.FC<Props> = ({ item }) => {
  const resolveLink = useLinks();
  const [
    activeDesktopSubNavPanelItemIndex,
    setActiveDesktopSubNavPanelItemIndex,
  ] = useState<number>(0);
  const panelRefs = useRef<HTMLDivElement[]>([]);
  const [shouldAnimateSidebar, setShouldAnimateSidebar] = useState(false);
  const groupAnimationCount = useRef(0);

  const handleGroupAnimationComplete = () => {
    groupAnimationCount.current += 1;
    const totalGroups =
      item.items[activeDesktopSubNavPanelItemIndex]?.body?.length || 0;
    if (groupAnimationCount.current === totalGroups) {
      setShouldAnimateSidebar(true);
    }
  };

  useEffect(() => {
    setActiveDesktopSubNavPanelItemIndex(0);
    setShouldAnimateSidebar(false);
    groupAnimationCount.current = 0;
  }, [item]);

  const handleItemClick = (index: number) => {
    if (index !== activeDesktopSubNavPanelItemIndex) {
      if (
        item.items[index]?.body?.length ||
        item.items[index]?.cta?.length ||
        item.items[index]?.sidebar?.length
      ) {
        setActiveDesktopSubNavPanelItemIndex(index);
        setShouldAnimateSidebar(false);
        groupAnimationCount.current = 0;
      }
    }
  };

  const panelCount = item.items?.length || 0;

  const [highlightStyle, setHighlightStyle] = useState<React.CSSProperties>({
    top: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    const currentTab = panelRefs.current[activeDesktopSubNavPanelItemIndex];
    if (currentTab) {
      const { offsetTop, offsetHeight } = currentTab;
      setHighlightStyle({ top: offsetTop, height: offsetHeight });
    }
  }, [activeDesktopSubNavPanelItemIndex]);

  return (
    <DesktopSubNavigationPanel isSingleItem={panelCount === 1}>
      {panelCount > 1 && (
        <DesktopSubNavigationPanelList activeStyle={highlightStyle}>
          {item.items?.map((subItemList, i) => {
            const hasContent =
              !!subItemList.body?.length ||
              !!subItemList.cta?.length ||
              !!subItemList.sidebar?.length;
            return hasContent ? (
              <DesktopSubNavigationPanelDiv
                key={subItemList._uid}
                title={subItemList.title}
                onClick={() => handleItemClick(i)}
                isActive={activeDesktopSubNavPanelItemIndex === i}
                index={i}
                ref={(el) => (panelRefs.current[i] = el!)}
              />
            ) : (
              <DesktopSubNavigationPanelLink
                key={subItemList._uid}
                title={subItemList.title}
                link={resolveLink(subItemList.link)}
                index={i}
              />
            );
          })}
        </DesktopSubNavigationPanelList>
      )}
      <DesktopSubNavigationPanelItemWrapper>
        {item.items?.map((subItem, j) => {
          const hasContent =
            !!subItem.body?.length ||
            !!subItem.cta?.length ||
            !!subItem.sidebar?.length;
          return hasContent && activeDesktopSubNavPanelItemIndex === j ? (
            <DesktopSubNavigationPanelItemWrapperInner
              key={subItem._uid}
              hasSidebar={!!(subItem.cta?.length || subItem.sidebar?.length)}
            >
              <div>
                {subItem.body?.map((bodyItem, k) => (
                  <DesktopSubNavigationGroup
                    key={bodyItem._uid}
                    title={bodyItem.title}
                    description={bodyItem.description}
                    link={resolveLink(bodyItem.link)}
                    index={k}
                    icon={
                      bodyItem.icon ? (
                        <Image
                          src={`/svg/icons/${bodyItem.icon}.svg`}
                          width={16}
                          height={16}
                          alt={bodyItem.title}
                        />
                      ) : null
                    }
                    onGroupAnimationComplete={handleGroupAnimationComplete}
                  >
                    <SubNavigationItemList items={bodyItem.items || []} />
                  </DesktopSubNavigationGroup>
                ))}
              </div>
              <div className={s.sidebarWrapper}>
                {subItem.cta?.map((ctaItem) => (
                  <DesktopSubNavigationCTA
                    key={ctaItem._uid}
                    title={ctaItem.title}
                    description={ctaItem.description}
                    link={resolveLink(ctaItem.link)}
                    image={
                      isValidImageData(ctaItem.image) ? (
                        <ImageVaultImage
                          sizes="400px"
                          image={ctaItem.image}
                          layout="fill"
                        />
                      ) : null
                    }
                    animateSidebar={shouldAnimateSidebar}
                  />
                ))}
                {subItem.sidebar?.map((sidebarItem, m) => (
                  <DesktopSubNavigationSidebar
                    key={sidebarItem._uid}
                    title={sidebarItem.title}
                    icon={
                      sidebarItem.icon ? (
                        <Image
                          src={`/svg/icons/${sidebarItem.icon}.svg`}
                          width={16}
                          height={16}
                          alt={sidebarItem.title}
                        />
                      ) : null
                    }
                    animateSidebar={shouldAnimateSidebar}
                    index={m}
                  >
                    <SubNavigationItemList items={sidebarItem.items || []} />
                  </DesktopSubNavigationSidebar>
                ))}
              </div>
            </DesktopSubNavigationPanelItemWrapperInner>
          ) : null;
        })}
      </DesktopSubNavigationPanelItemWrapper>
    </DesktopSubNavigationPanel>
  );
};

export default HeaderSubNavigationPanel;
