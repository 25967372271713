import { mediaqueries } from "../../styles/mediaqueries";
import {
  ImageVaultFieldType,
  StoryblokAsset,
} from "../storyblok/types/fieldtypes/imagevaultFieldtype";

type Key = keyof typeof mediaqueries;
type ViewWidths = `${number}${"vw" | "px" | "em" | "rem"}`;
type Queries = Record<Key, ViewWidths>;
export type ImageSizes = string;

export const imageSizes = (
  maxSize: ViewWidths,
  queries?: Partial<Queries>
): ImageSizes =>
  [
    ...Object.entries(queries ?? {}).map(
      ([key, width]) => `${key ? mediaqueries[key] : ""} ${width}`
    ),
    `${maxSize}`,
  ].join(", ");

export const isValidImageData = (
  image: (ImageVaultFieldType & Partial<StoryblokAsset>) | undefined | null
): boolean => {
  if (!image) return false;

  // Check if the image is a Storyblok asset
  const isStoryblokAsset =
    "filename" in image &&
    "fieldtype" in image &&
    !!image.filename &&
    typeof image.filename === "string";

  // Check if the image is an ImageVault image with item
  const isImageVaultWithItem =
    "plugin" in image &&
    image.plugin === "image-vault" &&
    "item" in image &&
    image.item !== null &&
    image.item !== undefined;

  return isStoryblokAsset || isImageVaultWithItem;
};
