/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { DynamicBlocks } from "../DynamicBlock";
import FilterPageGrid from "../../ui/PageTemplates/FilterPage";
import Button from "../../ui/Button";
import { useTranslation } from "next-i18next";
import { PublicationPageStory } from "../../lib/storyblok/types/stories/PublicationPageStory";
import ContentGrid from "../../ui/ContentGrid";
import { PublicationPageViewModelExtension } from "../../lib/viewModel/handlePublicationStory";
import useMediaQuery from "../../hooks/useMatchMedia";
import {
  PublicationItems,
  PublicationPageState,
  getPublications,
} from "../../lib/publication/getPublications";
import s from "../../ui/ContentGrid/ContentGrid.module.scss";
import Pagination from "../../ui/Pagination/Pagination";
import FiltersSection from "../../ui/FiltersSection";
import { useRouter } from "next/router";
import Icon from "../../ui/Icon";
import cn from "classnames";
import excludedProductTags from "../../utils/excludedProductTags";
import { getTagUrl } from "../../utils/tagUtils";
import excludedFiltersTags from "../../utils/excludedFiltersTags";
import { splitIntoSections } from "../Layout/AnchorMenu/splitIntoSections";
import HeroBlock from "../Nestable/Hero";
import AnchorMenu from "../Layout/AnchorMenu/AnchorMenu";
import SearchBox from "../Nestable/SearchBox";
import b from "../../ui/Button/Button.module.scss";

type VM = ViewModel<PublicationPageStory> & PublicationPageViewModelExtension;

const PublicationPage: StoryblokStoryComponent<VM> = ({ story }) => {
  const router = useRouter();
  const { hero, body, footer, hideStickyMenu, anchorMenuButton } =
    story.content;
  const sections = splitIntoSections(body);

  const { t } = useTranslation(["searchpage", "common"]);
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const [showFilter, setShowFilter] = useState(!isMobile);
  const toggleFilter = () => {
    setShowFilter((currentShowFilter) => !currentShowFilter);
  };

  const [loading, setLoading] = useState(true);

  const [publicationItems, setPublicationItems] = useState<PublicationItems[]>(
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [yearsList, setYearsList] = useState<PublicationPageState["yearsList"]>(
    []
  );
  const [prodTagsList, setProdTagsList] = useState<
    PublicationPageState["prodTagsList"]
  >([]);
  const [fieldOFResTagsList, setFieldOFResTagsList] = useState<
    PublicationPageState["fieldOFResTagsList"]
  >([]);
  const [pubTagsList, setPubTagsList] = useState<
    PublicationPageState["filteredPubTags"]
  >([]);
  const [keyword, setKeyword] = useState("");
  const [error, setError] = useState<string>("");

  const [activeYearFilters, setActiveYearFilters] = useState<string[]>([]);
  const [activeProdTagFilters, setActiveProdTagFilters] = useState<string[]>(
    []
  );
  const [activeFieldOFResTagFilters, setActiveFieldOFResTagFilters] = useState<
    string[]
  >([]);
  const [activePubTagFilters, setActivePubTagFilters] = useState<string[]>([]);

  const [totalCount, setTotalCount] = useState(0);

  const [expandedAbstracts, setExpandedAbstracts] = useState<string[]>([]);

  const toggleAbstractExpansion = (publicationId) => {
    setExpandedAbstracts((prevExpanded) => {
      if (prevExpanded.includes(publicationId)) {
        return prevExpanded.filter((id) => id !== publicationId);
      } else {
        return [...prevExpanded, publicationId];
      }
    });
  };

  const DEBOUNCE_DELAY = 1300;
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(debounceTimer);
  }, [keyword]);

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      const years = "";
      const queryString = window.location.search.replace("?", "");
      const queries = queryString.split("&");
      queries.map((query) => {
        const parts = query.split("=");
        if (parts[0] === "year") {
          const years = parts[1] ? parts[1].split(",") : [];
          setActiveYearFilters(years);
        }
        if (parts[0] === "product") {
          const product = parts[1] ? parts[1].split(",") : [];
          setActiveProdTagFilters(product);
        }
        if (parts[0] === "field") {
          const field = parts[1] ? parts[1].split(",") : [];
          setActiveFieldOFResTagFilters(field);
        }
        if (parts[0] === "publication") {
          const publication = parts[1] ? parts[1].split(",") : [];
          setActivePubTagFilters(publication);
        }
        if (parts[0] === "q") {
          setKeyword(parts[1] ? parts[1] : "");
        }
        if (parts[0] === "page") {
          setCurrentPage(parts[1] ? Number(parts[1]) : 1);
        }
      });

      getPublications(
        currentPage,
        (years as string) ? (years as string).split(",") : [],
        activeProdTagFilters,
        activeFieldOFResTagFilters,
        activePubTagFilters,
        debouncedKeyword
      )
        .then((res) => {
          const filteredProdTags = res.productTagGroups.filter(
            (item) => item.count > 0 && !excludedFiltersTags.includes(item.name)
          );
          const filteredFieldOFResTags = res.fieldOfResearchTagGroups.filter(
            (item) => item.count > 0
          );
          const filteredPubTags = res.publicationTagGroups.filter(
            (item) => item.count > 0
          );
          const filteredresYearGroups = res.yearGroups.filter(
            (item) => item.count > 0
          );
          setProdTagsList(filteredProdTags);
          setPublicationItems(res.publications);
          setPageCount(res.pageCount);
          setYearsList(filteredresYearGroups);
          setProdTagsList(filteredProdTags);
          setFieldOFResTagsList(filteredFieldOFResTags);
          setPubTagsList(filteredPubTags);
          setLoading(false);
          setTotalCount(res.totalCount);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
      return;
    }

    setLoading(true);
    getPublications(
      currentPage,
      activeYearFilters,
      activeProdTagFilters,
      activeFieldOFResTagFilters,
      activePubTagFilters,
      debouncedKeyword
    )
      .then((res) => {
        const filteredProdTags = res.productTagGroups.filter(
          (item) => item.count > 0
        );
        const filteredFieldOFResTags = res.fieldOfResearchTagGroups.filter(
          (item) => item.count > 0
        );
        const filteredPubTags = res.publicationTagGroups.filter(
          (item) => item.count > 0
        );
        const filteredresYearGroups = res.yearGroups.filter(
          (item) => item.count > 0
        );
        setPublicationItems(res.publications);
        setPageCount(res.pageCount);
        setYearsList(filteredresYearGroups);
        setProdTagsList(filteredProdTags);
        setFieldOFResTagsList(filteredFieldOFResTags);
        setPubTagsList(filteredPubTags);
        setLoading(false);
        setTotalCount(res.totalCount);
        const newQueryParams = {};
        if (activeYearFilters.length > 0)
          newQueryParams["year"] = activeYearFilters.toString();
        if (activeProdTagFilters.length > 0)
          newQueryParams["product"] = activeProdTagFilters.toString();
        if (activeFieldOFResTagFilters.length > 0)
          newQueryParams["field"] = activeFieldOFResTagFilters.toString();
        if (activePubTagFilters.length > 0)
          newQueryParams["publication"] = activePubTagFilters.toString();
        if (debouncedKeyword.length > 0)
          newQueryParams["q"] = debouncedKeyword.toString();
        if (currentPage > 1) newQueryParams["page"] = currentPage;
        const queryString = Object.entries(newQueryParams)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const hashFragment = window.location.hash;

        router.push(
          {
            pathname: router.asPath.split("#")[0].split("?")[0],
            query: queryString,
            hash: hashFragment,
          },
          undefined,
          { shallow: true }
        );
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [
    currentPage,
    activeYearFilters,
    activeProdTagFilters,
    activeFieldOFResTagFilters,
    activePubTagFilters,
    debouncedKeyword,
  ]);

  const clearAllFilters = () => {
    setActiveYearFilters([]);
    setActiveProdTagFilters([]);
    setActiveFieldOFResTagFilters([]);
    setActivePubTagFilters([]);
    setKeyword("");
  };
  const handleKeywordChange = (value: string) => {
    setKeyword(value);
    setCurrentPage(1);
  };
  const handleYearFilterChange = (newFilters) => {
    setActiveYearFilters(newFilters);
    setCurrentPage(1);
  };

  const handleProdTagFilterChange = (newFilters) => {
    setActiveProdTagFilters(newFilters);
    setCurrentPage(1);
  };

  const handleFieldOFResTagFilterChange = (newFilters) => {
    setActiveFieldOFResTagFilters(newFilters);
    setCurrentPage(1);
  };

  const handlePubTagFilterChange = (newFilters) => {
    setActivePubTagFilters(newFilters);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      {hero?.[0] && (
        <HeroBlock
          {...hero?.[0]}
          anchorLinksMenu={
            sections.length > 1 &&
            !hideStickyMenu && (
              <AnchorMenu sections={sections} button={anchorMenuButton?.[0]} />
            )
          }
        />
      )}
      <FilterPageGrid type="publicationFilterPage">
        <FilterPageGrid.Preamble>
          <div id="highlights"></div>
          {body && <DynamicBlocks blocks={body} />}
        </FilterPageGrid.Preamble>
        {error && <p>{t("error")}</p>}
        {!error && (
          <>
            <FilterPageGrid.Toolbar>
              <FilterPageGrid.Input>
                <SearchBox
                  defaultValue={keyword}
                  type="small"
                  customOnChange={handleKeywordChange}
                  customOnClear={() => setKeyword("")}
                  useInstantSearch={false}
                />
              </FilterPageGrid.Input>

              <FilterPageGrid.RefinementsWrapper
                id="refinement-lists"
                isVisible={showFilter}
                aria-labelledby="refinement-toggle"
              >
                {yearsList.length > 0 && (
                  <FiltersSection
                    label={t("Years")}
                    allItems={yearsList}
                    activeItems={activeYearFilters}
                    setState={handleYearFilterChange}
                  />
                )}

                {prodTagsList.length > 0 && (
                  <FiltersSection
                    label={t("Product")}
                    allItems={prodTagsList.filter(
                      (item) => !excludedFiltersTags.includes(item.name)
                    )}
                    activeItems={activeProdTagFilters}
                    setState={handleProdTagFilterChange}
                  />
                )}

                {fieldOFResTagsList.length > 0 && (
                  <FiltersSection
                    label={t("Field of Research")}
                    allItems={fieldOFResTagsList}
                    activeItems={activeFieldOFResTagFilters}
                    setState={handleFieldOFResTagFilterChange}
                  />
                )}
              </FilterPageGrid.RefinementsWrapper>

              <FilterPageGrid.Toggle>
                <Button
                  variant="default"
                  size="small"
                  icon={"filter"}
                  onClick={toggleFilter}
                  aria-expanded={showFilter}
                  aria-controls="refinement-lists"
                  id="refinement-toggle"
                >
                  {showFilter ? t("close-filters") : t("show-filters")}
                </Button>
              </FilterPageGrid.Toggle>
            </FilterPageGrid.Toolbar>

            <FilterPageGrid.Status>
              <FilterPageGrid.Heading
                clearLabel={
                  activeYearFilters.length > 0 ||
                  activeProdTagFilters.length > 0 ||
                  activeFieldOFResTagFilters.length > 0 ||
                  activePubTagFilters.length > 0 ||
                  keyword !== ""
                    ? t("clear-all")
                    : ""
                }
                onClearClick={clearAllFilters}
              />
              <FilterPageGrid.NumberOfHits>
                {t("number-of-hits_other", {
                  count: !loading ? totalCount : 0,
                })}
              </FilterPageGrid.NumberOfHits>
            </FilterPageGrid.Status>

            <FilterPageGrid.Body>
              {!loading && !error && publicationItems.length > 0 ? (
                <ContentGrid
                  spacing="noTop"
                  container="fluid"
                  className="publicationContentGrid"
                >
                  {publicationItems.map(
                    ({
                      id,
                      title,
                      source_Title,
                      abstract,
                      date,
                      publicationAuthors,
                      doi,
                      fieldOfResearchTags,
                      productTags,
                    }) => {
                      const publicationYear = new Date(date).getFullYear();
                      const doiLink = `https://doi.org/${doi}`;
                      //@ts-ignore
                      const isAbstractExpanded = expandedAbstracts.includes(id);

                      return (
                        <div key={id} className={s.pubDetailsWrapper}>
                          <a
                            href={doiLink}
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            className="pubTitle externalLink"
                            title={title}
                          >
                            <h3>{title}</h3>
                          </a>

                          <div className={s.meta}>
                            {publicationYear}
                            {publicationAuthors &&
                              publicationAuthors.length > 0 && (
                                <strong>&bull;</strong>
                              )}
                            {publicationAuthors
                              ?.map(
                                (author) =>
                                  `${
                                    author.family.charAt(0).toUpperCase() +
                                    author.family.slice(1)
                                  } ${
                                    author.given.charAt(0).toUpperCase() +
                                    author.given.slice(1)
                                  }`
                              )
                              .join(", ")}
                            {source_Title && <strong>&bull;</strong>}
                            {source_Title}
                            {fieldOfResearchTags && (
                              <div>
                                {fieldOfResearchTags
                                  ? fieldOfResearchTags
                                      .map(({ name }) => name)
                                      .join(" | ")
                                  : ""}
                              </div>
                            )}
                          </div>

                          <p className={s.abstract}>
                            {isAbstractExpanded
                              ? abstract
                              : `${abstract.slice(0, 280)}${
                                  abstract.length > 280 ? "..." : ""
                                }`}

                            {abstract.length > 280 && (
                              <button
                                className={s.expandButton}
                                onClick={() => toggleAbstractExpansion(id)}
                                aria-label={
                                  isAbstractExpanded
                                    ? t("show-less", { ns: "common" })
                                    : t("show-more", { ns: "common" })
                                }
                                title={
                                  isAbstractExpanded
                                    ? t("show-less", { ns: "common" })
                                    : t("show-more", { ns: "common" })
                                }
                              >
                                {isAbstractExpanded
                                  ? t("show-less", { ns: "common" })
                                  : t("show-more", { ns: "common" })}

                                <Icon
                                  icon={isAbstractExpanded ? "minus" : "add"}
                                  width={12}
                                  className={cn(s.icon, {
                                    [s.open]: isAbstractExpanded,
                                  })}
                                />
                              </button>
                            )}
                          </p>

                          <ul className={s.products}>
                            {productTags
                              ? productTags
                                  .filter(
                                    ({ name }) =>
                                      !excludedProductTags.includes(name)
                                  )
                                  .map(({ name }) => {
                                    const tagUrl = getTagUrl(name);

                                    return (
                                      <li key={name}>
                                        {tagUrl ? (
                                          <a
                                            href={tagUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={cn(b.button, b.tag)}
                                          >
                                            {name}
                                          </a>
                                        ) : (
                                          <span className={cn(s.tag)}>
                                            {name}
                                          </span>
                                        )}
                                      </li>
                                    );
                                  })
                              : ""}
                          </ul>
                        </div>
                      );
                    }
                  )}
                </ContentGrid>
              ) : (
                <>
                  {loading && <p>{t("loading")}</p>}
                  {publicationItems && publicationItems.length === 0 && (
                    <p>{t("no-publications-found", { ns: "common" })}</p>
                  )}
                </>
              )}
            </FilterPageGrid.Body>
          </>
        )}

        {!error && (
          <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            labelPrevious={t("previous", { ns: "common" })}
            labelNext={t("next", { ns: "common" })}
            labelPage={t("page", { ns: "common" })}
          />
        )}
      </FilterPageGrid>
      <div id="subscribe"></div>
      {footer && <DynamicBlocks blocks={footer} />}
    </>
  );
};

export default PublicationPage;
