import Link from "next/link";
import { EditorialImageBlock } from "../../lib/storyblok/types/blocks/EditorialImageBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import Editorial from "../../ui/Editorial/Editorial";
import ConditionalWrapper from "../ConditionalWrapper";
import ImageVaultImage from "../ImageVaultImage";
import { isValidImageData } from "../../lib/utils/imageUtils";

const EditorialImageKeepProportions: StoryblokBlockComponent<
  EditorialImageBlock
> = ({ image, caption, narrow, link, linkTarget, linkTitle }) => {
  const resolveLink = useLinks();
  if (!image) return null;
  const hasLink = link ? link.id.length > 0 || link.url.length > 0 : false;
  const target = linkTarget ? "_blank" : "_self";
  const href = link ? resolveLink(link) : "/";
  const linkProps = {
    title: linkTitle,
    href,
    target,
  };
  return (
    <Editorial.Image
      image={
        // Todo : change layout to responsive and fix css issues that arises, in order to get responsive image sizes.
        <ConditionalWrapper
          shouldWrap={hasLink}
          wrapper={(children) => (
            <Link {...linkProps}>
              <a {...linkProps}>{children}</a>
            </Link>
          )}
        >
          <>
            {isValidImageData(image) ? (
              <ImageVaultImage image={image} layout="intrinsic" contain />
            ) : null}
          </>
        </ConditionalWrapper>
      }
      caption={caption}
      wide={true}
      keepProportions={true}
      narrow={narrow}
    />
  );
};

export default EditorialImageKeepProportions;
