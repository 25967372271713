import { useTranslation } from "next-i18next";
import React from "react";
import {
  Highlight,
  Snippet,
  useHits,
  usePagination,
} from "react-instantsearch";
import SearchHits from "../../ui/SearchHits";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import Pagination from "../../ui/Pagination/Pagination";
import Image from "next/image";
import ImageVaultImage from "../ImageVaultImage";
import fallbackImage from "../../public/images/tobii-seo-fallback.jpg";
import {
  ImageVaultFieldType,
  StoryblokAsset,
} from "../../lib/storyblok/types/fieldtypes/imagevaultFieldtype";

type SearchHit = {
  objectID: string;
  fullSlug: string;
  storyType: string;
  title: string;
  publishedAt?: string;
  metaTitle?: string;
  metaDescription?: string;
  image?: ImageVaultFieldType & Partial<StoryblokAsset>;
  content?: string;
  features?: Record<string, unknown>[];
};

const getBodyAttribute = (hit: SearchHit) =>
  typeof hit.metaDescription === "string" &&
  hit.metaDescription.trim().length > 0
    ? "metaDescription"
    : "content";

const PaginatedHits = () => {
  const { t } = useTranslation("searchpage");
  const { hits, results } = useHits<SearchHit>();
  const { refine: refinePage } = usePagination();

  const totalHits = results?.nbHits ?? 0;
  const pageCount = Math.ceil(totalHits / 9);
  const currentPage = (results?.page ?? 0) + 1;
  const displayedHits = hits;

  const handlePageChange = (newPage: number) => {
    refinePage(newPage - 1);
    const section = document.getElementById("filterSearchSection");
    const offset = 20;

    if (section) {
      const sectionPosition =
        section.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: sectionPosition, behavior: "smooth" });
    }
  };

  const totalLabel = t("number-of-hits", { count: totalHits });

  return (
    <>
      <SearchHits total={totalLabel}>
        {displayedHits.map((hit) => (
          <SearchHits.Hit key={hit.objectID}>
            <SearchHits.Image>
              {hit.image ? (
                <ImageVaultImage
                  image={hit.image}
                  alt={hit.title}
                  layout="fill"
                  sizes={"480px"}
                />
              ) : (
                <Image
                  src={fallbackImage}
                  sizes={"480px"}
                  layout="fill"
                  alt={hit.title}
                />
              )}
            </SearchHits.Image>
            <SearchHits.ContentWrapper>
              <SearchHits.Heading>
                <a href={resolveSlug(hit.fullSlug)} title={hit.title}>
                  <Highlight hit={hit} attribute="title" />
                </a>
              </SearchHits.Heading>
              <SearchHits.Body>
                <Snippet hit={hit} attribute={getBodyAttribute(hit)} />
              </SearchHits.Body>
              <SearchHits.Tags>
                <SearchHits.Tag tag={t(`pages.${hit.storyType}`)} />
              </SearchHits.Tags>
            </SearchHits.ContentWrapper>
          </SearchHits.Hit>
        ))}
      </SearchHits>

      {pageCount > 1 && (
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          labelPrevious={t("previous")}
          labelNext={t("next")}
          labelPage={t("page")}
        />
      )}
    </>
  );
};

export default PaginatedHits;
