import React, { useEffect, useState } from "react";
import Image from "next/image";
import cn from "classnames";
import s from "../Slider.module.scss";
import c from "../../../styles/container.module.scss";
import Button from "../../Button";
import { useLinks } from "../../../lib/storyblok/useLinks";
import videoThumbnailFallback from "../../../public/images/chapter-divider-01.png";
import VideoPlayer from "../../Common/VideoPlayer";
import { isValidImageData } from "../../../lib/utils/imageUtils";
import ImageVaultImage from "../../../components/ImageVaultImage";

const SlideItemVideo = ({
  label,
  titleType = "h3",
  title,
  description,
  buttons,
  inverted,
  overlay,
  video,
  videoThumbnail,
  activeSlideId,
  paginationTitle, // eslint-disable-line
  id,
  contentPosition,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [videoKey, setVideoKey] = useState(`video-${id}`);
  const resolveLink = useLinks();
  const TitleTag = titleType;

  useEffect(() => {
    const nextIsActive = id === activeSlideId;

    if (nextIsActive && !isActive) {
      setVideoKey(`video-${id}-${Date.now()}`);
    }

    setIsActive(nextIsActive);
  }, [id, activeSlideId, isActive]);

  const innerContainerClassName = cn(
    s.innerContainer,
    c.xWide,
    { [s.inverted]: inverted },
    { [s.contentLeft]: contentPosition === "left" },
    { [s.contentCenter]: contentPosition === "center" },
    "swiper-slide-content"
  );
  return (
    <>
      <div className={cn(s.slideItem, s.slideVideo)}>
        {video && (
          <div className={cn(s.videoContainer)}>
            {
              <VideoPlayer
                key={videoKey}
                videoUrl={video.filename}
                thumbnail={
                  videoThumbnail && isValidImageData(videoThumbnail) ? (
                    <ImageVaultImage
                      image={videoThumbnail}
                      layout="fill"
                      sizes="100vw"
                      objectFit="cover"
                      priority
                    />
                  ) : (
                    <Image
                      src={videoThumbnailFallback}
                      layout="fill"
                      objectFit="cover"
                    />
                  )
                }
                thumbnailMode="thumbnailOnly"
                isActive={isActive}
              />
            }
          </div>
        )}
        {overlay && <div className={cn(s.overlay, s[overlay])}></div>}

        <div className={innerContainerClassName}>
          {label && (
            <p
              className={cn(s.label)}
              data-swiper-parallax-y="-10"
              data-swiper-parallax-duration="300"
              data-swiper-parallax-opacity="0.25"
            >
              {label}
            </p>
          )}
          {title &&
            TitleTag &&
            React.createElement(
              TitleTag,
              {
                className: cn(s.title),
                "data-swiper-parallax-y": "-20",
                "data-swiper-parallax-duration": "400",
                "data-swiper-parallax-opacity": "1",
              },
              title
            )}
          {description && (
            <p
              className={cn(s.description)}
              data-swiper-parallax-y="-30"
              data-swiper-parallax-duration="500"
              data-swiper-parallax-opacity="0.5"
            >
              {description}
            </p>
          )}
          {buttons && buttons.length > 0 && (
            <div className={cn(s.buttons)}>
              {buttons.map((button) => (
                <Button
                  key={button._uid}
                  variant={button.buttonType ?? "default"}
                  href={resolveLink(button.buttonLink)}
                >
                  {button.buttonText}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SlideItemVideo;
