import { useTranslation } from "next-i18next";
import React, { ReactNode } from "react";
import { Snippet, useHits, usePagination } from "react-instantsearch";
import type { Hit } from "instantsearch.js";
import Button from "../../ui/Button";
import FilterCard from "../../ui/FilterCard";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import ContentGrid from "../../ui/ContentGrid";
import { StorySortMethod } from "./StorySort";
import {
  ImageVaultFieldType,
  StoryblokAsset,
} from "../../lib/storyblok/types/fieldtypes/imagevaultFieldtype";
import Image from "next/image";
import ImageVaultImage from "../ImageVaultImage";
import fallbackImage from "../../public/images/tobii-seo-fallback.jpg";
import { PastEvent } from "../../ui/Button/PastEvent";
import Pagination from "../../ui/Pagination/Pagination";

export type StoryHit = {
  date?: string;
  startDate?: string;
  objectID: string;
  fullSlug: string;
  storyType: string;
  title: string;
  publishedAt?: string;
  metaTitle?: string;
  metaDescription?: string;
  image?: ImageVaultFieldType & Partial<StoryblokAsset>;
  content?: string;
  features?: Record<string, unknown>[];
  products?: string[];
  types?: string[];
  categories?: string[];
  location?: string;
  author?: string;
  readTime?: string;
  children?: ReactNode;
  availability?: string;
  __position: number;
  __queryID?: string;
};

type Props = {
  sortMethod: StorySortMethod;
  renderImages: boolean;
  renderDate: boolean;
  pageType?: string;
};

const getBodyAttribute = (hit: StoryHit) =>
  typeof hit.metaDescription === "string" &&
  hit.metaDescription.trim().length > 0
    ? "metaDescription"
    : "content";

const sortHits = (
  sortMethod: StorySortMethod,
  hits: Array<Hit<StoryHit>>
): Array<Hit<StoryHit>> => {
  const localHits = [...hits];
  if (sortMethod === "Ascending") {
    return localHits.sort((a, b) => a.title.localeCompare(b.title));
  }
  if (sortMethod === "Descending") {
    return localHits.sort((a, b) => -1 * a.title.localeCompare(b.title));
  }
  return localHits;
};

const InfiniteStoryHits = ({
  sortMethod,
  renderImages,
  renderDate,
  pageType,
}: Props) => {
  const { t } = useTranslation(["searchpage", "tags"]);
  const { hits, results } = useHits<StoryHit>();
  const { refine: refinePage } = usePagination();
  const totalHits = results?.nbHits ?? 0;
  const pageCount = Math.ceil(totalHits / 9);
  const currentPage = (results?.page ?? 0) + 1;

  const handlePageChange = (newPage: number) => {
    refinePage(newPage - 1);
    const section = document.getElementById("filterSearchSection");
    const offset = 20;

    if (section) {
      const sectionPosition =
        section.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: sectionPosition, behavior: "smooth" });
    }
  };

  const isExpired = (hit: Hit<StoryHit>) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return hit.date && new Date(hit.date) < today;
  };

  const sortedHits = sortMethod ? sortHits(sortMethod, hits) : hits;

  const getImage = (hit: Hit<StoryHit>) => {
    if (!renderImages) return;
    const expired = isExpired(hit);

    return hit.image ? (
      <div>
        <ImageVaultImage
          image={hit.image}
          sizes={"720px"}
          contain={false}
          layout="fill"
          style={{
            opacity: expired && pageType === "eventFinderPage" ? 0.5 : 1,
          }}
          alt={hit.title}
          priority={false}
        />
        {expired && pageType === "eventFinderPage" && (
          <div
            style={{
              position: "absolute",
              bottom: "1rem",
              right: "1rem",
            }}
          >
            <PastEvent variant={""}>{t("past-event")}</PastEvent>
          </div>
        )}
      </div>
    ) : (
      <Image
        src={fallbackImage}
        sizes={"720px"}
        objectFit="contain"
        layout="fill"
        alt={hit.title}
        priority={false}
      />
    );
  };

  return (
    <>
      <ContentGrid spacing="noTop">
        {sortedHits.map((hit) => {
          const slug = resolveSlug(hit.fullSlug);
          const expired = isExpired(hit);

          const imageAlignment = hit.types?.includes("customer-story")
            ? "left"
            : "center";
          return (
            <FilterCard
              author={hit.author && hit.author}
              readTime={hit.readTime && hit.readTime}
              categories={hit.categories && hit.categories}
              key={hit.objectID}
              date={renderDate ? hit.date : ""}
              startDate={renderDate && hit.startDate ? hit.startDate : ""}
              location={hit.availability && hit.availability}
              label={
                expired &&
                pageType === "investorEventFinderPage" && (
                  <PastEvent variant={expired ? "investor" : ""}>
                    {t("past-event")}
                  </PastEvent>
                )
              }
              type={
                hit.types && hit.types.length
                  ? t(hit.types[0], { ns: "tags" })
                  : ""
              }
              image={
                getImage(hit) ? (
                  <a title={hit.title} href={slug}>
                    {getImage(hit)}
                  </a>
                ) : undefined
              }
              title={
                <a title={hit.title} href={slug}>
                  <Snippet hit={hit} attribute="title" />
                </a>
              }
              link={
                <Button
                  variant="text"
                  title={hit.title}
                  href={slug}
                  icon="caret-right"
                >
                  {t("learn-more")}
                </Button>
              }
              pageType={pageType}
              imageAlignment={imageAlignment}
            >
              <p>
                <Snippet hit={hit} attribute={getBodyAttribute(hit)} />
              </p>
            </FilterCard>
          );
        })}
      </ContentGrid>
      {pageCount > 1 && (
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          labelPrevious={t("previous")}
          labelNext={t("next")}
          labelPage={t("page")}
        />
      )}
    </>
  );
};

export default InfiniteStoryHits;
