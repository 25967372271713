import React, { ElementType, useRef, useState, useEffect } from "react";
import cn from "classnames";
import { FilterListContext } from "./FilterListContext";
import Filter from "./components/Filter";
import Icon from "../Icon";
import s from "./FilterList.module.scss";
import SearchInput from "../../ui/SearchInput";
import { useTranslation } from "next-i18next";

type DropdownPosition = {
  left: number;
  right: number | "auto";
  transform: string;
};

type FilterListProps = {
  label?: string;
  children: JSX.Element | JSX.Element[];
  labelAs?: ElementType;
  className?: string;
  layout?: "dropdown" | "expand";
};

type FilterChildProps = {
  children: string;
  value: string;
  amount?: number;
  checked?: boolean;
  onChange?: () => void;
};

const EmptyState = () => {
  const { t } = useTranslation("searchpage");
  return <div className={s.noFilterOptions}>{t("no-results-found")}</div>;
};

const subComponents = { Filter };

const FilterList: CWS<FilterListProps, typeof subComponents> = ({
  label,
  children,
  className,
  layout = "dropdown",
}) => {
  const { t } = useTranslation("searchpage");
  const name = label ? label.replace(/\s+/g, "-").toLowerCase() : "";

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLUListElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const [selectedCount, setSelectedCount] = useState(0);

  const handleSelectionChange = (isSelected: boolean) => {
    setSelectedCount((prevCount) => {
      return isSelected ? prevCount + 1 : Math.max(0, prevCount - 1);
    });
  };

  // Count initial selected items on mount
  useEffect(() => {
    let count = 0;
    React.Children.forEach(children, (child) => {
      // Use type assertion to fix the TypeScript error
      if (
        React.isValidElement<FilterChildProps>(child) &&
        child.props.checked
      ) {
        count++;
      }
    });
    setSelectedCount(count);
  }, [children]);

  const filteredChildren = React.Children.map(children, (child) => {
    if (React.isValidElement<FilterChildProps>(child)) {
      const childText = child.props.children?.toString().toLowerCase() || "";
      if (!searchTerm || childText.includes(searchTerm.toLowerCase())) {
        return child;
      }
    }
    return null;
  });

  const visibleChildrenCount =
    filteredChildren?.filter((child) => child !== null).length || 0;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Escape" && isOpen) {
      setIsOpen(false);
      return;
    }

    if ((event.key === "ArrowDown" || event.key === "ArrowUp") && isOpen) {
      event.preventDefault();
      const inputs = Array.from(
        dropdownRef.current?.querySelectorAll('input[type="checkbox"]') || []
      );
      const currentIndex = inputs.indexOf(
        document.activeElement as HTMLElement
      );
      const nextIndex =
        event.key === "ArrowDown"
          ? (currentIndex + 1) % inputs.length
          : (currentIndex - 1 + inputs.length) % inputs.length;
      (inputs[nextIndex] as HTMLElement).focus();
    }

    if (event.key === "Enter" || event.key === " ") {
      if (
        document.activeElement === dropdownRef.current?.querySelector("button")
      ) {
        setIsOpen(!isOpen);
      }
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onClear = () => {
    setSearchTerm("");
  };

  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>({
    left: 0,
    right: "auto",
    transform: "translateX(-50%)",
  });

  // Calculate and set dropdown position
  useEffect(() => {
    if (!isOpen || !toggleRef.current || !dropdownRef.current) return;

    const updatePosition = () => {
      const toggleRect = toggleRef.current?.getBoundingClientRect();
      const containerRect =
        dropdownRef.current?.parentElement?.getBoundingClientRect();
      const dropdownWidth = 280;

      if (!toggleRect || !containerRect) return;

      const viewportWidth = window.innerWidth;
      let left = (toggleRect.width - dropdownWidth) / 2;
      const absoluteLeft = toggleRect.left + left;
      const absoluteRight = absoluteLeft + dropdownWidth;

      if (absoluteRight > viewportWidth) {
        const overflow = absoluteRight - viewportWidth;
        left -= overflow;
      }

      if (absoluteLeft < 0) {
        left = 0;
      }

      setDropdownPosition({
        left,
        right: "auto",
        transform: "none",
      });
    };

    requestAnimationFrame(updatePosition);

    window.addEventListener("resize", updatePosition);
    return () => window.removeEventListener("resize", updatePosition);
  }, [isOpen]);

  const dropDownContent = (
    <>
      <button
        ref={toggleRef}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        className={cn(s.filterToggle, { [s.isOpen]: isOpen })}
        aria-expanded={isOpen}
        aria-controls={`filter-list-${name}`}
        aria-label={`${label} filter options${
          selectedCount > 0 ? `, ${selectedCount} selected` : ""
        }`}
      >
        <div className={s.inner}>
          <span className={s.label}>{label}</span>

          {selectedCount > 0 && (
            <span className={s.selectedCount} aria-hidden="true">
              {selectedCount}
            </span>
          )}
        </div>
        <Icon
          icon={isOpen ? "caret-up" : "caret-down"}
          className={cn(s.arrow)}
          width={16}
          color={isOpen ? s.iconColor : s.iconColorInverted}
        />
      </button>
      <div
        id={`filter-list-${name}`}
        className={cn(s.filterListWrapper, {
          [s.isOpen]: isOpen,
        })}
        role="region"
        aria-labelledby={`${name}-heading`}
        style={{
          left: `${dropdownPosition.left}px`,
          right: dropdownPosition.right,
        }}
      >
        {isOpen && (
          <div className={s.filterListSearch}>
            <SearchInput
              label={t("input-label")}
              placeholder={t("input-label")}
              clearLabel={t("input-clear")}
              onChange={handleSearchChange}
              defaultValue={searchTerm}
              onClearClick={onClear}
              type="mini"
            />
          </div>
        )}
        {visibleChildrenCount > 0 ? (
          <ul
            ref={childrenRef}
            role="listbox"
            aria-label={`${label} options`}
            className={cn(s.filterList)}
          >
            {filteredChildren}
          </ul>
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );

  const expandContent = (
    <ul role="listbox" className={cn(s.filterList)}>
      {filteredChildren}
    </ul>
  );

  return (
    <FilterListContext.Provider
      value={{ name, onSelectionChange: handleSelectionChange }}
    >
      <div
        ref={dropdownRef}
        className={cn(s.filterListContainer, className, {
          [s.dropdown]: layout === "dropdown",
          [s.expand]: layout === "expand",
        })}
      >
        {layout === "dropdown" ? dropDownContent : expandContent}
      </div>
    </FilterListContext.Provider>
  );
};

FilterList.Filter = Filter;

export default FilterList;
