import { ReactNode } from "react";
import cn from "classnames";
import s from "../ContentGrid.module.scss";
import BlockIntro from "../../Common/BlockIntro";

type ImageAlignment = "center" | "left";

type Props = {
  image: ReactNode;
  category: string;
  title: string;
  text: string;
  link: ReactNode;
  gray?: boolean;
  href?: string;
  imageAlignment?: ImageAlignment;
};

const Item = ({
  image,
  category,
  title,
  link,
  text,
  gray,
  href,
  imageAlignment,
}: Props) => {
  return (
    <article className={cn(s.item, { [s.gray]: gray })}>
      <a className={s.link} title={title} href={href}>
        <div className={cn(s.imageContainer, s[`align${imageAlignment}`])}>
          {image}
        </div>
        <div className={s.content}>
          <BlockIntro
            label={category}
            title={title}
            description={text}
            descriptionSize={"small"}
            descriptionStyle="faded"
            alignment={"alignLeft"}
            inverted={false}
            width={"full"}
            headingTag="h3"
          />
        </div>
      </a>
      {link}
    </article>
  );
};

export default Item;
