import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ColumnGridVideoItemBlock } from "../../lib/storyblok/types/blocks/ColumnGridVideoItemBlock";
import ColumnGrid from "../../ui/ColumnGrid";
import Button from "../../ui/Button";
import { DynamicBlocks } from "../DynamicBlock";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useContentStyle, useButtonStyle } from "../../hooks/useStyle";

const { Item } = ColumnGrid;

const ColumnGridVideoItem: StoryblokBlockComponent<
  ColumnGridVideoItemBlock
> = ({
  _uid,
  video,
  title,
  markdown,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
  imageKeepProportions,
}) => {
  const resolveLink = useLinks();
  const { buttonVariant } = useButtonStyle(style);
  const contentStyle = useContentStyle(style);
  const isInverted = style === "" || style === undefined ? false : contentStyle;

  return (
    <Item
      key={_uid}
      video={<DynamicBlocks blocks={video} />}
      title={title}
      markdown={markdown}
      imageKeepProportions={imageKeepProportions}
      inverted={isInverted}
    >
      {buttonText && buttonLink && (
        <Button
          href={resolveLink(buttonLink)}
          variant={buttonVariant}
          openInNewTab={buttonLink.linktype === "asset"}
        >
          {buttonText}
        </Button>
      )}
      {secondButtonText && secondButtonLink && (
        <Button
          href={resolveLink(secondButtonLink)}
          variant={buttonVariant}
          openInNewTab={secondButtonLink.linktype === "asset"}
        >
          {secondButtonText}
        </Button>
      )}
    </Item>
  );
};

export default ColumnGridVideoItem;
