import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import SpeakersUi from "../../ui/Speakers";
import { SpeakersBlock } from "../../lib/storyblok/types/blocks/SpeakersBlock";
import ImageVaultImage from "../ImageVaultImage";
import { useTranslation } from "next-i18next";
import { isValidImageData } from "../../lib/utils/imageUtils";

const Speakers: StoryblokBlockComponent<SpeakersBlock> = ({
  title,
  speakers,
}) => {
  const { Speaker } = SpeakersUi;
  const { t } = useTranslation();

  return (
    <SpeakersUi title={title}>
      {speakers.map((speaker) => (
        <Speaker
          key={speaker._uid}
          image={
            isValidImageData(speaker.image) ? (
              <ImageVaultImage
                sizes="256px"
                image={speaker.image}
                layout="fill"
              />
            ) : null
          }
          name={speaker.name}
          title={speaker.title}
          description={speaker.description}
          linkedinLink={speaker.linkedinLink?.url}
          showMoreLabel={t("show-more")}
          showLessLabel={t("show-less")}
        />
      ))}
    </SpeakersUi>
  );
};

export default Speakers;
