/* eslint-disable */
import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./Overlay.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import BlockIntro from "../Common/BlockIntro";
import BlockBackground from "../Common/BlockBackground";
import { AlignmentVariant } from "../../lib/storyblok/types/shared";

type Props = {
  title: string;
  description: string;
  buttons?: ReactNode;
  image?: ReactNode;
  video?: ReactNode;
  overlayStyle: string;
  overlayOpacity: number;
  contentAlignment: AlignmentVariant;
  innerSpacing: string;
  outerSpacing: string;
  width: string;
  inverted?: boolean;
};

const Overlay: React.FC<Props> = ({
  title,
  description,
  buttons,
  image,
  video,
  overlayStyle,
  overlayOpacity,
  contentAlignment,
  innerSpacing = "innerMedium",
  outerSpacing,
  width,
  inverted,
}) => {
  return (
    <>
      <section
        className={cn(s.overlay, s[innerSpacing], bs[outerSpacing], c[width], {
          [s.roundedCorners]: width !== "fluid",
        })}
      >
        <div className={cn(s.background)}>
          {video ? (
            video
          ) : image ? (
            <div className={cn(s.backgroundImage)}>
              <div className={s.imageContainer}>{image}</div>
            </div>
          ) : null}
        </div>

        <BlockBackground style={overlayStyle} opacity={overlayOpacity} />

        <div className={s.inner}>
          <BlockIntro
            alignment={contentAlignment}
            title={title}
            description={description}
            buttons={buttons}
            inverted={inverted}
            width={"contained"}
            spaceHorizontal={true}
            parallax={true}
          />
        </div>
      </section>
    </>
  );
};

export default Overlay;
