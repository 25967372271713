import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import EmbedBlockUi from "../../ui/EmbedBlock";
import { EmbedBlock } from "../../lib/storyblok/types/blocks/EmbedBlock";
import { useContentStyle } from "../../hooks/useStyle";

const EmbedBlock: StoryblokBlockComponent<EmbedBlock> = ({
  text,
  title,
  asset,
  style,
  spacing,
  width,
  height,
}) => {
  const isInverted = useContentStyle(style);

  return (
    <EmbedBlockUi
      title={title}
      text={text}
      asset={asset}
      style={style}
      spacing={spacing}
      width={width}
      height={height}
      inverted={isInverted}
    />
  );
};

export default EmbedBlock;
