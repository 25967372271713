import dynamic from "next/dynamic";
import React, { useState, useEffect } from "react";
import { MobileSubNavigationPanel as PanelType } from "../../../lib/storyblok/types/blocks/header/HeaderSubNavigationPanel";
import { MobileSubNavTitle, MobileNavList } from "../../../ui/Header";
import { useLinks } from "../../../lib/storyblok/useLinks";
import { MobileNavItemItem } from "../../../ui/Header/components/mobile/MobileNavItemItem";
import { HeaderSubNavigationItem } from "../../../lib/storyblok/types/blocks/header/HeaderSubNavigationItem";

const AnimatePresence = dynamic(
  () => import("framer-motion").then((mod) => mod.AnimatePresence),
  {
    ssr: false,
  }
);

type Props = {
  item: PanelType;
  title: string;
  href: string;
  setIsMobileSubNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSubNavigationPanel: React.FC<Props> = ({
  item,
  title,
  href,
  setIsMobileSubNavOpen,
}) => {
  const resolveLink = useLinks();
  const [isMobilePanelOpen, setIsMobilePanelOpen] = useState(false);

  useEffect(() => {
    setIsMobilePanelOpen(true);
  }, []);

  return (
    <>
      <AnimatePresence>
        {isMobilePanelOpen && (
          <MobileNavList isOpen={isMobilePanelOpen} level={1} key={item._uid}>
            <MobileSubNavTitle
              title={title}
              href={href}
              onBackClick={() => {
                setIsMobilePanelOpen(false);
                setIsMobileSubNavOpen(false);
              }}
              index={0}
            />
            {item.items.map((navItem, k) => {
              let navItemAllSubItems: HeaderSubNavigationItem[] = [];

              navItem.body.forEach((e) => {
                navItemAllSubItems = navItemAllSubItems.concat(e.items);
              });

              return (
                <MobileNavItemItem
                  key={navItem._uid || k}
                  text={navItem.title}
                  href={resolveLink(navItem.link)}
                  directLink={resolveLink(navItem.link) !== "/"}
                  subItems={navItemAllSubItems}
                  index={k}
                />
              );
            })}
          </MobileNavList>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileSubNavigationPanel;
