import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./Preamble.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import type {
  StoryblokRichTextFieldtype,
  RichTextContent,
} from "../../lib/storyblok/types/fieldtypes/richText";
import BlockIntro from "../Common/BlockIntro";
import {
  AlignmentVariant,
  HeadingVariant,
} from "../../lib/storyblok/types/shared";

type Props = {
  title?: string;
  labelAs?: HeadingVariant;
  text?: string | StoryblokRichTextFieldtype | RichTextContent;
  noTopPadding?: boolean;
  noBottomPadding?: boolean;
  innerSpacing?: string;
  contentAlignment?: AlignmentVariant;
  buttons?: ReactNode;
};

const Preamble = ({
  title,
  labelAs = "h2",
  text,
  noTopPadding,
  noBottomPadding,
  innerSpacing = "both",
  contentAlignment = "alignCenter",
  buttons,
}: Props) => {
  const innerSpacingCleanup = noTopPadding
    ? "onlyBottom"
    : noBottomPadding
    ? "onlyTop"
    : innerSpacing;

  return (
    <div className={cn(s.preamble, bs[innerSpacingCleanup])}>
      <BlockIntro
        title={title}
        description={text}
        headingTag={labelAs}
        width={"contained"}
        alignment={contentAlignment}
        buttons={buttons}
        inverted={false}
        titleSize={"large"}
        titleWeight={"bold"}
        descriptionSize={"large"}
        descriptionWeight={"bold"}
      />
    </div>
  );
};

export default Preamble;
