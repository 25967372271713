import React from "react";
import RefinementFilterList from "./RefinementFilterList";

const StoryRefinementList = ({ refinementAttributes, hiddenFacets }) => {
  return (
    <>
      {refinementAttributes.map(({ id, attribute, sort }) => (
        <RefinementFilterList
          key={id}
          id={id}
          attribute={attribute}
          sort={sort}
          hiddenFacets={hiddenFacets}
        />
      ))}
    </>
  );
};

export default StoryRefinementList;
