import { ReactNode } from "react";
import cn from "classnames";
import { InnerSpacingVariant } from "../../lib/storyblok/types/shared";

import s from "./LocalAlert.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";

type Props = {
  style: "white" | "red" | "black" | "signature";
  icon: ReactNode;
  title: string;
  text: string;
  button: ReactNode;
  padding: InnerSpacingVariant;
};

const LocalAlert = ({
  style,
  icon,
  title,
  text,
  button,
  padding = "topAndBottom",
}: Props) => {
  return (
    <div className={cn(s.localAlert, c.medium, s[style], bs[padding])}>
      <div className={s.content}>
        {icon && <span className={s.icon}>{icon && icon}</span>}
        {title && <h3 className={s.title}>{title}</h3>}
        {text && <p className={s.description}>{text}</p>}
        {button && button}
      </div>
    </div>
  );
};

export default LocalAlert;
