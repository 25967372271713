import { ReactNode } from "react";
import cn from "classnames";
import Image from "next/image";
import s from "./Hero.module.scss";
import c from "../../styles/container.module.scss";
import useMediaQuery from "../../hooks/useMatchMedia";
import swooshDefaultTop from "../../public/images/divider-block-swoosh-default-top-flipped.png";
import type {
  StoryblokRichTextFieldtype,
  RichTextContent,
} from "../../lib/storyblok/types/fieldtypes/richText";
import BlockIntro from "../Common/BlockIntro";
import BlockBackground from "../Common/BlockBackground";
import { StyleVariant } from "../../lib/storyblok/types/shared";

type Props = {
  description?: string | StoryblokRichTextFieldtype | RichTextContent;
  copy?: string | StoryblokRichTextFieldtype | RichTextContent;
  title: string;
  label?: string;
  buttons?: ReactNode;
  small?: boolean;
  backgroundImage?: ReactNode;
  video?: ReactNode;
  divider?: ReactNode;
  inverted: boolean;
  noOverlay?: boolean;
  overlayStyle: StyleVariant;
  overlayOpacity: number;
};

const Hero: React.FC<Props> = ({
  label,
  title,
  description,
  copy,
  buttons,
  small,
  backgroundImage,
  video,
  divider,
  inverted = true,
  noOverlay,
  overlayStyle,
  overlayOpacity,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <section
        className={cn(s.hero, {
          [s.isSmall]: small,
          [s.isRegular]: !small,
          [s.inverted]: inverted,
        })}
      >
        {!small && (
          <>
            <div className={s.topImage}>
              <Image
                src={swooshDefaultTop}
                alt="Swoosh Top"
                objectFit="contain"
                layout="intrinsic"
                quality="100"
              />
            </div>
          </>
        )}

        {small ? (
          <>
            <div className={s.visual}>
              {(() => {
                if (isMobile && backgroundImage) {
                  return (
                    <div className={cn(s.background)}>{backgroundImage}</div>
                  );
                }
                if (video) {
                  return <div className={cn(s.background)}>{video}</div>;
                }
                if (backgroundImage) {
                  return (
                    <div className={cn(s.background)}>{backgroundImage}</div>
                  );
                }
                return null;
              })()}
              {noOverlay ? null : overlayStyle !== undefined ? (
                <BlockBackground
                  style={overlayStyle}
                  opacity={overlayOpacity}
                />
              ) : (
                <BlockBackground style={"default"} opacity={50} />
              )}
            </div>

            <div className={cn(s.inner, c.xWide)}>
              <div className={cn(s.content)}>
                {label && <span className={s.label}>{label}</span>}
                {title && <h1>{title}</h1>}
                <BlockIntro
                  description={description}
                  inverted={inverted}
                  alignment={"alignLeft"}
                  width="contained"
                />
                {buttons && buttons[0] && (
                  <div className={cn(s.buttons)}>{buttons}</div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={cn(s.inner, c.xxWide)}>
            <div className={cn(s.content)}>
              {label && <span className={s.label}>{label}</span>}
              {title && <h1>{title}</h1>}
              <BlockIntro
                description={copy}
                inverted={false}
                alignment={"alignLeft"}
                buttons={buttons}
                gap={"large"}
              />
            </div>
            <div className={s.visual}>
              {(() => {
                if (isMobile && backgroundImage) {
                  return (
                    <div className={cn(s.background)}>{backgroundImage}</div>
                  );
                }
                if (video) {
                  return <div className={cn(s.background)}>{video}</div>;
                }

                if (backgroundImage) {
                  return (
                    <div className={cn(s.background)}>{backgroundImage}</div>
                  );
                }
                return null;
              })()}
            </div>
          </div>
        )}
      </section>
      {divider && divider}
    </>
  );
};

export default Hero;
