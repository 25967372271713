import { useMemo } from "react";
import {
  StyleVariant,
  ButtonStyleVariant,
} from "../lib/storyblok/types/shared";

/**
 * Defines styles that should have dark/inverted styling
 */
const darkStyles: ReadonlyArray<StyleVariant> = [
  "black",
  "signature",
  "blue",
  "oceanBlue",
  "violet",
  "peach",
];

/**
 * Defines styles that should have light/default styling
 */
const lightStyles: ReadonlyArray<StyleVariant> = [
  "none",
  "white",
  "gray",
  "frostBlue",
] as const;

/**
 * Determines if a style should be treated as a dark style
 * @param style The style to check
 * @returns True if the style should use dark styling
 */
const isDarkStyle = (style: StyleVariant | null | undefined): boolean => {
  // Explicitly defined dark styles
  if (style && darkStyles.includes(style)) {
    return true;
  }

  // Undefined is treated as dark
  if (style === undefined) {
    return true;
  }

  // Null is explicitly handled to match undefined behavior
  if (style === null) {
    return true;
  }

  // Handle empty string (which could be a valid StyleVariant
  // depending on the type definition)
  if (style === "") {
    return false;
  }

  // If none of the above, check if it's in lightStyles
  return !style || !lightStyles.includes(style as StyleVariant);
};

/**
 * Hook to determine button styling based on the background style
 * @param style The background style variant
 * @returns Object with button styling properties
 */
export const useButtonStyle = (style?: StyleVariant | null) => {
  return useMemo(() => {
    const isInverted = isDarkStyle(style);
    return {
      isInverted,
      buttonVariant: isInverted
        ? ("inverted" as ButtonStyleVariant)
        : ("default" as ButtonStyleVariant),
    };
  }, [style]);
};

/**
 * Hook to determine content styling based on the background style
 * @param style The background style variant
 * @returns True for dark content styling, false for light content styling
 */
export const useContentStyle = (style?: StyleVariant | null) => {
  return useMemo(() => isDarkStyle(style), [style]);
};
