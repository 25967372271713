import { useTranslation } from "next-i18next";
import { storyblokEditable } from "@storyblok/react";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { ResourcePageStory } from "../../lib/storyblok/types/stories/ResourcePageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Agenda from "../../ui/Agenda";
import Details from "../../ui/Details";
import WithSidebar from "../../ui/PageTemplates/WithSidebar";
import Share from "../../ui/Share";
import Tags from "../../ui/Tags";
import Tag from "../Tag";
import { DynamicBlocks } from "../DynamicBlock";
import Button from "../../ui/Button";
import { useRelations } from "../../lib/storyblok/useRelations";
import PartOfEvent from "../../ui/PartOfEvent";
import ImageVaultImage from "../ImageVaultImage";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { EventPageStory } from "../../lib/storyblok/types/stories/EventPageStory";
import FeaturedHero from "../../ui/FeaturedHero";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";
import ShareItems from "../../ui/Share/components/ShareItems";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import Image from "next/image";
import WeChat from "../../public/images/wechat.jpg";
import Body from "../../ui/PageTemplates/WithSidebar/components/Body";
import EditorialWithoutSpacing from "../Nestable/EditorialWithoutSpacing";
import { useLinks } from "../../lib/storyblok/useLinks";
import SpeakersUi from "../../ui/SpeakersV2";
import { Speaker } from "../../lib/storyblok/types/stories/Speaker";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";

type VM = ViewModel<ResourcePageStory>;

const { Sidebar } = WithSidebar;

const ResourcePage: StoryblokStoryComponent<VM> = ({ story }) => {
  const { getRelation } = useRelations();
  const { t } = useTranslation(["resourcepage", "tags", "common"]);

  const {
    heroImage,
    heroTitle,
    heroPreamble,
    heroDate,
    heroButtons,
    noHeroOverlay,
    details,
    event,
    editorial,
    agendaTitle,
    agenda,
    callToActionText,
    callToActionSection,
    resourceType,
    taggedProducts,
    taggedSolutions,
    speakers,
    speakersv2,
    speakersv2Title,
    contentGrid,
  } = story.content;

  const resolveLink = useLinks();
  const { globalLinks, locale } = useViewmodel();

  const eventStory = getRelation<EventPageStory>(event);

  return (
    <>
      <FeaturedHero
        backgroundImage={
          heroImage && isValidImageData(heroImage) ? (
            <ImageVaultImage
              priority
              image={heroImage}
              layout="fill"
              sizes="100vw"
            />
          ) : null
        }
        label={
          resourceType?.length > 0
            ? t(resourceType[0], { ns: "tags" })
            : undefined
        }
        title={heroTitle}
        preamble={heroPreamble}
        date={heroDate && FormatDateRange(heroDate)}
        buttons={
          <>
            {callToActionText && (
              <Button variant="cta" href="#cta-section">
                {callToActionText}
              </Button>
            )}
            {heroButtons?.length > 0 && <DynamicBlocks blocks={heroButtons} />}
          </>
        }
        noOverlay={noHeroOverlay}
      />
      <WithSidebar>
        <Body>
          {editorial?.length > 0 && (
            <DynamicBlocks
              blocks={editorial}
              overrides={{ editorial: EditorialWithoutSpacing }}
            />
          )}
          {agenda?.length > 0 && (
            <Agenda title={agendaTitle}>
              {agenda.map((i) => (
                <div key={i._uid} {...storyblokEditable(i)}>
                  <Agenda.Item time={i.time} description={i.description} />
                </div>
              ))}
            </Agenda>
          )}
          {callToActionText && (
            <Button href="#cta-section" alignment={"center"}>
              {callToActionText}
            </Button>
          )}
        </Body>
        <Sidebar>
          {details && details.length > 0 && (
            <Details>
              {details.map((d) => (
                <div key={d._uid} {...storyblokEditable(d)}>
                  <Details.Detail
                    icon={d.icon}
                    title={d.title}
                    subtitle={d.subtitle}
                  />
                </div>
              ))}
            </Details>
          )}
          {(resourceType || taggedProducts || taggedSolutions) && (
            <Tags>
              {resourceType && resourceType.length > 0 && (
                <Tags.TagList title={t("resource-type")}>
                  {resourceType?.map((tag) => {
                    const baseUrl = globalLinks.mainResourceFilterPage
                      ? resolveLink(globalLinks.mainResourceFilterPage)
                      : "";
                    const url = `${baseUrl}?types=${tag}`;
                    return (
                      <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                    );
                  })}
                </Tags.TagList>
              )}
              {taggedProducts && taggedProducts.length > 0 && (
                <Tags.TagList title={t("tagged-products")}>
                  {taggedProducts?.map((tag) => {
                    const baseUrl = globalLinks.mainResourceFilterPage
                      ? resolveLink(globalLinks.mainResourceFilterPage)
                      : "";
                    const url = `${baseUrl}?categories=${tag}`;
                    return (
                      <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                    );
                  })}
                </Tags.TagList>
              )}
              {taggedSolutions && taggedSolutions.length > 0 && (
                <Tags.TagList title={t("tagged-solutions")}>
                  {taggedSolutions?.map((tag) => {
                    const baseUrl = globalLinks.mainResourceFilterPage
                      ? resolveLink(globalLinks.mainResourceFilterPage)
                      : "";
                    const url = `${baseUrl}?solutions=${tag}`;
                    return (
                      <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                    );
                  })}
                </Tags.TagList>
              )}
            </Tags>
          )}
          <Share
            weChat={
              locale === "zh" && (
                <Image src={WeChat} quality={100} sizes="100vw" alt="WeChat" />
              )
            }
          >
            <ShareItems />
          </Share>

          {eventStory && (
            <PartOfEvent
              image={
                eventStory.content.image &&
                isValidImageData(eventStory.content.image) ? (
                  <ImageVaultImage
                    image={eventStory.content.image}
                    layout="fill"
                    sizes={imageSizes("100vw", { laptopLarge: "22rem" })}
                  />
                ) : null
              }
              eventType={eventStory.content.eventType?.[0]}
              title={eventStory.content.title}
              date={
                eventStory.content.eventDate &&
                FormatDateRange(eventStory.content.eventDate)
              }
              location={eventStory.content.eventLocation}
              link={resolveSlug(eventStory.full_slug)}
            />
          )}
        </Sidebar>
      </WithSidebar>
      {callToActionSection && (
        <div id="cta-section">
          <DynamicBlocks blocks={callToActionSection} />
        </div>
      )}
      {speakersv2?.length > 0 && (
        <SpeakersUi title={speakersv2Title}>
          {speakersv2.map((speaker) => {
            const story = getRelation<Speaker>(speaker);

            if (story) {
              const { uuid, content } = story;

              return (
                <SpeakersUi.Speaker
                  key={uuid}
                  image={
                    content.image && isValidImageData(content.image) ? (
                      <ImageVaultImage
                        sizes="33vw"
                        image={content.image}
                        layout="fill"
                      />
                    ) : null
                  }
                  name={content.name}
                  title={content.title}
                  description={content.description}
                  linkedinLink={
                    content.linkedinLink.url &&
                    resolveLink(content.linkedinLink)
                  }
                  showMoreLabel={t("show-more", { ns: "common" })}
                  showLessLabel={t("show-less", { ns: "common" })}
                />
              );
            }
          })}
        </SpeakersUi>
      )}
      <DynamicBlocks blocks={[...(speakers ?? []), ...(contentGrid ?? [])]} />
    </>
  );
};

export default ResourcePage;
