import { ReactNode } from "react";
import cn from "classnames";
import s from "./Authors.module.scss";
import c from "../../styles/container.module.scss";

import Author from "././components/Author";

type Props = {
  title: string;
  children: ReactNode;
};

const subComponents = { Author };
type SubComponents = typeof subComponents;

const Authors: CWS<Props, SubComponents> = ({ title, children }: Props) => (
  <section className={cn(s.authors, c.xWide)}>
    <p className={cn(s.title)}>{title}</p>
    <ul>{children}</ul>
  </section>
);

Authors.Author = Author;

export default Authors;
