import Head from "next/head";
import { useTranslation } from "next-i18next";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { EventPageStory } from "../../lib/storyblok/types/stories/EventPageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Agenda from "../../ui/Agenda";
import Details from "../../ui/Details";
import WithSidebar from "../../ui/PageTemplates/WithSidebar";
import Share from "../../ui/Share";
import Tags from "../../ui/Tags";
import Tag from "../Tag";
import { DynamicBlocks } from "../DynamicBlock";
import ImageVaultImage, {
  getStoryblokCropURI,
  imageVaultLoader,
  storyblokLoader,
} from "../ImageVaultImage";
import FeaturedHero from "../../ui/FeaturedHero";
import Button from "../../ui/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLinks } from "../../lib/storyblok/useLinks";
import ShareItems from "../../ui/Share/components/ShareItems";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import Image, { ImageLoader } from "next/image";
import WeChat from "../../public/images/wechat.jpg";
import Body from "../../ui/PageTemplates/WithSidebar/components/Body";
import { MediaQueryHider } from "../../ui/MediaQueryHider/MediaQueryHider";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";
import EditorialWithoutSpacing from "../Nestable/EditorialWithoutSpacing";
import { deduceDomain } from "../../lib/utils/deduceDomain";

import { useRelations } from "../../lib/storyblok/useRelations";
import SpeakersUi from "../../ui/SpeakersV2";
import { Speaker } from "../../lib/storyblok/types/stories/Speaker";
import { StoryblokAsset } from "../../lib/storyblok/types/fieldtypes/imagevaultFieldtype";
import Alert from "../../ui/Common/Alert";
import { isValidImageData } from "../../lib/utils/imageUtils";

type VM = ViewModel<EventPageStory>;

const { Sidebar } = WithSidebar;

const EventPage: StoryblokStoryComponent<VM> = ({ story }) => {
  const {
    heroImage,
    heroTitle,
    heroPreamble,
    heroButtons,
    callToActionText,
    noHeroOverlay,
    eventDate,
    startDate,
    eventLocation,
    eventAvailability,
    eventLanguages,
    details,
    relatedDocuments,
    contacts,
    editorial,
    agendaTitle,
    agenda,
    resourcesFromEvent,
    speakers,
    speakersv2,
    speakersv2Title,
    callToActionSection,
    contentGrid,
    eventType,
    taggedProducts,
    taggedSolutions,
  } = story.content;

  const resolveLink = useLinks();
  const { getRelation } = useRelations();
  const { t } = useTranslation(["eventpage", "tags", "common"]);
  const { globalLinks, locale, site } = useViewmodel();
  const [eventHasExpired, setEventHasExpired] = useState(false);

  // Replace space with 'T' for ISO 8601 format and Append 'Z' to indicate the date is in UTC
  const modifiedStartDate =
    startDate && startDate !== "Z"
      ? startDate.replace(" ", "T") + "Z"
      : undefined;

  const modifiedEventDate =
    eventDate && eventDate !== "Z"
      ? eventDate.replace(" ", "T") + "Z"
      : undefined;

  const hasEventExpired = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return today > new Date(date);
  };

  useEffect(() => {
    if (modifiedEventDate) {
      setEventHasExpired(hasEventExpired(modifiedEventDate));
    }
  }, [modifiedEventDate]);

  const isStoryblokAsset =
    !!story.content?.seoImage &&
    "filename" in story.content.seoImage &&
    "fieldtype" in story.content.seoImage;
  const isImageVaultWithItem =
    !!story.content?.seoImage &&
    "plugin" in story.content.seoImage &&
    "item" in story.content.seoImage &&
    story.content.seoImage?.item !== undefined;
  const storyblokAsset =
    !!story.content?.seoImage && isStoryblokAsset
      ? (story.content.seoImage as StoryblokAsset)
      : undefined;

  const seoImageUrl = useMemo(() => {
    if (storyblokAsset) {
      const cropData = storyblokAsset.meta_data?.crop;
      const cropURI = getStoryblokCropURI(cropData);
      return storyblokAsset.filename + cropURI;
    } else if (
      isImageVaultWithItem &&
      story.content.seoImage.item?.MediaConversions?.[0]?.Url
    ) {
      return (
        "/" +
        story.content.seoImage.item.MediaConversions[0].Url.split("/")
          .slice(3)
          .join("/")
      );
    }
    return "";
  }, [story, isImageVaultWithItem, storyblokAsset]);

  const loader: ImageLoader = useCallback(
    ({ src, width, quality }) => {
      return storyblokAsset
        ? storyblokLoader({ src, width, quality })
        : imageVaultLoader({ src, width, quality });
    },
    [storyblokAsset]
  );

  const seoImageMetaContentUrl = useMemo(() => {
    if (storyblokAsset) {
      return loader({
        src: seoImageUrl,
        width: 1200,
        quality: 100,
      });
    }
    return (
      deduceDomain(locale, site) +
      loader({
        src: seoImageUrl,
        width: 1200,
        quality: 100,
      })
    );
  }, [loader, locale, seoImageUrl, site, storyblokAsset]);

  // Event schema
  // Source: https://developers.google.com/search/docs/appearance/structured-data/event
  const eventSchema = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: heroTitle || "",
    startDate: modifiedStartDate || modifiedEventDate || "",
    endDate: modifiedEventDate || "",
    eventAttendanceMode: "https://schema.org/MixedEventAttendanceMode",
    eventStatus: "https://schema.org/EventScheduled",
    location: {
      "@type": "Place",
      name: eventLocation || "",
    },
    image: seoImageMetaContentUrl,
    description: heroPreamble || story.content.seoMeta?.description,
    organizer: {
      "@type": "Organization",
      name: "Tobii",
      url: deduceDomain(locale, site),
    },
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(eventSchema) }}
        />
      </Head>
      <FeaturedHero
        backgroundImage={
          heroImage && isValidImageData(heroImage) ? (
            <ImageVaultImage
              priority
              image={heroImage}
              layout="fill"
              sizes="100vw"
            />
          ) : null
        }
        label={
          eventType?.length > 0 ? t(eventType[0], { ns: "tags" }) : undefined
        }
        title={heroTitle}
        preamble={heroPreamble}
        date={modifiedEventDate}
        startDate={modifiedStartDate}
        location={eventLocation}
        buttons={
          <>
            {callToActionText && !eventHasExpired && (
              <Button variant="inverted" href="#cta-section">
                {callToActionText}
              </Button>
            )}
            {heroButtons?.length > 0 && <DynamicBlocks blocks={heroButtons} />}
          </>
        }
        noOverlay={noHeroOverlay}
      />
      <WithSidebar>
        <Body>
          <MediaQueryHider hideAboveDesktop>
            {eventHasExpired && (
              <Alert
                variant="info"
                title={t("the-event-has-concluded", { ns: "eventpage" })}
              />
            )}
            <Details>
              {eventDate && (
                <Details.Detail
                  icon="symbolicon-calendar"
                  title={
                    modifiedStartDate
                      ? FormatDateRange(modifiedStartDate, modifiedEventDate)
                      : FormatDateRange(modifiedEventDate)
                  }
                />
              )}
              {eventLocation && (
                <Details.Detail
                  icon="location"
                  title={eventLocation}
                  subtitle={t(eventAvailability, { ns: "tags" })}
                />
              )}
              {eventLanguages && eventLanguages.length > 0 && (
                <Details.Detail
                  icon="world"
                  title={eventLanguages
                    .map((tag) => t(tag, { ns: "common" }))
                    .join(", ")}
                />
              )}
              {details &&
                details.length > 0 &&
                details.map((d) => (
                  <Details.Detail
                    key={d._uid}
                    icon={d.icon}
                    title={d.title}
                    subtitle={d.subtitle}
                  />
                ))}
            </Details>
          </MediaQueryHider>
          {editorial && (
            <DynamicBlocks
              blocks={editorial}
              overrides={{ editorial: EditorialWithoutSpacing }}
            />
          )}
          {agenda?.length >= 1 && (
            <Agenda title={agendaTitle}>
              {agenda.map((i) => (
                <Agenda.Item
                  key={i._uid}
                  time={i.time}
                  description={i.description}
                />
              ))}
            </Agenda>
          )}
        </Body>
        <Sidebar>
          <MediaQueryHider hideBelowDesktop>
            {eventHasExpired && (
              <Alert
                variant="info"
                title={t("the-event-has-concluded", { ns: "eventpage" })}
              />
            )}
            <Details>
              {eventDate && (
                <Details.Detail
                  icon="symbolicon-calendar-date"
                  title={
                    modifiedStartDate
                      ? FormatDateRange(modifiedStartDate, modifiedEventDate)
                      : FormatDateRange(modifiedEventDate)
                  }
                />
              )}
              {eventLocation && (
                <Details.Detail
                  icon="location"
                  title={eventLocation}
                  subtitle={t(eventAvailability, { ns: "tags" })}
                />
              )}
              {eventLanguages && eventLanguages.length > 0 && (
                <Details.Detail
                  icon="world"
                  title={eventLanguages
                    .map((tag) => t(tag, { ns: "common" }))
                    .join(", ")}
                />
              )}
              {details &&
                details.length > 0 &&
                details.map((d) => (
                  <Details.Detail
                    key={d._uid}
                    icon={d.icon}
                    title={d.title}
                    subtitle={d.subtitle}
                  />
                ))}
            </Details>
          </MediaQueryHider>

          {relatedDocuments && relatedDocuments.length > 0 && (
            <Details isRelatedDocuments>
              {relatedDocuments.map((d) => (
                <Details.Document
                  key={d._uid}
                  icon={d.icon}
                  title={d.title}
                  subtitle={d.subtitle}
                  href={resolveLink(d.link)}
                />
              ))}
            </Details>
          )}
          {(eventType || taggedProducts || taggedSolutions) && (
            <Tags>
              {eventType && eventType.length > 0 && (
                <Tags.TagList title={t("event-type")}>
                  {eventType?.map((tag) => {
                    const baseUrl = globalLinks.mainEventFilterPage
                      ? resolveLink(globalLinks.mainEventFilterPage)
                      : "";
                    const url = `${baseUrl}?types=${tag}`;
                    return (
                      <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                    );
                  })}
                </Tags.TagList>
              )}
              {taggedProducts && taggedProducts.length > 0 && (
                <Tags.TagList title={t("tagged-products")}>
                  {taggedProducts?.map((tag) => {
                    const baseUrl = globalLinks.mainEventFilterPage
                      ? resolveLink(globalLinks.mainEventFilterPage)
                      : "";
                    const url = `${baseUrl}?categories=${tag}`;
                    return (
                      <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                    );
                  })}
                </Tags.TagList>
              )}
              {taggedSolutions && taggedSolutions.length > 0 && (
                <Tags.TagList title={t("tagged-solutions")}>
                  {taggedSolutions?.map((tag) => {
                    const baseUrl = globalLinks.mainEventFilterPage
                      ? resolveLink(globalLinks.mainEventFilterPage)
                      : "";
                    const url = `${baseUrl}?solutions=${tag}`;
                    return (
                      <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                    );
                  })}
                </Tags.TagList>
              )}
            </Tags>
          )}
          <DynamicBlocks blocks={contacts} />
          <Share
            weChat={
              locale === "zh" && (
                <Image src={WeChat} quality={100} sizes="100vw" alt="WeChat" />
              )
            }
          >
            <ShareItems />
          </Share>
        </Sidebar>
      </WithSidebar>
      {resourcesFromEvent && <DynamicBlocks blocks={resourcesFromEvent} />}
      {speakersv2?.length > 0 && (
        <SpeakersUi title={speakersv2Title}>
          {speakersv2.map((speaker) => {
            const story = getRelation<Speaker>(speaker);

            if (story) {
              const { uuid, content } = story;

              return (
                <SpeakersUi.Speaker
                  key={uuid}
                  image={
                    content.image && isValidImageData(content.image) ? (
                      <ImageVaultImage
                        sizes="33vw"
                        image={content.image}
                        layout="fill"
                      />
                    ) : null
                  }
                  name={content.name}
                  title={content.title}
                  description={content.description}
                  linkedinLink={
                    content.linkedinLink.url &&
                    resolveLink(content.linkedinLink)
                  }
                  showMoreLabel={t("show-more", { ns: "common" })}
                  showLessLabel={t("show-less", { ns: "common" })}
                />
              );
            }
          })}
        </SpeakersUi>
      )}
      {speakers && <DynamicBlocks blocks={speakers} />}
      {callToActionSection && (
        <div id="cta-section">
          <DynamicBlocks blocks={callToActionSection} />
        </div>
      )}
      {contentGrid && <DynamicBlocks blocks={contentGrid} />}
    </>
  );
};

export default EventPage;
