import React, { useEffect } from "react";
import cn from "classnames";
import s from "./MobileMenu.module.scss";
import useScrollBlock from "../../../../hooks/useScrollBlock";
import { useTranslation } from "next-i18next";
import Icon from "../../../Icon";
import TobiiLogo from "../../../Logos/TobiiLogo";

type Props = {
  onLangClick: () => void;
  onSearchClick?: React.MouseEventHandler<HTMLAnchorElement>;
  isOpen: boolean;
  searchHref?: string;
  toggleOpen: () => void;
  isTobiiGroup?: boolean;
};

export const MobileHeader = ({
  isOpen,
  toggleOpen,
  onSearchClick,
  searchHref,
  isTobiiGroup = false,
}: Props) => {
  const { t } = useTranslation();
  const [, setLocked] = useScrollBlock();
  const siteTitle = isTobiiGroup ? "Tobii Corporate" : "Tobii";

  useEffect(() => {
    setLocked(isOpen);
  }, [isOpen, setLocked]);

  return (
    <div className={cn(s.mobileMenuHeader, { [s.isOpen]: isOpen })}>
      <a
        href="/"
        title={siteTitle}
        className={cn(s.logo, { [s.logoCorporate]: isTobiiGroup })}
      >
        <span className="visuallyHidden">{t("home")}</span>
        <TobiiLogo
          color={
            isOpen
              ? isTobiiGroup
                ? s.tobiigroupiconcolor
                : s.iconcolor
              : isTobiiGroup
              ? s.tobiigroupiconcolor
              : s.iconcolor
          }
        />
        {isTobiiGroup && <span>Corporate</span>}
      </a>
      <div className={s.navBtnContainer}>
        <div className={s.langAndSearch}>
          <a
            href={searchHref}
            onClick={onSearchClick}
            className={s.searchIcon}
            title={t("search")}
          >
            <span className="visuallyHidden">{t("search")}</span>
            <Icon
              width={20}
              icon="search"
              color={isTobiiGroup ? s.tobiigroupiconcolor : s.iconcolor}
            />
          </a>
        </div>
        <button
          className={s.mobileNavButton}
          onClick={toggleOpen}
          title={t("menu")}
        >
          <Icon
            icon={isOpen ? "cancel" : "mobile-nav-button"}
            width={25}
            color={isTobiiGroup ? s.tobiigroupiconcolor : s.iconcolor}
          />
        </button>
      </div>
    </div>
  );
};
