import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./CTABlock.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import c from "../../styles/container.module.scss";
import {
  StyleVariant,
  InnerSpacingVariant,
  OuterSpacingVariant,
  WidthVariant,
} from "../../lib/storyblok/types/shared";

import BlockBackground from "../Common/BlockBackground";
import BlockIntro from "../Common/BlockIntro";

type Props = {
  style: StyleVariant;
  title: string;
  text: string;
  buttons?: ReactNode;
  spacing: InnerSpacingVariant;
  outerSpacing: OuterSpacingVariant;
  width: WidthVariant;
  inverted?: boolean;
};

const CTABlock = ({
  style = "signature",
  title,
  text,
  buttons,
  spacing,
  outerSpacing,
  width,
  inverted,
}: Props) => {
  return (
    <section>
      <div
        className={cn(
          s.cta,
          s[style],
          bs[spacing],
          bs[outerSpacing],
          width === "wide"
            ? c.xWide
            : width === "fluid"
            ? c.fluid
            : width === "narrow"
            ? c.narrow
            : "",
          {
            [s.roundedCorners]:
              width !== "fluid" &&
              width !== undefined &&
              !!width &&
              width !== "Full width",
          }
        )}
      >
        <BlockBackground style={style} opacity={100} />
        <div className={s.container}>
          <BlockIntro
            title={title}
            description={text}
            width={width === "narrow" ? "full" : "contained"}
            alignment={"alignCenter"}
            inverted={inverted}
            buttons={buttons}
            spaceHorizontal={true}
          />
        </div>
      </div>
    </section>
  );
};

export default CTABlock;
