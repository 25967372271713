import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./ContentGridSlider.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import Item from "./components/Item";
import Navigation from "./components/Navigation";
import BlockIntro from "../Common/BlockIntro";

type Props = {
  title: string;
  description?: string;
  spacing?: string;
  isStartpage?: boolean;
  children: ReactNode;
};

const subComponents = { Item, Navigation };
type SubComponents = typeof subComponents;

const ContentGridSlider: CWS<Props, SubComponents> = ({
  title,
  description,
  spacing,
  children,
}) => {
  return (
    <section
      className={cn(s.section, bs["both"], {
        [s.noTopSpacing]: spacing === "noTop",
        [s.noBottomSpacing]: spacing === "noBottom",
        [s.noSpacing]: spacing === "noSpacing",
      })}
    >
      <div className={cn(c.xWide, s.intro)}>
        <BlockIntro
          alignment={"alignLeft"}
          inverted={false}
          title={title}
          description={description}
          buttons={""}
        />
      </div>
      <div className={cn(c.xWide, s.contentGrid)}>{children}</div>
    </section>
  );
};

ContentGridSlider.Item = Item;
ContentGridSlider.Navigation = Navigation;

export default ContentGridSlider;
