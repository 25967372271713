import React, { ReactNode } from "react";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import s from "./Agenda.module.scss";

import Item from "./components/Item";

type Props = {
  title: string;
  children: ReactNode;
};

const subComponents = { Item };
type SubComponents = typeof subComponents;

const Agenda: CWS<Props, SubComponents> = ({ title, children }) => (
  <section className={cn(s.agenda, c.narrow)}>
    <h2>{title}</h2>
    <ul className={s.items}>{children}</ul>
  </section>
);

Agenda.Item = Item;

export default Agenda;
