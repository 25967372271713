import React, { HTMLProps } from "react";
import s from "../FilterList.module.scss";
import cn from "classnames";
import { useFilterListContext } from "../FilterListContext";

type FilterProps = {
  children: string;
  value: string | number;
  amount?: number;
  type?: "checkbox" | "radio";
  onChange?: () => void;
  checked?: boolean;
} & HTMLProps<HTMLInputElement>;

const Filter = ({
  children,
  amount,
  className,
  type = "checkbox",
  onChange,
  checked,
  ...props
}: FilterProps) => {
  const { name, onSelectionChange } = useFilterListContext();

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }

    if (onSelectionChange) {
      onSelectionChange(e.target.checked);
    }
  };

  return (
    <li className={cn(className, s.filter)}>
      <label className={s.filterLabel}>
        <input
          {...props}
          className={cn(s.filterInput)}
          name={name}
          type={type}
          checked={checked}
          onChange={handleChange}
        />
        <span className={s.filterContainer}>
          <span className={s.name}>{children}</span>
          {amount && <span className={s.count}>{amount}</span>}
        </span>
      </label>
    </li>
  );
};

export default Filter;
