import Link from "next/link";
import React from "react";
import { TextAndVideoBlock } from "../../lib/storyblok/types/blocks/TextAndVideoBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import Button from "../../ui/Button";
import MediaAndImageUi from "../../ui/MediaAndImage";
import { DynamicBlocks } from "../DynamicBlock";
import { useContentStyle, useButtonStyle } from "../../hooks/useStyle";

const TextAndVideo: StoryblokBlockComponent<TextAndVideoBlock> = ({
  style,
  text,
  title,
  video,
  reversed,
  dark,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  typeformCopy,
  typeformID,
  typeformSize,
  typeformButtonStyle,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
}) => {
  const resolveLink = useLinks();
  const contentStyle = useContentStyle(style);
  const isInverted = style === "" || style === undefined ? false : contentStyle;
  const { buttonVariant } = useButtonStyle(style);

  return (
    <MediaAndImageUi
      style={style === "white" || style === "gray" ? "none" : style}
      outerSpacing={outerSpacing}
      mediaBackground={mediaBackground}
      imageKeepProportions={imageKeepProportions}
      layout={layout}
      reversed={reversed}
      dark={dark}
      title={title}
      preamble={text}
      video={<DynamicBlocks blocks={video} />}
      button={
        buttonText && (
          <Link href={resolveLink(buttonLink)} passHref prefetch={false}>
            <Button href={resolveLink(buttonLink)} variant={buttonVariant}>
              {buttonText}
            </Button>
          </Link>
        )
      }
      secondButton={
        secondButtonText && (
          <Link passHref href={resolveLink(secondButtonLink)} prefetch={false}>
            <Button
              href={resolveLink(secondButtonLink)}
              variant={buttonVariant}
            >
              {secondButtonText}
            </Button>
          </Link>
        )
      }
      typeformCopy={typeformCopy}
      typeformID={typeformID}
      typeformSize={typeformSize}
      typeformButtonStyle={typeformButtonStyle}
      inverted={isInverted}
    />
  );
};

export default TextAndVideo;
