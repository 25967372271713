import { ReactNode } from "react";
import cn from "classnames";
import s from "./Details.module.scss";

import Detail from "./components/Detail";
import Document from "./components/Document";

type Props = {
  withoutHero?: boolean;
  isRelatedDocuments?: boolean;
  children: ReactNode;
};

const subComponents = { Detail, Document };
type SubComponents = typeof subComponents;

const Details: CWS<Props, SubComponents> = ({
  withoutHero,
  isRelatedDocuments,
  children,
}: Props) => (
  <section
    className={cn(s.details, {
      [s.withoutHero]: withoutHero,
      [s.relatedDocuments]: isRelatedDocuments,
    })}
  >
    <ul>{children}</ul>
  </section>
);

Details.Detail = Detail;
Details.Document = Document;

export default Details;
