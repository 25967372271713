import React from "react";
import Image from "next/image";

interface IconWithColorProps {
  icon?: string;
  alt: string;
  color?: string;
  width?: number;
  height?: number;
  className?: string;
  priority?: boolean;
  quality?: number;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  style?: React.CSSProperties;
  role?: string;
  title?: string;
  loading?: "lazy" | "eager";
  disabled?: boolean;
  opacity?: number;
}

const IconWithColor = ({
  icon,
  color = "white",
  width = 18,
  height = 18,
  alt,
  className = "",
  priority = false,
  quality = 75,
  onClick,
  onMouseEnter,
  onMouseLeave,
  style = {},
  role = "img",
  title,
  loading = "lazy",
  disabled = false,
  opacity = 1,
}: IconWithColorProps) => {
  // Convert your hex or rgba colors via a tool like this: https://cssfilterconverter.com/
  const getColors = {
    signature:
      "brightness(0) saturate(100%) invert(9%) sepia(39%) saturate(2818%) hue-rotate(220deg) brightness(94%) contrast(106%)",
    oceanBlue:
      "brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1513%) hue-rotate(195deg) brightness(88%) contrast(112%)",
    gradientRadialTopBlueSignature:
      "brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1513%) hue-rotate(195deg) brightness(88%) contrast(112%)",
    gradientRadialCenterBlueSignature:
      "brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1513%) hue-rotate(195deg) brightness(88%) contrast(112%)",
    gradientLinearBlueSignature:
      "brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1513%) hue-rotate(195deg) brightness(88%) contrast(112%)",
    frostBlue:
      "brightness(0) saturate(100%) invert(97%) sepia(99%) saturate(3769%) hue-rotate(178deg) brightness(109%) contrast(109%)",
    peach:
      "brightness(0) saturate(100%) invert(49%) sepia(72%) saturate(523%) hue-rotate(316deg) brightness(102%) contrast(98%)",
    violet:
      "brightness(0) saturate(100%) invert(40%) sepia(40%) saturate(1391%) hue-rotate(229deg) brightness(84%) contrast(112%)",
    white: "brightness(0) invert(1)",
    black: "brightness(0)",
  } as const;

  const combinedStyles = {
    ...style,
    filter: getColors[color as keyof typeof getColors],
    opacity: disabled ? 0.5 : opacity,
    cursor: onClick && !disabled ? "pointer" : "default",
  };

  return (
    <Image
      src={`/svg/icons/${icon}.svg`}
      width={width}
      height={height}
      alt={alt}
      className={`transition-all duration-200 ${className}`}
      priority={priority}
      quality={quality}
      onClick={!disabled ? onClick : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={combinedStyles}
      role={role}
      title={title}
      loading={loading}
      draggable={false}
    />
  );
};

export default IconWithColor;

/*
// Usage examples
// With specific opacity
<IconWithColor
  icon="search"
  color="tobii-violet"
  opacity={0.7}
/>

// With hover opacity using Tailwind
<IconWithColor
  icon="search"
  color="tobii-ocean-blue"
  className="hover:opacity-70"
/>

// Combining opacity with other styles
<IconWithColor
  icon="arrow"
  color="white"
  opacity={0.8}
  style={{ mixBlendMode: 'difference' }}
/>

// Note: disabled state will still override any opacity value
<IconWithColor
  icon="search"
  color="tobii-violet"
  opacity={0.7}
  disabled  // This will set opacity to 0.5 regardless of the opacity prop
/>
*/
