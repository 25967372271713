import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Overlay from "../../ui/Overlay";
import { OverlayBlock } from "../../lib/storyblok/types/blocks/OverlayBlock";
import VideoPlayer from "../../ui/Common/VideoPlayer";
import ImageVaultImage from "../ImageVaultImage";
import Link from "next/link";
import Button from "../../ui/Button";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useContentStyle } from "../../hooks/useStyle";
import { isValidImageData } from "../../lib/utils/imageUtils";

const OverlayBlock: StoryblokBlockComponent<OverlayBlock> = ({
  title,
  description,
  buttons,
  image,
  video,
  overlayStyle,
  overlayOpacity,
  contentAlignment,
  innerSpacing,
  outerSpacing,
  width,
}) => {
  const resolveLink = useLinks();
  const isInverted = useContentStyle(overlayStyle);

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType ?? "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  return (
    <Overlay
      title={title}
      description={description}
      buttons={buttonCollection}
      image={
        image && isValidImageData(image) ? (
          <ImageVaultImage image={image} layout="fill" sizes="50vw" priority />
        ) : null
      }
      video={
        video?.filename && (
          <VideoPlayer
            key="video"
            videoUrl={video?.filename}
            thumbnail={
              image && isValidImageData(image) ? (
                <ImageVaultImage
                  image={image}
                  layout="fill"
                  sizes="100vw"
                  objectFit="cover"
                  priority
                />
              ) : null
            }
            thumbnailMode="thumbnailOnly"
            isActive={true}
          />
        )
      }
      overlayStyle={overlayStyle}
      overlayOpacity={overlayOpacity.value}
      contentAlignment={contentAlignment}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      width={width}
      inverted={isInverted}
    />
  );
};

export default OverlayBlock;
