import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./PartOfEvent.module.scss";
import { useTranslation } from "next-i18next";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";

type Props = {
  image: ReactNode;
  eventType: string;
  title: string;
  date: string;
  location: string;
  link: string;
};

const PartOfEvent = ({
  image,
  eventType,
  title,
  date,
  location,
  link,
}: Props) => {
  const { t } = useTranslation();

  return (
    <a href={link} className={s.partOfEvent} title={title}>
      {image && <div className={s.imageContainer}> {image}</div>}
      <div className={s.overlay}></div>
      <div className={cn(s.card)}>
        {eventType && <p className={cn("label", s.label)}>{eventType}</p>}
        {title && <h4 className={s.title}>{title}</h4>}
        <ul className={s.details}>
          {date ? (
            <li className={cn("date")}>{FormatDateRange(date)}</li>
          ) : null}
          {location && (
            <li className={cn("location")}>{t(location, { ns: "tags" })}</li>
          )}
        </ul>
      </div>
    </a>
  );
};

export default PartOfEvent;
