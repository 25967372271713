import { createContext, useContext } from "react";

type FilterContextProps = {
  name: string;
  onSelectionChange?: (isSelected: boolean) => void;
};

export const FilterListContext = createContext<FilterContextProps>({
  name: "default",
  onSelectionChange: undefined,
});

export const useFilterListContext = () =>
  useContext<FilterContextProps>(FilterListContext);
