import algoliasearch, { SearchClient } from "algoliasearch";

export const initAlgoliaSearch = (): SearchClient => {
  const ID = process.env.NEXT_PUBLIC_ALGOLIA_ID;
  const KEY = process.env.NEXT_PUBLIC_ALGOLIA_KEY;

  if (!ID) {
    throw new Error("Missing Algolia ID");
  }
  if (!KEY) {
    throw new Error("Missing Algolia Key");
  }

  return algoliasearch(ID, KEY);
};

export const initAlgoliaIndecies = () => {
  const EN_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_EN;
  const ZH_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_ZH;
  const JA_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_JA;
  const ES_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_ES;
  const DE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_DE;
  const FR_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_FR;

  if (!EN_INDEX) {
    throw new Error("Missing Algolia Global Search Index for English");
  }
  if (!ZH_INDEX) {
    throw new Error("Missing Algolia Global Search Index for Chinese");
  }
  if (!JA_INDEX) {
    throw new Error("Missing Algolia Global Search Index for Japanese");
  }
  if (!ES_INDEX) {
    throw new Error("Missing Algolia Global Search Index for Spanish");
  }
  if (!DE_INDEX) {
    throw new Error("Missing Algolia Global Search Index for German");
  }
  if (!FR_INDEX) {
    throw new Error("Missing Algolia Global Search Index for French");
  }

  return {
    en: EN_INDEX,
    zh: ZH_INDEX,
    ja: JA_INDEX,
    es: ES_INDEX,
    de: DE_INDEX,
    fr: FR_INDEX,
  } as const;
};

export const initAlgoliaInvestorIndecies = () => {
  const EN_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_INVESTOR_SEARCH_INDEX_EN;
  const SV_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_INVESTOR_SEARCH_INDEX_SV;

  if (!EN_INDEX) {
    throw new Error("Missing Algolia Pressrelease Search Index for English");
  }
  if (!SV_INDEX) {
    throw new Error("Missing Algolia Pressrelease Search Index for Swedish");
  }
  return {
    en: EN_INDEX,
    sv: SV_INDEX,
  } as const;
};
