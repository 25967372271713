import { ReactNode } from "react";
import cn from "classnames";
import s from "../ContentGridSlider.module.scss";
import BlockIntro from "../../Common/BlockIntro";

type ImageAlignment = "center" | "left";

type Props = {
  image: ReactNode;
  label?: string;
  title: string;
  text: string;
  link: ReactNode;
  href: string;
  imageAlignment?: ImageAlignment;
};

const Item = ({
  image,
  label,
  title,
  link,
  text,
  href,
  imageAlignment,
}: Props) => (
  <article className={s.item}>
    <a className={s.link} title={title} href={href}>
      <div className={cn(s.imageContainer, s[`align${imageAlignment}`])}>
        {image}
      </div>
      <BlockIntro
        label={label}
        title={title}
        description={text}
        descriptionSize={"small"}
        descriptionStyle={"faded"}
        alignment={"alignLeft"}
        inverted={false}
        width={"full"}
        headingTag="h3"
      />
    </a>
    {link}
  </article>
);

export default Item;
