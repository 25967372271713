import React, { ReactNode, useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  activeItemIndex: number | null;
  navRef?: React.RefObject<HTMLElement>;
};

export const DesktopNav = ({ children, activeItemIndex, navRef }: Props) => {
  const [dimensions, setDimensions] = useState({
    position: 0,
    width: 0,
    opacity: 0,
  });
  const internalNavRef = useRef<HTMLUListElement>(null);
  const prevActiveIndexRef = useRef<number | null>(null);

  useEffect(() => {
    const updateHighlight = () => {
      if (activeItemIndex !== null && internalNavRef.current) {
        const listItem = internalNavRef.current.children[
          activeItemIndex
        ] as HTMLElement;
        if (!listItem) return;

        // Find the inner link/span element
        const linkElement = listItem.querySelector("a, span") as HTMLElement;
        if (!linkElement) return;

        const { offsetWidth } = linkElement;
        const parentPadding = parseInt(
          window.getComputedStyle(listItem).paddingLeft || "0",
          10
        );

        // Calculate position including parent padding
        const newPosition = listItem.offsetLeft + parentPadding;
        const newWidth = offsetWidth;

        if (prevActiveIndexRef.current === null) {
          // First activation
          setDimensions({
            position: newPosition,
            width: newWidth,
            opacity: 1,
          });
        } else if (prevActiveIndexRef.current !== activeItemIndex) {
          // Transition between items
          setDimensions({
            position: newPosition,
            width: newWidth,
            opacity: 1,
          });
        }

        prevActiveIndexRef.current = activeItemIndex;
      } else if (prevActiveIndexRef.current !== null) {
        // Deactivation
        setDimensions((prev) => ({ ...prev, opacity: 0 }));
        prevActiveIndexRef.current = null;
      }
    };

    const debouncedUpdate = () => {
      requestAnimationFrame(updateHighlight);
    };

    updateHighlight();

    // Handle resize with debounce
    let resizeTimer: number;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = window.setTimeout(debouncedUpdate, 100);
    };

    window.addEventListener("resize", handleResize);

    // Handle potential parent container resizes
    const resizeObserver = new ResizeObserver(debouncedUpdate);
    if (navRef?.current) {
      resizeObserver.observe(navRef.current);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(resizeTimer);
      resizeObserver.disconnect();
    };
  }, [activeItemIndex, navRef]);

  return (
    <nav className={s.nav} ref={navRef}>
      <ul className={s.navList} ref={internalNavRef}>
        {children}
        <AnimatePresence>
          {activeItemIndex !== null && (
            <motion.li
              className={s.highlight}
              initial={false}
              animate={{
                x: dimensions.position,
                width: dimensions.width,
                opacity: dimensions.opacity,
              }}
              transition={{
                x: { type: "spring", stiffness: 400, damping: 30 },
                width: { type: "spring", stiffness: 400, damping: 30 },
                opacity: { duration: 0.3 },
              }}
            />
          )}
        </AnimatePresence>
      </ul>
    </nav>
  );
};
