// components/Item.tsx
import React, { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import s from "../Accordion.module.scss";
import Icon from "../../Icon";
import BlockIntro from "../../Common/BlockIntro";

type Props = {
  title: string;
  description: string;
  index: number;
  expanded: boolean;
  onChange: () => void;
  totalItems: number;
  inverted?: boolean;
  onKeyboardNavigation: (targetIndex: number) => void;
};

const Item = ({
  title,
  description,
  index,
  expanded,
  onChange,
  totalItems,
  onKeyboardNavigation,
  inverted,
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Enter":
      case " ":
        e.preventDefault();
        onChange();
        break;

      case "ArrowDown":
        e.preventDefault();
        if (index < totalItems - 1) {
          onKeyboardNavigation(index + 1);
        }
        break;

      case "ArrowUp":
        e.preventDefault();
        if (index > 0) {
          onKeyboardNavigation(index - 1);
        }
        break;

      case "Home":
        e.preventDefault();
        onKeyboardNavigation(0);
        break;

      case "End":
        e.preventDefault();
        onKeyboardNavigation(totalItems - 1);
        break;

      default:
        break;
    }
  };

  return (
    <div
      className={cn(s.item, { [s.inverted]: inverted, [s.expanded]: expanded })}
    >
      <button
        ref={buttonRef}
        className={s.header}
        onClick={onChange}
        onKeyDown={handleKeyDown}
        aria-expanded={expanded}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        {title && <h4 className={s.title}>{title}</h4>}
        <motion.div transition={{ duration: 0.3 }} className={s.iconWrapper}>
          <Icon
            icon={expanded ? "minus" : "add"}
            width={20}
            color={inverted ? s.iconColorInverted : s.iconColor}
            className={cn(s.icon)}
          />
        </motion.div>
      </button>

      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.3, ease: "easeOut" },
                opacity: { duration: 0.2, delay: 0.1 },
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.3, ease: "easeInOut" },
                opacity: { duration: 0.2 },
              },
            }}
            className={s.contentWrapper}
            id={`panel${index}-content`}
            role="region"
            aria-labelledby={`panel${index}-header`}
          >
            <div className={s.contentInner}>
              <BlockIntro
                description={description}
                inverted={inverted}
                alignment={"alignLeft"}
                width={"full"}
                spaceHorizontal={false}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Item;
