/* eslint-disable */
import { useViewmodel } from "../storyblok/ViewmodelContext";

const isValidDate = (date: Date | null): boolean => {
  if (date === null) {
    return false;
  }
  return !isNaN(date.getTime());
};

export const FormatDateRange = (startDate, endDate?) => {
  const { locale } = useViewmodel();

  try {
    const startDateObj = startDate ? new Date(startDate) : null;
    const endDateObj = endDate ? new Date(endDate) : null;

    if (startDate && !isValidDate(startDateObj)) {
      console.error("Invalid Start Date:", startDateObj);
      throw new Error("Invalid Start Date");
    }
    if (endDate && !isValidDate(endDateObj)) {
      console.error("Invalid End Date:", endDateObj);
      throw new Error("Invalid End Date");
    }

    const startMonth = startDateObj
      ? startDateObj.toLocaleString(locale, { month: "long", timeZone: "CET" })
      : "";
    const endMonth = endDateObj
      ? endDateObj.toLocaleString(locale, { month: "long", timeZone: "CET" })
      : "";
    const startDay = startDateObj
      ? startDateObj.toLocaleString(locale, { day: "numeric", timeZone: "CET" })
      : "";

    const endDay = endDateObj
      ? endDateObj.toLocaleString(locale, { day: "numeric", timeZone: "CET" })
      : "";
    const startYear = startDateObj
      ? startDateObj.toLocaleString(locale, {
          year: "numeric",
          timeZone: "CET",
        })
      : "";
    const endYear = endDateObj
      ? endDateObj.toLocaleString(locale, { year: "numeric", timeZone: "CET" })
      : "";

    let formattedDateRange = "";

    if (startDateObj && endDateObj) {
      // Both dates available
      if (startYear === endYear) {
        if (startMonth === endMonth) {
          if (startDay !== endDay) {
            formattedDateRange = `${startMonth} ${startDay} - ${endDay}, ${endYear}`;
          } else {
            formattedDateRange = `${startMonth} ${startDay}, ${endYear}`;
          }
        } else {
          formattedDateRange = `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
        }
      } else {
        formattedDateRange = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
      }
    } else if (startDateObj) {
      // Only start date available
      formattedDateRange = `${startMonth} ${startDay}, ${startYear}`;
    } else if (endDateObj) {
      // Only end date available test
      formattedDateRange = `${endMonth} ${endDay}, ${endYear}`;
    }

    return formattedDateRange;
  } catch (error) {
    console.error("Error formatting date range:", error);
    return "Date Range Error";
  }
};
