import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ImagevaultMigrationBlock } from "../../lib/storyblok/types/blocks/ImagevaultMigrationBlock";
import ImageVaultImage from "../ImageVaultImage";
import { imageSizes } from "../../lib/utils/imageUtils";
import ImagevaultMigrationUi from "../../ui/ImagevaultMigration";

const ImagevaultMigration: StoryblokBlockComponent<
  ImagevaultMigrationBlock
> = ({
  hero,
  editorial,
  content,
  logo,
  image1,
  image2,
  image3,
  image4,
  image5,
}) => {
  const wide = true;
  return (
    <ImagevaultMigrationUi
      hero={
        <ImageVaultImage image={hero} layout="fill" sizes="500vw" priority />
      }
      editorial={
        <ImageVaultImage
          sizes={imageSizes(
            "100vw",
            wide ? { xl: "1200px" } : { laptop: "720px" }
          )}
          image={editorial}
          layout="fill"
        />
      }
      content={
        <ImageVaultImage
          sizes={imageSizes("100vw", { xl: "750px", laptop: "33vw" })}
          image={content}
          layout="fill"
        />
      }
      logo={<ImageVaultImage image={logo} layout="intrinsic" />}
      image1={<ImageVaultImage sizes="400px" image={image1} layout="fill" />}
      image2={
        <ImageVaultImage
          sizes="96px"
          quality={90}
          image={image2}
          layout="fill"
        />
      }
      image3={
        <ImageVaultImage
          sizes={imageSizes("720px")}
          image={image3}
          layout="intrinsic"
          contain
        />
      }
      image4={
        <ImageVaultImage
          image={image4}
          layout="fill"
          sizes="720px"
          objectFit="cover"
          priority
        />
      }
      image5={
        <ImageVaultImage width={720} height={405} quality={50} image={image5} />
      }
    />
  );
};

export default ImagevaultMigration;
