import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";
import { useRefinementList } from "react-instantsearch";
import FilterList from "../../ui/FilterList";

type RefinementListItem = {
  value: string;
  label: string;
  highlighted?: string;
  count: number;
  isRefined: boolean;
};

type RefinementListProps = {
  layout?: "dropdown" | "expand";
};

const RefinementList = ({ layout = "dropdown" }: RefinementListProps) => {
  const { t } = useTranslation("searchpage");

  const translateLabel = useCallback(
    (items: RefinementListItem[]) =>
      items.map((item) => ({ ...item, label: t(`pages.${item.value}`) })),
    [t]
  );
  const { items, refine } = useRefinementList({
    limit: 99,
    attribute: "storyType",
    sortBy: ["name:asc"],
    transformItems: translateLabel,
  });

  return (
    <>
      <FilterList layout={layout}>
        {items.map(({ label, count, value, isRefined }) => (
          <FilterList.Filter
            key={label}
            amount={count}
            value={value}
            checked={isRefined}
            onChange={() => refine(value)}
          >
            {label}
          </FilterList.Filter>
        ))}
      </FilterList>
    </>
  );
};

export default RefinementList;
