import React, { ReactNode } from "react";
import Image from "next/image";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { HeroBlock } from "../../lib/storyblok/types/blocks/HeroBlock";
import Hero from "../../ui/Hero";
import VideoPlayer from "../../ui/Common/VideoPlayer";
import Button from "../../ui/Button";
import ImageVaultImage from "../ImageVaultImage";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";
import { ButtonStyleVariant } from "../../lib/storyblok/types/shared";
import { useContentStyle } from "../../hooks/useStyle";
import { isValidImageData } from "../../lib/utils/imageUtils";
import videoThumbnailFallback from "../../public/images/chapter-divider-02.png";

type Props = {
  anchorLinksMenu?: ReactNode;
};

const HeroBlock: StoryblokBlockComponent<HeroBlock & Props> = ({
  component,
  title,
  description,
  copy,
  label,
  backgroundImage,
  videoThumbnail,
  video,
  buttons,
  anchorLinksMenu,
  inverted,
  noOverlay,
  overlayStyle,
  overlayOpacity,
}) => {
  const resolveLink = useLinks();
  const overlayInverted = useContentStyle(overlayStyle);
  const isInverted = overlayInverted !== undefined ? overlayInverted : inverted;
  const safeOpacityValue = overlayOpacity?.value ?? 0.5;

  const buttonCollection = buttons?.map((button) => {
    let buttonType: ButtonStyleVariant = button.buttonType || "default";
    if (!component.includes("Small") && buttonType === "inverted") {
      buttonType = "default";
    }

    return (
      <Link
        key={button._uid}
        href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
        passHref
      >
        <Button
          variant={buttonType ?? "default"}
          href={resolveLink(button.buttonLink)}
          size={"medium"}
        >
          {button.buttonText}
        </Button>
      </Link>
    );
  });

  return (
    <>
      <Hero
        title={title}
        description={description}
        copy={copy}
        label={label}
        backgroundImage={
          backgroundImage && isValidImageData(backgroundImage) ? (
            <ImageVaultImage
              image={backgroundImage}
              layout="fill"
              sizes="100vw"
              objectFit="cover"
              priority
            />
          ) : null
        }
        video={
          video?.filename && (
            <VideoPlayer
              key="video"
              videoUrl={video?.filename}
              thumbnail={
                videoThumbnail && isValidImageData(videoThumbnail) ? (
                  <ImageVaultImage
                    image={videoThumbnail}
                    layout="fill"
                    sizes="100vw"
                    objectFit="cover"
                    priority
                  />
                ) : (
                  <Image
                    src={videoThumbnailFallback}
                    layout="fill"
                    objectFit="cover"
                  />
                )
              }
              thumbnailMode="clickable"
              isActive={true}
            />
          )
        }
        small={component.includes("Small")}
        buttons={buttonCollection}
        noOverlay={noOverlay}
        inverted={isInverted}
        overlayStyle={overlayStyle}
        overlayOpacity={safeOpacityValue}
      />
      {anchorLinksMenu && anchorLinksMenu}
    </>
  );
};

export default HeroBlock;
