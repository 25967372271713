import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ColumnGrid from "../../ui/ColumnGrid";
import { ColumnGridTextItemBlock } from "../../lib/storyblok/types/blocks/ColumnGridTextItemBlock";
import { useLinks } from "../../lib/storyblok/useLinks";
import Button from "../../ui/Button";
import { useContentStyle, useButtonStyle } from "../../hooks/useStyle";

const { Item } = ColumnGrid;

const ColumnGridTextItem: StoryblokBlockComponent<ColumnGridTextItemBlock> = ({
  _uid,
  title,
  markdown,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
}) => {
  const resolveLink = useLinks();
  const { buttonVariant } = useButtonStyle(style);
  const contentStyle = useContentStyle(style);
  const isInverted = style === "" || style === undefined ? false : contentStyle;

  return (
    <Item key={_uid} title={title} markdown={markdown} inverted={isInverted}>
      {buttonText && buttonLink && (
        <Button
          href={resolveLink(buttonLink)}
          variant={buttonVariant}
          openInNewTab={buttonLink.linktype === "asset"}
        >
          {buttonText}
        </Button>
      )}
      {secondButtonText && secondButtonLink && (
        <Button
          href={resolveLink(secondButtonLink)}
          variant={buttonVariant}
          openInNewTab={secondButtonLink.linktype === "asset"}
        >
          {secondButtonText}
        </Button>
      )}
    </Item>
  );
};

export default ColumnGridTextItem;
