import React from "react";
import useMediaQuery from "../../hooks/useMatchMedia";
import s from "./Pagination.module.scss";
import Icon from "../Icon";
import cn from "classnames";

const Pagination = ({
  currentPage,
  pageCount,
  onPageChange,
  labelPrevious,
  labelNext,
  labelPage,
}) => {
  const isMobile = useMediaQuery("(max-width: 540px)");

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };
  const totalPagesToShow = isMobile ? 2 : 3;

  const pageButtons: JSX.Element[] = [];

  const startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
  const endPage = Math.min(pageCount, startPage + totalPagesToShow - 1);

  if (currentPage > 2) {
    const isActive = currentPage === startPage;
    pageButtons.push(
      <React.Fragment key="first-group">
        <li key="first-page">
          <button
            key="first"
            onClick={() => handlePageChange(1)}
            className={isActive ? `${s.active} ${s.button}` : s.button}
            title={`${labelPage} 1`}
          >
            1
          </button>
        </li>
        {!isMobile && (
          <li key="ellipsis-1">{currentPage > 2 && <span>...</span>}</li>
        )}
      </React.Fragment>
    );
  }

  for (let page = startPage; page <= endPage; page++) {
    const isActive = currentPage === page;
    pageButtons.push(
      <li key={`page-${page}`}>
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={isActive ? `${s.active} ${s.button}` : s.button}
          title={`${labelPage} ${page}`}
        >
          {page}
        </button>
      </li>
    );
  }

  if (currentPage < pageCount - 1) {
    pageButtons.push(
      <React.Fragment key="last-group">
        {!isMobile && currentPage < pageCount - 2 && (
          <li key="last-ellipsis">
            <span>...</span>
          </li>
        )}
        <li key="last-page">
          <button
            onClick={() => handlePageChange(pageCount)}
            className={
              currentPage === pageCount ? `${s.active} ${s.button}` : s.button
            }
            title={`${labelPage} ${pageCount}`}
          >
            {pageCount}
          </button>
        </li>
      </React.Fragment>
    );
  }

  if (currentPage > 1) {
    pageButtons.unshift(
      <li key="prev">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className={s.button}
          title={labelPrevious}
        >
          <Icon icon="caret-left" width={16} className={cn(s.icon)} />
        </button>
      </li>
    );
  }

  if (currentPage < pageCount) {
    pageButtons.push(
      <li key="next">
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className={s.button}
          title={labelNext}
        >
          <Icon icon="caret-right" width={16} className={cn(s.icon)} />
        </button>
      </li>
    );
  }
  return <ul className={s.paginationContainer}>{pageButtons}</ul>;
};
export default Pagination;
