import React from "react";
import { isValidImageData } from "../../lib/utils/imageUtils";
import cn from "classnames";
import s from "./DualMediaBlock.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import { MediaAndImageSmall } from "../MediaAndImage";
import ImageVaultImage from "../../components/ImageVaultImage";
import { useRelations } from "../../lib/storyblok/useRelations";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { useTranslation } from "next-i18next";
import { Story } from "../../lib/storyblok/types/story";
import FlexibleLinkWrapper from "../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";
import c from "../../styles/container.module.scss";
import {
  StyleVariant,
  OuterSpacingVariant,
  ButtonStyleVariant,
  PercentageVariant,
} from "../../lib/storyblok/types/shared";

const ArticleItem = ({ article, subTitle }) => {
  const { t } = useTranslation("articlepage");

  return (
    <FlexibleLinkWrapper
      href={resolveSlug(article?.full_slug || "")}
      className={cn(s.imageLink)}
    >
      <div className={cn(s.card)}>
        <div className={cn(s.imageWrapper)}>
          <div>
            <>
              {isValidImageData(article?.content.image) ? (
                <ImageVaultImage
                  sizes={"320px"}
                  image={article.content.image}
                  alt={article?.content.title || ""}
                  layout="fill"
                />
              ) : null}
            </>
          </div>
        </div>
        <div className={cn(s.cardContent)}>
          <p className={cn(s.oneThirdSubTitle)}>{subTitle}</p>
          {article?.content.title && (
            <p className={cn(s.oneThirdDescription)}>
              {article?.content.title}
            </p>
          )}
          <div className={s.detailsWrapper}>
            <ul className={s.details}>
              {article?.content.author && (
                <li className={cn(s.filterCardLabel)}>
                  {article.content.author}
                </li>
              )}

              {article?.content.readTime && (
                <li>
                  {article.content.readTime} {t("minutesShort")}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </FlexibleLinkWrapper>
  );
};

type Props = {
  featured: boolean;
  reversed: boolean;
  latestArticle?: Story;
  featuredArticle: string;
  topArticles: string[];
  editorsPick: string;
  mostPopular: string;
  highlight: string;
  imageKeepProportions: boolean;
  style: StyleVariant;
  outerSpacing: OuterSpacingVariant;
  mediaBackground:
    | "mediaBgWhite"
    | "mediaBgGray"
    | "mediaBgSignature"
    | "mediaBgBlack"
    | "mediaBgLitterbox";
  layout: "layout11" | "layout12" | "layout21";
  typeformCopy?: string;
  typeformID?: string;
  typeformSize?: PercentageVariant;
  typeformButtonStyle?: ButtonStyleVariant;
};

const DualMediaBlock = ({
  reversed,
  style,
  latestArticle,
  featuredArticle,
  editorsPick,
  mostPopular,
  highlight,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
}: Props) => {
  const { getRelation } = useRelations();

  const resolveArticle = (articleId?: string | null): Story | undefined => {
    if (articleId !== null && articleId !== undefined) {
      const resolved = getRelation(articleId);
      if (resolved !== null) {
        return resolved;
      }
    }
    return latestArticle;
  };

  const featuredArticleStory = resolveArticle(featuredArticle);
  const editorsPickStory = resolveArticle(editorsPick);
  const mostPopularStory = resolveArticle(mostPopular);
  const highlightStory = resolveArticle(highlight);
  const { t } = useTranslation("articlepage");
  return (
    <>
      <section className={cn(s.dualMedia, c.xWide, bs["both"])}>
        {featuredArticle && (
          <div className={cn(s.twoThirds)}>
            <a
              className={cn(s.twoThirdsLink)}
              href={resolveSlug(featuredArticleStory?.full_slug || "")}
            >
              <MediaAndImageSmall
                style={style}
                title={featuredArticleStory?.content.title || ""}
                preamble={featuredArticleStory?.content.description || ""}
                image={
                  featuredArticleStory?.content.image &&
                  isValidImageData(featuredArticleStory?.content.image) ? (
                    <ImageVaultImage
                      sizes={"768px"}
                      image={featuredArticleStory?.content.image}
                      layout="fill"
                      alt={featuredArticleStory?.content.title || ""}
                    />
                  ) : null
                }
                author={featuredArticleStory?.content.author}
                publishedAt={featuredArticleStory?.content.createdAt}
                readTime={featuredArticleStory?.content.readTime}
                reversed={reversed}
                outerSpacing={outerSpacing}
                mediaBackground={mediaBackground}
                imageKeepProportions={imageKeepProportions}
                layout={layout}
                typeformCopy={""}
                typeformID={""}
                typeformSize={70}
                typeformButtonStyle={"default"}
              />
            </a>
          </div>
        )}

        <div className={cn(s.oneThird)}>
          <div className={cn(s.cards)}>
            <ArticleItem
              article={editorsPickStory}
              subTitle={t("editors-pick")}
            />
            <ArticleItem
              article={mostPopularStory}
              subTitle={t("most-popular")}
            />
            <ArticleItem article={highlightStory} subTitle={t("highlight")} />
          </div>
        </div>
      </section>
    </>
  );
};

export default DualMediaBlock;
