import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./ColumnGrid.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import {
  StyleVariant,
  InnerSpacingVariant,
  OuterSpacingVariant,
  WidthVariant,
} from "../../lib/storyblok/types/shared";

import Item from "./components/Item";
import BlockIntro from "../Common/BlockIntro";
import BlockBackground from "../Common/BlockBackground";

type Props = {
  title: string;
  description?: string;
  buttons?: ReactNode;
  children: ReactNode;
  centered?: boolean;
  twoColumns?: boolean;
  style: StyleVariant;
  spacing: InnerSpacingVariant;
  outerSpacing: OuterSpacingVariant;
  width: WidthVariant;
  inverted?: boolean;
};

const subComponents = { Item };
type SubComponents = typeof subComponents;

const ColumnGrid: CWS<Props, SubComponents> = ({
  title,
  description,
  buttons,
  children,
  twoColumns,
  centered,
  spacing,
  style,
  outerSpacing,
  width,
  inverted,
}) => {
  return (
    <section
      className={cn(
        s.columnGrid,
        s[style],
        bs[outerSpacing],
        bs[spacing],
        width === "wide" ? [c.xWide, s.wide] : "",
        {
          [s.roundedCorners]:
            width !== "fluid" && width !== undefined && !!width,
          [bs.none]: spacing === undefined,
        }
      )}
    >
      <BlockBackground style={style} opacity={100} />

      <div className={cn(s.inner, c.xWide)}>
        <BlockIntro
          alignment={centered ? "alignCenter" : "alignLeft"}
          inverted={inverted}
          title={title}
          description={description}
          buttons={buttons}
          width={"contained"}
        />
        <div
          className={cn(s.grid, {
            [s.twoColumns]: twoColumns,
          })}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

ColumnGrid.Item = Item;

export default ColumnGrid;
