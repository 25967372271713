import { useRef } from "react";
import { useTranslation } from "next-i18next";
import { useRelations } from "../../lib/storyblok/useRelations";
import { ContentGridSliderBlock } from "../../lib/storyblok/types/blocks/ContentGridSliderBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Button from "../../ui/Button";
import ImageVaultImage from "../ImageVaultImage";
import ContentGridSliderUi from "../../ui/ContentGridSlider";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import useMediaQuery from "../../hooks/useMatchMedia";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import SliderButton from "../../ui/Common/SliderButton/SliderButton";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";

const ContentGridSlider: StoryblokBlockComponent<ContentGridSliderBlock> = ({
  title,
  description,
  items,
  verticalSpacing,
  isStartpage,
}) => {
  const { getRelation } = useRelations();
  const { t } = useTranslation();
  const swiperRef = useRef<SwiperRef>(null);

  const isLaptop = useMediaQuery("(max-width: 1024px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const shouldShowNavigation =
    (isMobile && items.length > 1) ||
    (!isMobile && isLaptop && items.length > 2) ||
    (!isLaptop && items.length > 3);

  const slideNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <>
      <ContentGridSliderUi
        title={title}
        description={description}
        spacing={verticalSpacing}
        isStartpage={isStartpage}
      >
        <Swiper
          centeredSlides={false}
          loop={true}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          mousewheel={false}
          slidesPerView={1}
          spaceBetween={32}
          breakpoints={{
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          ref={swiperRef}
        >
          {items.map((item) => {
            const story = getRelation(item);
            if (story) {
              const { uuid, content, name, full_slug } = story;
              const category = content.category;
              const label = category || full_slug.split("/").reverse()[1];
              const slug = resolveSlug(full_slug);

              // For cases like "customer-story" we want the image to be on the left
              const imageAlignment =
                Array.isArray(content.resourceType) &&
                content.resourceType.includes("customer-story")
                  ? "left"
                  : "center";

              return (
                <SwiperSlide key={uuid}>
                  <ContentGridSliderUi.Item
                    href={slug}
                    image={
                      isValidImageData(content.image) ? (
                        <ImageVaultImage
                          sizes={imageSizes("720px")}
                          image={content.image}
                          layout="fill"
                        />
                      ) : null
                    }
                    label={!isStartpage ? t(label, { ns: "tags" }) : ""}
                    title={content.title || name}
                    text={content.description || ""}
                    imageAlignment={imageAlignment}
                    link={
                      isStartpage ? (
                        <Button
                          variant="default"
                          href={slug}
                          title={content.title || name}
                          icon="arrow-right"
                        >
                          {t("learn-more")}
                        </Button>
                      ) : null
                    }
                  />
                </SwiperSlide>
              );
            }
          })}
        </Swiper>

        {shouldShowNavigation && (
          <ContentGridSliderUi.Navigation>
            <SliderButton
              onClick={slidePrev}
              align="prev"
              label={t("previous")}
              inverted={false}
            />
            <SliderButton
              onClick={slideNext}
              align="next"
              label={t("next")}
              inverted={false}
            />
          </ContentGridSliderUi.Navigation>
        )}
      </ContentGridSliderUi>
    </>
  );
};

export default ContentGridSlider;
