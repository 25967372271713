import React from "react";
import cn from "classnames";
import s from "./BlockBackground.module.scss";

type Props = {
  style: string;
  opacity: number | undefined;
};

const BlockBackground = ({ style, opacity }: Props) => {
  const normalizedOpacity = opacity !== undefined ? opacity / 100 : 0.5;
  return (
    <div
      className={cn(s.blockBackground, s[style])}
      style={{ opacity: normalizedOpacity }}
    ></div>
  );
};

export default BlockBackground;
