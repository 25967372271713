import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./Speakers.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";

import Speaker from "./components/Speaker";
import ExpandButton from "./components/ExpandButton";

type Props = {
  title: string;
  children: ReactNode;
};

const subComponents = { Speaker, ExpandButton };
type SubComponents = typeof subComponents;

const Speakers: CWS<Props, SubComponents> = ({ title, children }: Props) => {
  return (
    <section className={cn(c.fluid, s.speakers, bs["both"])}>
      <h2 className={cn(s.title, c.xWide)}>{title}</h2>
      <ul className={cn(c.xxWide, s.speakerList)}>{children}</ul>
    </section>
  );
};

Speakers.Speaker = Speaker;
Speakers.ExpandButton = ExpandButton;

export default Speakers;
