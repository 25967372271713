import { ReactNode } from "react";
import s from "./Share.module.scss";

import Item from "./components/Item";

type Props = {
  weChat?: ReactNode;
  children: ReactNode;
};

const subComponents = { Item };
type SubComponents = typeof subComponents;

const Share: CWS<Props, SubComponents> = ({ weChat, children }: Props) => (
  <section className={s.share}>
    {weChat && <div className={s.qrCode}>{weChat}</div>}
    <div>{children}</div>
  </section>
);

Share.Item = Item;

export default Share;
