import React, { useRef } from "react";
import cn from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";
import RichText from "../RichText";
import { isRichTextContent } from "../../../lib/storyblok/types/fieldtypes/richText";
import type {
  StoryblokRichTextFieldtype,
  RichTextContent,
} from "../../../lib/storyblok/types/fieldtypes/richText";
import {
  AlignmentVariant,
  HeadingVariant,
} from "../../../lib/storyblok/types/shared";
import Markdown from "../../Markdown";
import s from "./BlockIntro.module.scss";
import c from "../../../styles/container.module.scss";

interface BlockIntroProps {
  alignment: AlignmentVariant;
  label?: string;
  title?: string;
  description?: string | StoryblokRichTextFieldtype | RichTextContent;
  copy?: string | StoryblokRichTextFieldtype | RichTextContent;
  buttons?: React.ReactNode;
  width?: "full" | "featured" | "contained";
  inverted?: boolean;
  headingTag?: HeadingVariant;
  spaceHorizontal?: boolean;
  spaceVertical?: boolean;
  parallax?: boolean;
  parallaxOffset?: number;
  parallaxReverse?: boolean;
  titleSize?: "default" | "large";
  titleWeight?: "default" | "bold";
  descriptionSize?: "small" | "default" | "large";
  descriptionStyle?: "default" | "faded";
  descriptionWeight?: "default" | "bold";
  gap?: "default" | "large";
}

const BlockIntro: React.FC<BlockIntroProps> = ({
  label,
  alignment,
  title,
  titleSize = "default",
  titleWeight = "default",
  description,
  copy,
  buttons,
  width = "full",
  headingTag = "h2",
  inverted,
  spaceHorizontal = false,
  spaceVertical = false,
  parallax = false,
  parallaxOffset = 24,
  parallaxReverse = false,
  descriptionSize = "default",
  descriptionStyle = "default",
  descriptionWeight = "default",
  gap = "default",
}) => {
  const Heading = headingTag;
  const spaceHorizontalStyle = spaceHorizontal
    ? s.horizontalSpace
    : s.noHorizontalSpace;
  const spaceVerticalStyle = spaceVertical
    ? s.VerticalSpace
    : s.noVerticalSpace;

  const renderDescription = () => {
    if (!description) return null;

    if (isRichTextContent(description)) {
      return (
        <RichText
          content={description}
          className={cn(
            s.description,
            s[descriptionSize],
            s[descriptionStyle],
            s[descriptionWeight]
          )}
          inverted={inverted}
          alignment={alignment}
        />
      );
    }

    // Fallback to Markdown for string content
    return (
      <div
        className={cn(
          s.markdown,
          s.description,
          s[descriptionSize],
          s[descriptionStyle],
          s[descriptionWeight]
        )}
      >
        <Markdown>{description}</Markdown>
      </div>
    );
  };

  const renderCopy = () => {
    if (!copy) return null;

    if (isRichTextContent(copy)) {
      return (
        <RichText
          content={copy}
          className={cn(
            s.description,
            s[descriptionSize],
            s[descriptionStyle],
            s[descriptionWeight]
          )}
          inverted={inverted}
          alignment={alignment}
        />
      );
    }

    // Fallback to Markdown for string content
    return (
      <div
        className={cn(
          s.markdown,
          s.description,
          s[descriptionSize],
          s[descriptionStyle],
          s[descriptionWeight]
        )}
      >
        <Markdown>{copy}</Markdown>
      </div>
    );
  };

  // Parallax effect
  const introRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: introRef,
    offset: ["start end", "end start"],
  });
  const y = useTransform(
    scrollYProgress,
    [0, 1],
    parallaxReverse
      ? [-parallaxOffset, parallaxOffset]
      : [parallaxOffset, -parallaxOffset]
  );
  const ContentWrapper = parallax ? motion.div : "div";
  const motionStyles = parallax ? { y } : {};

  return (
    <ContentWrapper
      ref={introRef}
      style={motionStyles}
      className={cn(
        s.intro,
        spaceHorizontalStyle,
        spaceVerticalStyle,
        s[alignment],
        s[width],
        s[gap],
        {
          [s.inverted]: inverted,
          [c.xWide]: width === "contained",
          [c.xxWide]: width === "featured",
          [c.fluid]: width === "full",
        }
      )}
    >
      {label && <span className={s.label}>{label}</span>}
      {title && (
        <Heading className={cn(s.title, s[titleSize], s[titleWeight])}>
          {title}
        </Heading>
      )}
      {description && renderDescription()}
      {copy && renderCopy()}
      {buttons && <div className={s.buttons}>{buttons}</div>}
    </ContentWrapper>
  );
};

export default BlockIntro;
