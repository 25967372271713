import { ReactNode } from "react";
import s from "../TagsAndShareWrapper.module.scss";

type Props = {
  weChat?: ReactNode;
  children: ReactNode;
};

const ShareWrapper = ({ weChat, children }: Props) => (
  <div className={s.shareWrapper}>
    {weChat && <div className={s.qrCode}>{weChat}</div>}
    <div className={s.list}>{children}</div>
  </div>
);

export default ShareWrapper;
