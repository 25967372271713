import React, { HTMLProps } from "react";
import cn from "classnames";
import s from "../FilterPage.module.scss";

// Extend HTMLProps with our custom props
interface ToolbarProps extends HTMLProps<HTMLDivElement> {
  layout?: "altOne" | string; // Add additional layout options here
}

const Toolbar = ({ className, layout, ...props }: ToolbarProps) => (
  <div
    {...props}
    id="library"
    className={cn(className, s.toolbar, {
      [s.altOne]: layout === "altOne",
    })}
  />
);

export default Toolbar;
