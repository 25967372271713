import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ShareUi from "../../ui/Share";
import { ShareBlock } from "../../lib/storyblok/types/blocks/ShareBlock";
import { useLinks } from "../../lib/storyblok/useLinks";

const Share: StoryblokBlockComponent<ShareBlock> = ({ items }) => {
  const { Item } = ShareUi;

  const resolveLink = useLinks();

  return (
    <ShareUi>
      {items.map((item) => (
        <Item key={item._uid} icon={item.icon} href={resolveLink(item.href)} />
      ))}
    </ShareUi>
  );
};

export default Share;
