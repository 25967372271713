export const isForegroundImage = (content) => {
  if (content?.image?.item) {
    return false;
  }

  if (content.hero?.length > 0) {
    if (content.hero[0].foregroundImage) {
      return true;
    }

    if (content.hero[0].backgroundImage) {
      return false;
    }
  }
};
