import React, {
  ReactNode,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import ReactPlayer from "react-player";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import s from "./VideoPlayer.module.scss";
import Icon from "../../Icon";
import { useTranslation } from "next-i18next";

type Props = {
  videoUrl?: string;
  delay?: boolean;
  maxPlays?: number;
  onLoadComplete?: () => void;
  thumbnail?: ReactNode;
  thumbnailMode?: "clickable" | "thumbnailOnly";
  isActive?: boolean;
};

const VideoPlayer: React.FC<Props> = ({
  videoUrl,
  delay = false,
  maxPlays = 2,
  onLoadComplete,
  thumbnail,
  thumbnailMode = "thumbnailOnly",
  isActive,
}) => {
  const { t } = useTranslation(["common"]);
  const [playCount, setPlayCount] = useState(0);
  const [isTabActive, setIsTabActive] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [hasReachedMaxPlays, setHasReachedMaxPlays] = useState(false);

  const playerRef = useRef<ReactPlayer>(null);
  const { ref: containerRef, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (typeof window === "undefined") return;

    const ua = window.navigator.userAgent;
    const safariDetected = /^((?!chrome|android).)*safari/i.test(ua);
    const isIOS =
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    setIsSafari(safariDetected || isIOS);
  }, []);

  useEffect(() => {
    setPlayCount(0);
    setIsEnded(false);
    setHasInteracted(false);
    setHasReachedMaxPlays(false);

    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (typeof document === "undefined") return;

    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Monitor for max plays reached
  useEffect(() => {
    if (playCount >= maxPlays && !hasReachedMaxPlays) {
      setHasReachedMaxPlays(true);
      if (thumbnailMode === "clickable") {
        setHasInteracted(false);
      }
    }
  }, [playCount, maxPlays, hasReachedMaxPlays, thumbnailMode]);

  useEffect(() => {
    if (
      (isSafari && !hasInteracted) ||
      (isSafari && thumbnailMode === "thumbnailOnly") ||
      hasReachedMaxPlays ||
      !isActive
    )
      return;

    if (inView && isEnded && playCount < maxPlays) {
      setIsEnded(false);
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
    }
  }, [
    inView,
    isEnded,
    playCount,
    maxPlays,
    thumbnailMode,
    isSafari,
    hasInteracted,
    hasReachedMaxPlays,
    isActive,
  ]);

  const handleEnded = useCallback(() => {
    setIsEnded(true);
    setPlayCount((prev) => prev + 1);
  }, []);

  const handleReady = useCallback(() => {
    if (!onLoadComplete) return;

    if (delay) {
      const timeoutId = window.setTimeout(onLoadComplete, 100);
      return () => window.clearTimeout(timeoutId);
    } else {
      onLoadComplete();
    }
  }, [delay, onLoadComplete]);

  const handlePlayClick = useCallback(() => {
    setHasInteracted(true);
    setHasReachedMaxPlays(false);
    setPlayCount(0);
    setIsEnded(false);
  }, []);

  if (isSafari && thumbnailMode === "thumbnailOnly" && thumbnail) {
    return (
      <div ref={containerRef} className={s.videoWrapper}>
        {thumbnail}
      </div>
    );
  }

  const shouldShowThumbnail =
    thumbnail &&
    ((thumbnailMode === "clickable" &&
      ((isSafari && !hasInteracted) || hasReachedMaxPlays)) ||
      (thumbnailMode === "thumbnailOnly" &&
        (hasReachedMaxPlays || (isEnded && playCount >= maxPlays))));

  const shouldShowVideoPlayer =
    videoUrl &&
    // For Safari
    ((isSafari && hasInteracted && !hasReachedMaxPlays) ||
      // For other browsers
      (!isSafari &&
        !(
          (thumbnailMode === "clickable" && hasReachedMaxPlays) ||
          (thumbnailMode === "thumbnailOnly" &&
            (hasReachedMaxPlays || (isEnded && playCount >= maxPlays)))
        )));

  const shouldPlay =
    inView &&
    isTabActive &&
    isActive &&
    playCount < maxPlays &&
    !isEnded &&
    !hasReachedMaxPlays &&
    (!isSafari || hasInteracted);

  return (
    <div
      ref={containerRef}
      className={cn(s.videoWrapper, {
        [s.isCompleted]: playCount >= maxPlays,
        //[s.isSafari]: isSafari,
      })}
    >
      {shouldShowVideoPlayer && (
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          className={s.reactPlayer}
          width="100%"
          height="100%"
          playing={shouldPlay}
          playsinline
          muted
          onEnded={handleEnded}
          onReady={handleReady}
          config={{
            file: {
              attributes: {
                style: { objectFit: "cover" },
                playsInline: true,
                muted: true,
                preload: isSafari && !hasInteracted ? "none" : "auto",
              },
              forceVideo: true,
            },
          }}
        />
      )}

      {shouldShowThumbnail && (
        <div
          className={s.thumbnailWrapper}
          onClick={thumbnailMode === "clickable" ? handlePlayClick : undefined}
          tabIndex={thumbnailMode === "clickable" ? 0 : undefined}
          role={thumbnailMode === "clickable" ? "button" : undefined}
          aria-label={thumbnailMode === "clickable" ? t("play") : undefined}
          onKeyDown={
            thumbnailMode === "clickable"
              ? (e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    handlePlayClick();
                  }
                }
              : undefined
          }
        >
          {thumbnail}
          {thumbnailMode === "clickable" && (
            <>
              <div className={s.overlay} />
              <button
                className={s.playButton}
                onClick={handlePlayClick}
                aria-label={t("play")}
              >
                <span className={s.circle}>
                  <Icon icon="play" width={32} color={s.iconcolor} />
                </span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
