import { ReactNode } from "react";
import s from "../Tags.module.scss";

type Props = {
  title: string;
  children: ReactNode;
  hideTitle?: boolean;
  className?: string;
};

const TagList = ({ title, children }: Props) => (
  <article className={s.tagList}>
    <h5 className="label">{title}</h5>
    <ul>{children}</ul>
  </article>
);

export default TagList;
