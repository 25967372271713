import React, { useState } from "react";
import cn from "classnames";
import s from "./FinancialReportsTable.module.scss";
import c from "../../styles/container.module.scss";
import Table from "./components/Table";
import TableRow from "./components/TableRow";
import { FinancialReportSectionBlock } from "../../lib/storyblok/types/blocks/FinancialReportsTableBlock";
import TableCellWithLink from "./components/TableCellWithLink";
import { useTranslation } from "next-i18next";
import Button from "../Button";
import { InnerSpacingVariant } from "../../lib/storyblok/types/shared";

type Props = {
  title: string;
  spacing: InnerSpacingVariant;
  sections: FinancialReportSectionBlock[];
};

const subComponents = {
  Table,
  TableRow,
};
type SubComponents = typeof subComponents;

const FinancialReportsTable: CWS<Props, SubComponents> = ({
  title,
  spacing,
  sections,
}) => {
  const { t } = useTranslation("resourcepage");

  const [yearsToShow, setYearsToShow] = useState(2);
  const [noMoreRows, setNoMoreRows] = useState(false);

  const handleLearnMore = () => {
    if (yearsToShow + 1 <= sections.length) {
      setYearsToShow(yearsToShow + 1);
      if (yearsToShow + 1 === sections.length) {
        setNoMoreRows(true);
      }
    } else {
      setNoMoreRows(true);
    }
  };

  return (
    <section className={cn(s.background, c.fluid)}>
      <div className={cn(s.techSpec, c.xWide, s[spacing])}>
        <h3 className={cn("display", c.medium)}>{title}</h3>
        <div className={s.tableWrapper}>
          <div className={s.wrapper}>
            <table className={s.table}>
              <tbody>
                <TableRow>
                  <th className={s.firstRowDataYear}>{t("year")}</th>
                  <th>{t("quarter")}</th>
                  <th>{t("report")}</th>
                  <th>{t("presentation")}</th>
                  <th>{t("webcast")}</th>
                </TableRow>
                {sections.map((section, sectionIndex) => {
                  return (
                    <React.Fragment key={`section-${sectionIndex}`}>
                      {section.features &&
                        section.features.map((item, i) => {
                          const isEvenRow = i % 2 === 0;
                          const rowClassName = isEvenRow
                            ? `${s.tableRow} ${s.evenRow}`
                            : `${s.tableRow} ${s.oddRow}`;
                          const isLastRow = i === section.features.length - 1;
                          const lastRowClassName = isLastRow
                            ? `${rowClassName} ${s.lastRow}`
                            : rowClassName;

                          if (sectionIndex < yearsToShow) {
                            return (
                              <TableRow
                                className={lastRowClassName}
                                key={`row-${sectionIndex}-${i}`}
                              >
                                {i == 0 && (
                                  <td
                                    rowSpan={section.features.length}
                                    className={s.yearColumn}
                                  >
                                    {section.year}
                                  </td>
                                )}
                                <td>{item.quarter}</td>
                                <TableCellWithLink
                                  link={item.report}
                                  iconSrc="/svg/icons/report.svg"
                                />

                                <TableCellWithLink
                                  link={item.presentation}
                                  iconSrc="/svg/icons/presentation2.svg"
                                />

                                <TableCellWithLink
                                  link={item.webcast}
                                  iconSrc="/svg/icons/webcast.svg"
                                />
                              </TableRow>
                            );
                          }
                          return null;
                        })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={s.centerButton}>
          <Button href="" onClick={handleLearnMore} disabled={noMoreRows}>
            {t("load-more")}
          </Button>
        </div>
      </div>
    </section>
  );
};

FinancialReportsTable.Table = Table;
FinancialReportsTable.TableRow = TableRow;

export default FinancialReportsTable;
