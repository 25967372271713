import { useEffect, useState } from "react";
import { DownloadsExpandableItemListBlock } from "../../lib/storyblok/types/blocks/DownloadsExpandableItemListBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Downloads from "../../ui/Downloads/Downloads";
import { DynamicBlock } from "../DynamicBlock";
import ImageVaultImage from "../ImageVaultImage";
import { isValidImageData } from "../../lib/utils/imageUtils";

const { List, ExpandableItem } = Downloads;

const DownloadsExpandableItemList: StoryblokBlockComponent<
  DownloadsExpandableItemListBlock
> = ({ items }) => {
  const [expandedIndexes, setExpandedIndexes] = useState(
    items.map(() => false)
  );

  useEffect(() => {
    setExpandedIndexes(items.map(() => false));
  }, [items]);

  const toggleExpanded = (i: number) => {
    const indexes = [...expandedIndexes];
    indexes[i] = !indexes[i];
    setExpandedIndexes(indexes);
  };

  return (
    <List expandable>
      {items.map((item, i) => (
        <ExpandableItem
          key={item._uid}
          image={
            isValidImageData(item.image) ? (
              <ImageVaultImage sizes="10vw" image={item.image} layout="fill" />
            ) : null
          }
          title={item.title}
          text={item.text}
          listTitle={item.listTitle}
          isExpanded={expandedIndexes[i]}
          toggleExpanded={() => toggleExpanded(i)}
        >
          {item.items?.map((linkItem, overrides) => (
            <DynamicBlock
              content={linkItem}
              key={linkItem._uid}
              overrides={overrides}
            />
          ))}
        </ExpandableItem>
      ))}
    </List>
  );
};

export default DownloadsExpandableItemList;