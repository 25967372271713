import Icon, { IconType } from "../../Icon/Icon";
import cn from "classnames";
import s from "../Share.module.scss";
import FlexibleLinkWrapper from "../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  icon: IconType;
  href: string;
  title?: string;
  onClick?: () => void;
};

const Item = ({ icon, href, title, onClick }: Props) => (
  <div className={cn(s.item)}>
    {onClick ? (
      <button onClick={onClick} title={title}>
        {icon && <Icon icon={icon} width={14} />}
      </button>
    ) : (
      <FlexibleLinkWrapper href={href} title={title}>
        {icon && <Icon icon={icon} width={14} color={s.iconColor} />}
      </FlexibleLinkWrapper>
    )}
  </div>
);

export default Item;
