export const staticTags = [
  "products",
  "market-segment",
  "gaming",
  "personal-computing-pc",
  "people",
  "board",
  "investor-relations",
  "board-members",
  "hardware",
  "management",
  "executive-management-team",
  "screen-based",
  "scientific-research",
  "wearables",
  "brand-assets",
  "tobii-logos",
  "software",
];
