import React from "react";
import cn from "classnames";
import s from "./MiscTable.module.scss";
import c from "../../styles/container.module.scss";
import Table from "./components/Table";
import TableRow from "./components/TableRow";
import { MiscSectionBlock } from "../../lib/storyblok/types/blocks/MiscTableBlock";
import Details from "../../ui/Details";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useTranslation } from "next-i18next";
import { InnerSpacingVariant } from "../../lib/storyblok/types/shared";

type Props = {
  title: string;
  spacing: InnerSpacingVariant;
  sections: MiscSectionBlock[];
};

const subComponents = {
  Table,
  TableRow,
};
type SubComponents = typeof subComponents;

const MiscTable: CWS<Props, SubComponents> = ({ title, spacing, sections }) => {
  const resolveLink = useLinks();
  const { t } = useTranslation(["resourcepage"]);

  return (
    <section className={cn(s.background, c.fluid)}>
      <div className={cn(s.techSpec, c.xWide, s[spacing])}>
        <h3 className={cn("display", c.medium)}>{title}</h3>
        <div className={s.tableWrapper}>
          <div className={s.wrapper}>
            <table className={s.table}>
              <tbody>
                <TableRow>
                  <th className={s.smallColumn}>{t("year")}</th>
                  <th>{t("content")}</th>
                </TableRow>
                {sections.map((section, sectionIndex) => {
                  return (
                    <React.Fragment key={`section-${sectionIndex}`}>
                      {section.features &&
                        section.features.map((item, i) => {
                          const isEvenRow = i % 2 === 0;
                          const rowClassName = isEvenRow
                            ? `${s.tableRow} ${s.evenRow}`
                            : `${s.tableRow} ${s.oddRow}`;
                          const isLastRow = i === section.features.length - 1;
                          const lastRowClassName = isLastRow
                            ? `${rowClassName} ${s.lastRow}`
                            : rowClassName;

                          return (
                            <TableRow
                              className={lastRowClassName}
                              key={`row-${sectionIndex}-${i}`}
                            >
                              {i == 0 && (
                                <td
                                  rowSpan={section.features.length}
                                  className={cn(s.yearColumn, s.smallColumn)}
                                >
                                  {section.year}
                                </td>
                              )}

                              <td>
                                {item?.document?.length > 0 && (
                                  <Details.Document
                                    icon={item.document[0].icon}
                                    title={item.document[0].title}
                                    subtitle={item.document[0].subtitle}
                                    href={resolveLink(item.document[0].link)}
                                    customClassName={s.customDetail}
                                  />
                                )}
                              </td>
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

MiscTable.Table = Table;
MiscTable.TableRow = TableRow;

export default MiscTable;
