import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import s from "./Slider.module.scss";
import SlideItemImage from "./components/SlideItemImage";
import SlideItemVideo from "./components/SlideItemVideo";
import ImageVaultImage from "../../components/ImageVaultImage";
import getPaginationConfig from "./components/PaginationConfig";
import { resetProgressBar, startProgressBar } from "./components/ProgressUtils";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import {
  A11y,
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Parallax,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";
import { isValidImageData } from "../../lib/utils/imageUtils";

const Slider = ({
  slides,
  paginationOptions = "pagination",
  arrowNavigationVisibility,
  contentPosition = "left",
}) => {
  const swiperRef = useRef<SwiperRef>(null);
  const [activeSlideId, setActiveSlideId] = useState(null);
  const [intervalId, setIntervalId] = useState({});

  useEffect(() => {
    if (slides && slides.length > 0) {
      setActiveSlideId(slides[0]._uid);
    }

    // Start progress bar for the first slide when component mounts
    if (swiperRef.current) {
      startProgressBar(0, setIntervalId);
    }
  }, [slides]);

  const handleSlideChange = (swiper) => {
    const activeSlide = slides[swiper.realIndex];
    setActiveSlideId(activeSlide._uid);

    slides.forEach((_, index) => {
      const progressBar = document.getElementById(`progress-${index}`);
      if (progressBar) {
        progressBar.style.width = "0%";
      }
    });

    startProgressBar(swiper.realIndex, setIntervalId);

    for (let step = 0; step < slides.length; step++) {
      if (step === swiper.realIndex) continue;
      resetProgressBar(step, intervalId);
    }
  };

  const pagination = getPaginationConfig(paginationOptions, slides);
  return (
    <section className={cn(c.fluid, s.sliderWrapper)}>
      <Swiper
        modules={[A11y, Autoplay, EffectFade, Navigation, Pagination, Parallax]}
        pagination={pagination}
        effect={"fade"}
        navigation={arrowNavigationVisibility ? true : false}
        onSlideChange={handleSlideChange}
        className={s.mySwiper}
        ref={swiperRef}
        parallax={true}
        speed={300}
        autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
      >
        <div className={cn(s.slides)}>
          {slides &&
            slides.map((slide, index) => (
              <SwiperSlide key={index} className={s.swiperSlide}>
                {slide.component === "slideItemImage" && (
                  <SlideItemImage
                    description={slide.description}
                    inverted={slide.inverted}
                    overlay={slide.overlay}
                    titleType={slide.titleType}
                    title={slide.title}
                    label={slide.label}
                    paginationTitle={slide.paginationTitle}
                    buttons={slide.buttons}
                    image={
                      slide.image && isValidImageData(slide.image) ? (
                        <ImageVaultImage
                          image={slide.image}
                          sizes="100vw"
                          layout="fill"
                          objectFit="cover"
                        />
                      ) : null
                    }
                    contentPosition={contentPosition}
                  />
                )}
                {slide.component === "slideItemVideo" && (
                  <SlideItemVideo
                    label={slide.label}
                    titleType={slide.titleType}
                    title={slide.title}
                    description={slide.description}
                    buttons={slide.buttons}
                    video={slide.video}
                    videoThumbnail={slide.videoThumbnail}
                    overlay={slide.overlay}
                    paginationTitle={slide.paginationTitle}
                    activeSlideId={activeSlideId}
                    id={slide._uid}
                    inverted={slide.inverted}
                    contentPosition={contentPosition}
                  />
                )}
              </SwiperSlide>
            ))}
        </div>
      </Swiper>
    </section>
  );
};
export default Slider;
