import React, { Children, ReactNode } from "react";
import cn from "classnames";
import s from "./ArticleHeading.module.scss";
import c from "../../styles/container.module.scss";

import DetailsList from "./components/DetailsList";
import Detail from "./components/Detail";
import { useTranslation } from "next-i18next";

type Props = {
  title: string;
  author?: string;
  createdAt?: string;
  readTime?: string;
  children?: ReactNode;
  byLabel: string;
  minutesLabel: string;
  parent?: string;
};

const ArticleHeading = ({
  title,
  author,
  createdAt,
  readTime,
  children,
  byLabel,
  minutesLabel,
  parent,
}: Props) => {
  const { t } = useTranslation(["articlepage"]);
  const dateTime = createdAt?.substring(0, 10);

  return (
    <div className={cn(s.heading, c.wide)}>
      <h1 className={cn(s.title, "display")}>{title}</h1>
      <div className={s.details}>
        <DetailsList>
          {parent && (
            <Detail>
              <a href={parent} title={t("blog")} className={s.parentPage}>
                {t("blog")}
              </a>
            </Detail>
          )}
          {author && (
            <Detail>
              {byLabel} <span className={s.author}>{author}</span>
            </Detail>
          )}
          {createdAt && (
            <Detail>
              <time dateTime={dateTime}>{createdAt}</time>
            </Detail>
          )}
          {readTime && <Detail>{`${readTime} ${minutesLabel}`}</Detail>}
        </DetailsList>
      </div>
      {Children.count(children) > 0 && (
        <div className={s.share}>{children}</div>
      )}
    </div>
  );
};

export default ArticleHeading;
