import React, { ElementType, HTMLProps } from "react";
import s from "../SearchHits.module.scss";
import cn from "classnames";

type HitContentWrapperProps = HTMLProps<HTMLDivElement> & {
  as?: ElementType;
};

const ContentWrapper = ({ className, ...props }: HitContentWrapperProps) => (
  <div {...props} className={cn(className, s.contentWrapper)} />
);

export default ContentWrapper;
