import cn from "classnames";
import s from "./../LanguageSelect.module.scss";
import Icon from "../../Icon";
import { forwardRef } from "react";
import Flag from "../../Flag";
import { FlagKey } from "../../Flag/Flag";

type Props = {
  language: string;
  country: FlagKey;
  href: string;
  selected?: boolean;
};

const LanguageList = forwardRef<HTMLAnchorElement, Props>(
  ({ language, selected, country, href }, ref) => (
    <li className={cn(s.listItem, s[language], { [s.selected]: selected })}>
      <a ref={ref} href={href}>
        <div className={cn(s.leftSide)}>
          <Flag country={country} width={23} />
          <span>{language}</span>
        </div>
      </a>
      {selected && <Icon icon="check" color={s.iconcolor} />}
    </li>
  )
);

LanguageList.displayName = "LanguageList";

export default LanguageList;
