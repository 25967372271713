import React, { forwardRef } from "react";
import cn from "classnames";
import s from "../FilterPage.module.scss";

interface RefinementsWrapperProps {
  children: React.ReactNode;
  isVisible?: boolean;
  id?: string;
}

const RefinementsWrapper = forwardRef<HTMLDivElement, RefinementsWrapperProps>(
  ({ children, isVisible = true, id }, ref) => {
    return (
      <div
        ref={ref}
        id={id}
        className={cn(s.refinementsWrapper, {
          [s.hidden]: !isVisible,
        })}
        role="region"
        aria-labelledby="refinement-toggle"
      >
        {children}
      </div>
    );
  }
);

RefinementsWrapper.displayName = "RefinementsWrapper";

export default RefinementsWrapper;
