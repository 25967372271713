/* eslint-disable */
import React, { useRef } from "react";
import cn from "classnames";
import s from "./EmbedBlock.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import BlockBackground from "../Common/BlockBackground";
import BlockIntro from "../Common/BlockIntro";
import {
  StyleVariant,
  InnerSpacingVariant,
  WidthVariant,
} from "../../lib/storyblok/types/shared";

type Props = {
  title: string;
  text?: string;
  asset: {
    filename: string;
  };
  spacing: InnerSpacingVariant;
  width: WidthVariant;
  style: StyleVariant;
  height:
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
    | "1000"
    | "1100"
    | "1200";
  inverted?: boolean;
};

const EmbedBlock = ({
  style = "signature",
  title,
  text,
  asset,
  spacing = "both",
  width,
  height,
  inverted,
}: Props) => {
  const embedId = "embedIframe";

  const ref = useRef<HTMLIFrameElement>(null);

  let originUrl = "";
  if (asset?.filename) {
    try {
      originUrl = new URL(asset.filename).origin;
    } catch (error) {
      console.error("Invalid embedUrl:" + (asset?.filename || ""));
    }
  }

  return (
    <section className={cn(s.embed, bs[spacing])}>
      <BlockBackground style={style} opacity={100} />

      <div className={s.inner}>
        <BlockIntro
          inverted={inverted}
          title={title}
          description={text}
          buttons={""}
          width={width === "narrow" ? "contained" : "full"}
          alignment={"alignCenter"}
        />

        <div className={cn(s.content, c[width])}>
          <iframe
            title={title}
            ref={ref}
            id={embedId}
            width="100%"
            height={height}
            src={asset?.filename || ""}
          />
        </div>
      </div>
    </section>
  );
};

export default EmbedBlock;
