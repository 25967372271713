import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import LocalAlertUi from "../../ui/LocalAlert";
import { LocalAlertBlock } from "../../lib/storyblok/types/blocks/LocalAlertBlock";
import Image from "next/image";
import Button from "../../ui/Button";
import { useLinks } from "../../lib/storyblok/useLinks";

const LocalAlert: StoryblokBlockComponent<LocalAlertBlock> = ({
  style,
  icon,
  title,
  text,
  button,
  padding,
}) => {
  const resolveLink = useLinks();
  return (
    <LocalAlertUi
      style={style}
      icon={
        icon && (
          <Image
            src={`/svg/icons/${icon}.svg`}
            alt={`icon-${icon}`}
            width={32}
            height={32}
          />
        )
      }
      title={title}
      text={text}
      button={
        button[0] && (
          <Button
            href={button[0]?.buttonLink && resolveLink(button[0]?.buttonLink)}
            variant={button[0].buttonType}
          >
            {button[0].buttonText}
          </Button>
        )
      }
      padding={padding}
    />
  );
};

export default LocalAlert;
