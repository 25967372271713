/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  useCurrentRefinements,
  useInfiniteHits,
  useClearRefinements,
  useSearchBox,
} from "react-instantsearch";
import { DynamicBlocks } from "../DynamicBlock";
import FilterPageGrid from "../../ui/PageTemplates/FilterPage";
import SearchBox from "./SearchBox";
import { StorySortMethod } from "./StorySort";
import Button from "../../ui/Button";
import { useTranslation } from "next-i18next";
import InfiniteStoryHits from "./InfiniteStoryHits";
import { Block } from "../../lib/storyblok/types/story";
import StoryRefinementList from "./StoryRefinementList";
import { AnchorMenuContextProvider } from "../Layout/AnchorMenu/AnchorMenuContext";
import AnchorMenuSection from "../Layout/AnchorMenu/AnchorMenuSection";
import { splitIntoSections } from "../Layout/AnchorMenu/splitIntoSections";
import useMediaQuery from "../../hooks/useMatchMedia";

const StorySearcher = ({
  story,
  value,
  preamble,
  activateSearchField,
  refinements,
  renderImages = true,
  renderDate = true,
  hiddenFacets = [],
}: {
  story: any;
  value?: string;
  preamble?: Block[];
  activateSearchField?: boolean;
  refinements?: unknown[];
  renderImages?: boolean;
  renderDate?: boolean;
  hiddenFacets?: string[];
}) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useTranslation("searchpage");
  const { results } = useInfiniteHits();
  const { items } = useCurrentRefinements();

  const [showFilter, setShowFilter] = useState(!isMobile);
  const [sortMethod] = useState<StorySortMethod>();

  useEffect(() => {
    setShowFilter(!isMobile);
  }, [isMobile]);

  const total = results?.nbHits ?? 0;
  const toggleFilter = () => {
    setShowFilter((currentShowFilter) => !currentShowFilter);
  };

  const noActiveFilters = items.length < 1;
  const { body } = story.content;

  const sections = splitIntoSections(body);

  const { refine: clearRefinements } = useClearRefinements();
  const { items: currentRefinements } = useCurrentRefinements();
  const { refine: searchRefine } = useSearchBox();

  const clearAll = () => {
    clearRefinements();
    searchRefine("");
  };

  return (
    <>
      <FilterPageGrid>
        {preamble && (
          <FilterPageGrid.Preamble>
            <DynamicBlocks blocks={preamble} />
          </FilterPageGrid.Preamble>
        )}

        <FilterPageGrid.Toolbar>
          {activateSearchField && (
            <FilterPageGrid.Input>
              <SearchBox defaultValue={value} type="small" />
            </FilterPageGrid.Input>
          )}
          <FilterPageGrid.RefinementsWrapper
            id="refinement-lists"
            isVisible={showFilter}
            aria-labelledby="refinement-toggle"
          >
            <StoryRefinementList
              refinementAttributes={refinements}
              hiddenFacets={hiddenFacets}
            />
          </FilterPageGrid.RefinementsWrapper>
          <FilterPageGrid.Toggle>
            <Button
              variant="default"
              size="small"
              icon={noActiveFilters ? "filter" : undefined}
              onClick={toggleFilter}
              aria-expanded={showFilter}
              aria-controls="refinement-lists"
              id="refinement-toggle"
            >
              {showFilter ? t("close-filters") : t("show-filters")}
            </Button>
          </FilterPageGrid.Toggle>
        </FilterPageGrid.Toolbar>

        <FilterPageGrid.Status>
          <FilterPageGrid.Heading
            clearLabel={currentRefinements.length > 0 ? t("clear-all") : ""}
            onClearClick={clearAll}
          />
          <FilterPageGrid.NumberOfHits>
            {t("number-of-hits", { count: total })}
          </FilterPageGrid.NumberOfHits>
        </FilterPageGrid.Status>

        <FilterPageGrid.Body>
          <InfiniteStoryHits
            sortMethod={sortMethod}
            renderImages={renderImages}
            renderDate={renderDate}
          />
        </FilterPageGrid.Body>
      </FilterPageGrid>
      <AnchorMenuContextProvider>
        {sections?.map((section) => (
          <AnchorMenuSection
            key={`${section.href ? section.href : "index"}-${story.uuid}`}
            section={section}
          />
        ))}
      </AnchorMenuContextProvider>
    </>
  );
};

export default StorySearcher;
