import React, { useState, useEffect } from "react";
import {
  useCurrentRefinements,
  useInfiniteHits,
  useClearRefinements,
  useSearchBox,
} from "react-instantsearch";
import { DynamicBlocks } from "../DynamicBlock";
import FilterPageGrid from "../../ui/PageTemplates/FilterPage";
import SearchBox from "../Nestable/SearchBox";
import { StorySortMethod } from "./StorySort";
import Button from "../../ui/Button";
import { useTranslation } from "next-i18next";
import InfiniteStoryHits from "./InfiniteStoryHits";
import { Block } from "../../lib/storyblok/types/story";
import StoryRefinementList from "../Nestable/StoryRefinementList";
import useMediaQuery from "../../hooks/useMatchMedia";

const StoryFinder = ({
  value,
  preamble,
  activateSearchField,
  refinements,
  pageType,
  renderImages = true,
  renderDate = true,
  hiddenFacets = [],
}: {
  value?: string;
  preamble?: Block[];
  activateSearchField?: boolean;
  refinements?: unknown[];
  pageType?: string;
  renderImages?: boolean;
  renderDate?: boolean;
  hiddenFacets?: string[];
}) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useTranslation("searchpage");
  const { results } = useInfiniteHits();
  const { items } = useCurrentRefinements();

  const [showFilter, setShowFilter] = useState(!isMobile);
  const [sortMethod] = useState<StorySortMethod>();

  const total = results?.nbHits ?? 0;
  const toggleFilter = () => {
    setShowFilter((currentShowFilter) => !currentShowFilter);
  };

  useEffect(() => {
    setShowFilter(!isMobile);
  }, [isMobile]);

  const noActiveFilters = items.length < 1;

  const { refine: clearRefinements } = useClearRefinements();
  const { items: currentRefinements } = useCurrentRefinements();
  const { refine: searchRefine } = useSearchBox();

  const clearAll = () => {
    clearRefinements();
    searchRefine("");
  };

  return (
    <FilterPageGrid>
      {preamble && (
        <FilterPageGrid.Preamble>
          <DynamicBlocks blocks={preamble} />
        </FilterPageGrid.Preamble>
      )}

      <FilterPageGrid.Toolbar>
        {activateSearchField && (
          <FilterPageGrid.Input>
            <SearchBox defaultValue={value} type="small" />
          </FilterPageGrid.Input>
        )}
        <FilterPageGrid.RefinementsWrapper
          id="refinement-lists"
          isVisible={showFilter}
          aria-labelledby="refinement-toggle"
        >
          <StoryRefinementList
            refinementAttributes={refinements}
            hiddenFacets={hiddenFacets}
          />
        </FilterPageGrid.RefinementsWrapper>
        <FilterPageGrid.Toggle>
          <Button
            variant="default"
            size="small"
            icon={noActiveFilters ? "filter" : undefined}
            onClick={toggleFilter}
            aria-expanded={showFilter}
            aria-controls="refinement-lists"
            id="refinement-toggle"
          >
            {showFilter ? t("close-filters") : t("show-filters")}
          </Button>
        </FilterPageGrid.Toggle>
      </FilterPageGrid.Toolbar>

      <FilterPageGrid.Status>
        <FilterPageGrid.Heading
          clearLabel={currentRefinements.length > 0 ? t("clear-all") : ""}
          onClearClick={clearAll}
        />
        <FilterPageGrid.NumberOfHits>
          {t("number-of-hits", { count: total })}
        </FilterPageGrid.NumberOfHits>
      </FilterPageGrid.Status>

      <FilterPageGrid.Body>
        <InfiniteStoryHits
          sortMethod={sortMethod}
          renderImages={renderImages}
          renderDate={renderDate}
          pageType={pageType}
        />
      </FilterPageGrid.Body>
    </FilterPageGrid>
  );
};

export default StoryFinder;
