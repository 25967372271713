import { ReactNode } from "react";
import cn from "classnames";
import s from "./LogoList.module.scss";
import bs from "../../styles/block.spacing.module.scss";

import List from "./components/List";
import Logo from "./components/Logo";
import { InnerSpacingVariant } from "../../lib/storyblok/types/shared";
import BlockIntro from "../Common/BlockIntro";

type Props = {
  title: string;
  spacing: InnerSpacingVariant;
  children: ReactNode;
};

const subComponents = { List, Logo };
type Subcomponents = typeof subComponents;

const LogoList: CWS<Props, Subcomponents> = ({
  title,
  spacing = "both",
  children,
}) => {
  return (
    <section className={cn(s.logoList, bs[spacing])}>
      <BlockIntro alignment="alignCenter" title={title} width={"contained"} />

      {children}
    </section>
  );
};

LogoList.List = List;
LogoList.Logo = Logo;

export default LogoList;
