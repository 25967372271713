import { NextPage } from "next";
import useLanguageSelect from "../hooks/useLanguageSelect";
import { StoryblokPageProps } from "../lib/routing/getStaticProps";
import { ViewModelProvider } from "../lib/storyblok/ViewmodelContext";
import { useStoryblokBridge } from "../lib/storyblok/useStoryblokBridge";
import { DynamicStory } from "./DynamicStory";
import BreadCrumbsUi from "./Layout/BreadcrumbsUi";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header/Header";
import LanguageSelect from "./Nestable/LanguageSelect";
import MetaTags from "./MetaTags";
import GlobalAlert from "./Nestable/GlobalAlert";
import { HeaderContextProvider } from "./Layout/Header/HeaderContext";
import BackgroundEffect from "../ui/BackgroundEffect/BackgroundEffect";

const StoryblokPage: NextPage<StoryblokPageProps> = ({ viewModel }) => {
  const bridgedVm = useStoryblokBridge(viewModel);

  const { show, handleLanguageSelect, languages } = useLanguageSelect(
    viewModel && viewModel.locale,
    viewModel && viewModel.site,
    viewModel && viewModel.story
  );

  return (
    <>
      <HeaderContextProvider>
        <ViewModelProvider viewModel={viewModel}>
          <main>
            {viewModel?.header && (
              <Header
                {...viewModel.header}
                onLangClick={handleLanguageSelect(true)}
                languages={languages}
              />
            )}
            <DynamicStory viewModel={bridgedVm} />
          </main>
          {viewModel?.footer && (
            <>
              {!bridgedVm.story?.content.hideBreadcrumbs && (
                <BreadCrumbsUi {...viewModel.breadcrumbs} />
              )}
              <Footer
                {...viewModel.footer}
                onLangClick={handleLanguageSelect(true)}
              />
            </>
          )}
          <LanguageSelect
            show={show}
            languages={languages}
            handleLanguageSelect={handleLanguageSelect}
          />
          {viewModel?.globalAlert && <GlobalAlert {...viewModel.globalAlert} />}
          <MetaTags viewModel={viewModel} />
        </ViewModelProvider>
      </HeaderContextProvider>

      {viewModel?.site === "main" && <BackgroundEffect />}
    </>
  );
};

export default StoryblokPage;
