import React from "react";
import VideoUi from "../../ui/Video";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ImageVaultImage from "../ImageVaultImage";
import { TencentVideoBlock } from "../../lib/storyblok/types/blocks/TencentVideoBlock";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";

const TencentVideo: StoryblokBlockComponent<TencentVideoBlock> = ({
  url,
  thumbnail,
  thumbnailOverlay,
  title,
  description,
  duration,
  component,
}) => {
  const getUrl = () => {
    const idFinder = /page\/(.*)\./;
    const match = url.match(idFinder);
    const id = match && match[1];

    return `https://v.qq.com/txp/iframe/player.html?vid=${id}`;
  };

  return (
    <VideoUi
      url={getUrl()}
      thumbnailImage={
        thumbnail && isValidImageData(thumbnail) ? (
          <ImageVaultImage
            sizes={imageSizes("100vw", { xl: "1200px" })}
            image={thumbnail}
            layout="fill"
          />
        ) : null
      }
      thumbnailOverlay={thumbnailOverlay}
      title={title}
      description={description}
      duration={duration}
      provider={component}
    />
  );
};

export default TencentVideo;
