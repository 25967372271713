import { useTranslation } from "next-i18next";
import Tags from "../Tags";
import Tag from "../../components/Tag";
import s from "./ImageWrapper.module.scss";
import SingleImage from "./components/SingleImage";
import { ReactNode } from "react";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import { useLinks } from "../../lib/storyblok/useLinks";

type Props = {
  title: string;
  taggedMediaBank: string[];
  children?: ReactNode;
  setActiveFilters: React.Dispatch<React.SetStateAction<string[]>>;
};

const subComponents = { SingleImage };
type Subcomponents = typeof subComponents;

const ImageWrapper: CWS<Props, Subcomponents> = ({
  title,
  children,
  taggedMediaBank = [],
  setActiveFilters,
}) => {
  const { t } = useTranslation(["resourcepage", "tags", "common"]);
  const { globalLinks } = useViewmodel();
  const resolveLink = useLinks();

  const handleTagClick = (tag: string) => {
    setActiveFilters((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((filter) => filter !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  return (
    <div className={s.ImageWrapper}>
      {children && <div className={s.body}>{children}</div>}
      {taggedMediaBank && taggedMediaBank.length > 0 && (
        <div className={s.tags}>
          <Tags.TagList title={t("")}>
            {taggedMediaBank?.map((tag) => {
              const baseUrl = globalLinks.mainResourceFilterPage
                ? resolveLink(globalLinks.mainResourceFilterPage)
                : "";
              const url = `${baseUrl}?categories=${tag}`;

              return (
                <div
                  key={tag}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTagClick(tag);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault();
                      handleTagClick(tag);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  aria-label={t(tag, { ns: "tags" })}
                >
                  <Tag tag={t(tag, { ns: "tags" })} url={url} style={"gray"} />
                </div>
              );
            })}
          </Tags.TagList>
        </div>
      )}
      <h3 className={s.title}>{title}</h3>
    </div>
  );
};

ImageWrapper.SingleImage = SingleImage;

export default ImageWrapper;