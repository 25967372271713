import React from "react";
import cn from "classnames";
import Hit from "./components/Hit";
import Heading from "./components/HitHeading";
import Image from "./components/HitImage";
import Body from "./components/HitBody";
import Tags from "./components/Tags";
import ContentWrapper from "./components/HitContentWrapper";
import Tag from "../Tags/components/Tag";
import Pagination from "./components/Pagination";
import s from "./SearchHits.module.scss";

interface SearchHitsProps {
  total: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const subComponents = {
  Hit,
  Tags,
  Heading,
  Body,
  Image,
  Tag,
  ContentWrapper,
  Pagination,
};

const SearchHits: CWS<SearchHitsProps, typeof subComponents> = ({
  children,
  className,
  total,
}) => {
  return (
    <section className={s.searchHits}>
      <span className={s.total}>{total}</span>
      <ol className={cn(className, s.hits)}>{children}</ol>
    </section>
  );
};

SearchHits.Hit = Hit;
SearchHits.Tags = Tags;
SearchHits.Tag = Tag;
SearchHits.Heading = Heading;
SearchHits.Body = Body;
SearchHits.Image = Image;
SearchHits.ContentWrapper = ContentWrapper;
SearchHits.Pagination = Pagination;

export default SearchHits;
