import s from "../ShortFeatures.module.scss";
import { ReactNode } from "react";
import Markdown from "../../Markdown";
import cn from "classnames";
import BlockBackground from "../../Common/BlockBackground";
import IconWithColor from "../../Common/IconWithColor";

type Props = {
  text: string;
  description: string;
  showLabel?: boolean;
  label: string;
  labelPrefix: string;
  labelSuffix: string;
  button?: ReactNode;
  inverted?: boolean;
  style: string;
  boxed?: boolean;
  icon?: string;
  iconColor?: string;
};

const Feature = ({
  text,
  description,
  button,
  showLabel,
  label,
  labelPrefix,
  labelSuffix,
  inverted,
  style,
  boxed,
  icon,
  iconColor,
}: Props) => (
  <li
    className={cn(s.feature, {
      [s.inverted]: inverted,
      [s.box]: boxed,
    })}
  >
    <BlockBackground style={style} opacity={100} />

    <div className={s.inner}>
      <div className={s.visual}>
        {showLabel && (
          <div className={s.labelWrapper}>
            {labelPrefix && <span className={s.prefix}>{labelPrefix}</span>}
            {label && <span className={s.label}>{label}</span>}
            {labelSuffix && <span className={s.suffix}>{labelSuffix}</span>}
          </div>
        )}

        {!showLabel && (
          <>
            <div className={s.icon}>
              <IconWithColor
                icon={icon}
                width={48}
                height={48}
                alt={text}
                color={iconColor ? iconColor : inverted ? "white" : "black"}
              />
            </div>
          </>
        )}
      </div>

      <div className={s.content}>
        {text && description ? (
          <p className={s.title}>
            <strong>{text}</strong>
          </p>
        ) : (
          <p className={s.title}>{text}</p>
        )}
        {description !== null && (
          <div className={s.description}>
            <Markdown>{description}</Markdown>
          </div>
        )}
        {button && <div className={s.featureButtons}>{button}</div>}
      </div>
    </div>
  </li>
);

export default Feature;
