import React, { ReactNode, Children } from "react";
import cn from "classnames";
import s from "./Breadcrumbs.module.scss";
import BreadcrumbLink from "./components/BreadcrumbLink";

type Props = {
  children: ReactNode[];
  site: string;
};

const Breadcrumbs = ({ children, site }: Props) => (
  <nav className={cn(s.breadcrumbs, s[site])}>
    <ul className={s.inner}>
      {Children.map(children, (child) => {
        return <BreadcrumbLink>{child}</BreadcrumbLink>;
      })}
    </ul>
  </nav>
);

export default Breadcrumbs;
