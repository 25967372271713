import React, { FormEvent, useState, useEffect } from "react";
import SearchInput from "../../ui/SearchInput";
import { useSearchBox } from "react-instantsearch";
import { useTranslation } from "next-i18next";

type SearchBoxProps = {
  defaultValue?: string;
  type?: "large" | "small" | "mini";
  customOnChange?: (value: string) => void;
  customOnClear?: () => void;
  useInstantSearch?: boolean;
};

// Separate component for InstantSearch functionality
const InstantSearchBox = ({
  defaultValue,
  type,
  customOnChange,
  customOnClear,
}: Omit<SearchBoxProps, "useInstantSearch">) => {
  const { t } = useTranslation("searchpage");
  const { refine, query } = useSearchBox();
  const [inputValue, setInputValue] = useState(defaultValue);

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setInputValue(newValue);
    refine(newValue);
    if (customOnChange) {
      customOnChange(newValue);
    }
  };

  const onClear = () => {
    setInputValue("");
    refine("");
    if (customOnClear) {
      customOnClear();
    }
  };

  useEffect(() => {
    if (query !== undefined) {
      setInputValue(query);
    }
  }, [query]);

  return (
    <SearchInput
      label={t("input-label")}
      placeholder={t("input-label")}
      clearLabel={t("input-clear")}
      onChange={onChange}
      value={inputValue}
      onClearClick={onClear}
      type={type}
    />
  );
};

// Custom search component without InstantSearch
const CustomSearchBox = ({
  defaultValue,
  type,
  customOnChange,
  customOnClear,
}: Omit<SearchBoxProps, "useInstantSearch">) => {
  const { t } = useTranslation("searchpage");
  const [inputValue, setInputValue] = useState(defaultValue);

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setInputValue(newValue);
    if (customOnChange) {
      customOnChange(newValue);
    }
  };

  const onClear = () => {
    setInputValue("");
    if (customOnClear) {
      customOnClear();
    }
  };

  return (
    <SearchInput
      label={t("input-label")}
      placeholder={t("input-label")}
      clearLabel={t("input-clear")}
      onChange={onChange}
      value={inputValue}
      onClearClick={onClear}
      type={type}
    />
  );
};

// Main SearchBox component that decides which version to render
const SearchBox = ({
  defaultValue = "",
  type = "large",
  customOnChange,
  customOnClear,
  useInstantSearch = true,
}: SearchBoxProps) => {
  const commonProps = {
    defaultValue,
    type,
    customOnChange,
    customOnClear,
  };

  return useInstantSearch ? (
    <InstantSearchBox {...commonProps} />
  ) : (
    <CustomSearchBox {...commonProps} />
  );
};

export default SearchBox;
