import Head from "next/head";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { ArticlePageStory } from "../../lib/storyblok/types/stories/ArticlePageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { DynamicBlocks } from "../DynamicBlock";
import { useTranslation } from "next-i18next";
import { useRelations } from "../../lib/storyblok/useRelations";
import { useLinks } from "../../lib/storyblok/useLinks";
import Image, { ImageLoader } from "next/image";

import ArticleHeadingUI from "../../ui/ArticleHeading";
import AuthorsUi from "../../ui/Authors";
import ImageVaultImage, {
  getStoryblokCropURI,
  imageVaultLoader,
  storyblokLoader,
} from "../ImageVaultImage";
import { Author } from "../../lib/storyblok/types/stories/Author";
import TagsAndShareWrapper from "../../ui/TagsAndShareWrapper";
import Tag from "../Tag";
import WeChat from "../../public/images/wechat.jpg";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import ShareItems from "../../ui/Share/components/ShareItems";
import EditorialWithoutSpacing from "../Nestable/EditorialWithoutSpacing";
import CTAForm from "../Nestable/CtaForm";
import { deduceDomain } from "../../lib/utils/deduceDomain";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";
import { StoryblokAsset } from "../../lib/storyblok/types/fieldtypes/imagevaultFieldtype";
import { useCallback, useMemo } from "react";
import { isValidImageData } from "../../lib/utils/imageUtils";

type VM = ViewModel<ArticlePageStory>;

const ArticlePage: StoryblokStoryComponent<VM> = ({ story }) => {
  const resolveLink = useLinks();
  const { getRelation } = useRelations();
  const { t } = useTranslation(["articlepage", "common"]);

  const {
    pageTitle,
    author,
    authors,
    collaborators,
    createdAt,
    readTime,
    body,
    ctaForm,
    contentGrid,
    tagsProductCategories,
    tagsSolutions,
    otherTags,
  } = story.content;
  const { globalLinks, locale, site } = useViewmodel();

  const blogBaseUrl = globalLinks.mainBlogFilterPage
    ? resolveLink(globalLinks.mainBlogFilterPage)
    : "";

  const isStoryblokAsset =
    !!story.content?.seoImage &&
    "filename" in story.content.seoImage &&
    "fieldtype" in story.content.seoImage;
  const isImageVaultWithItem =
    !!story.content?.seoImage &&
    "plugin" in story.content.seoImage &&
    "item" in story.content.seoImage &&
    story.content.seoImage?.item !== undefined;
  const storyblokAsset = isStoryblokAsset
    ? (story.content.seoImage as StoryblokAsset)
    : undefined;

  const seoImageUrl = useMemo(() => {
    if (storyblokAsset) {
      const cropData = storyblokAsset.meta_data?.crop;
      const cropURI = getStoryblokCropURI(cropData);
      return storyblokAsset.filename + cropURI;
    } else if (
      isImageVaultWithItem &&
      story.content.seoImage.item?.MediaConversions?.[0]?.Url
    ) {
      return (
        "/" +
        story.content.seoImage.item.MediaConversions[0].Url.split("/")
          .slice(3)
          .join("/")
      );
    }
    return "";
  }, [story, isImageVaultWithItem, storyblokAsset]);

  const loader: ImageLoader = useCallback(
    ({ src, width, quality }) => {
      return storyblokAsset
        ? storyblokLoader({ src, width, quality })
        : imageVaultLoader({ src, width, quality });
    },
    [storyblokAsset]
  );

  const seoImageMetaContentUrl = useMemo(() => {
    if (storyblokAsset) {
      return loader({
        src: seoImageUrl,
        width: 1200,
        quality: 100,
      });
    }
    return (
      deduceDomain(locale, site) +
      loader({
        src: seoImageUrl,
        width: 1200,
        quality: 100,
      })
    );
  }, [loader, locale, seoImageUrl, site, storyblokAsset]);

  // Article schema
  // Source: https://developers.google.com/search/docs/data-types/article
  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: pageTitle || "",
    image: seoImageMetaContentUrl,
    datePublished: createdAt || "",
    dateModified: createdAt || "", // Since we're not updating articles, we can use the same date
    author: [
      {
        "@type": "Person",
        name: author || "",
      },
    ],
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(articleSchema) }}
        />
      </Head>
      <ArticleHeadingUI
        title={pageTitle}
        author={author}
        createdAt={FormatDateRange(createdAt)}
        readTime={readTime}
        byLabel={t("by")}
        minutesLabel={t("minutes")}
        parent={blogBaseUrl}
      >
        <ShareItems />
      </ArticleHeadingUI>
      <DynamicBlocks
        blocks={body}
        overrides={{ editorial: EditorialWithoutSpacing }}
      />
      <TagsAndShareWrapper>
        <TagsAndShareWrapper.TagsWrapper>
          {tagsProductCategories?.map((tag) => {
            const url = `${blogBaseUrl}?categories=${tag}`;
            return <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />;
          })}
          {tagsSolutions?.map((tag) => {
            const url = `${blogBaseUrl}?solutions=${tag}`;
            return <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />;
          })}
          {otherTags?.map((tag) => {
            const url = `${blogBaseUrl}?otherTags=${tag}`;
            return <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />;
          })}
        </TagsAndShareWrapper.TagsWrapper>

        <TagsAndShareWrapper.ShareWrapper
          weChat={
            locale === "zh" && (
              <Image src={WeChat} quality={100} sizes="100vw" alt="WeChat" />
            )
          }
        >
          <ShareItems />
        </TagsAndShareWrapper.ShareWrapper>
      </TagsAndShareWrapper>
      {authors?.length > 0 && (
        <AuthorsUi title={t("written-by")}>
          {authors.map((author) => {
            const story = getRelation<Author>(author);

            if (story) {
              const { uuid, content, name } = story;
              return (
                <AuthorsUi.Author
                  key={uuid}
                  image={
                    content.image && isValidImageData(content.image) ? (
                      <ImageVaultImage
                        sizes="33vw"
                        image={content.image}
                        layout="fill"
                      />
                    ) : null
                  }
                  name={name}
                  rolesAndCompany={content.rolesAndCompany}
                  description={content.description}
                  facebookLink={
                    content.facebookLink?.url &&
                    resolveLink(content.facebookLink)
                  }
                  linkedinLink={
                    content.linkedinLink?.url &&
                    resolveLink(content.linkedinLink)
                  }
                  twitterLink={
                    content.twitterLink?.url && resolveLink(content.twitterLink)
                  }
                  link={content.link?.url && resolveLink(content.link)}
                  showMoreLabel={t("show-more", { ns: "common" })}
                  showLessLabel={t("show-less", { ns: "common" })}
                />
              );
            }
          })}
        </AuthorsUi>
      )}
      {collaborators?.length > 0 && (
        <AuthorsUi title={t("collaboration")}>
          {collaborators.map((collaborator) => {
            const story = getRelation<Author>(collaborator);

            if (story) {
              const { uuid, content } = story;

              return (
                <AuthorsUi.Author
                  key={uuid}
                  image={
                    content.image && isValidImageData(content.image) ? (
                      <ImageVaultImage
                        sizes="33vw"
                        image={content.image}
                        layout="fill"
                      />
                    ) : null
                  }
                  name={content.name}
                  rolesAndCompany={content.rolesAndCompany}
                  description={content.description}
                  facebookLink={
                    content.facebookLink?.url &&
                    resolveLink(content.facebookLink)
                  }
                  linkedinLink={
                    content.linkedinLink?.url &&
                    resolveLink(content.linkedinLink)
                  }
                  twitterLink={
                    content.twitterLink?.url && resolveLink(content.twitterLink)
                  }
                  link={content.link?.url && resolveLink(content.link)}
                  showMoreLabel={t("show-more", { ns: "common" })}
                  showLessLabel={t("show-less", { ns: "common" })}
                />
              );
            }
          })}
        </AuthorsUi>
      )}
      <DynamicBlocks blocks={[...(ctaForm ? ctaForm : []), ...contentGrid]} />
      {locale == "en" && (
        /* Only show the subscribe form in English */
        <>
          <div id="subscribe"></div>
          <CTAForm
            title={t("blog-subscribe-title")}
            formUrl={t("blog-subscribe-formUrl")}
            text={t("blog-subscribe-description")}
            _uid={""}
            component={"ctaForm"}
            _editable={""}
            spacing={"both"}
            copy={""}
            image={{
              _uid: "",
              item: undefined,
              plugin: "image-vault",
            }}
            style={"white"}
            divider={"swooshBlueTop"}
          />
        </>
      )}
    </>
  );
};

export default ArticlePage;
