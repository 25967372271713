import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./ShortFeatures.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import { AlignmentVariant } from "../../lib/storyblok/types/shared";
import Feature from "./components/Feature";
import BlockBackground from "../Common/BlockBackground";
import BlockIntro from "../Common/BlockIntro";

type Props = {
  title: string;
  text: string;
  buttons?: ReactNode;
  children: ReactNode;
  style: string;
  spacing: string;
  outerSpacing: string;
  columns: string;
  contentAlignment: AlignmentVariant;
  backgroundImage?: ReactNode;
  backgroundOpacity: number;
  width: string;
  inverted?: boolean;
};

const subComponents = { Feature };
type SubComponents = typeof subComponents;

const ShortFeatures: CWS<Props, SubComponents> = ({
  title,
  text,
  buttons,
  children,
  style,
  spacing,
  outerSpacing,
  columns,
  width,
  contentAlignment,
  backgroundImage,
  backgroundOpacity,
  inverted,
}) => {
  const widthStyle =
    width === "fluid" || width === "featured" ? c.xWide : s.flexibleWidth;

  const childCount = React.Children.count(children);
  const columnValues = { two: 2, three: 3, four: 4, five: 5, six: 6 };
  const numberToName = { 2: "two", 3: "three", 4: "four", 5: "five", 6: "six" };
  const requestedColumns = columnValues[columns] || 4;
  const optimalColumns = Math.min(childCount, requestedColumns);
  const actualColumns = numberToName[optimalColumns] || columns;

  return (
    <>
      <section
        className={cn(
          s.shortFeatures,
          bs[spacing],
          bs[outerSpacing],
          c[width],
          {
            [s.roundedCorners]:
              width !== "fluid" && width !== "" && width !== undefined,
          }
        )}
      >
        <div className={cn(s.background)}>
          {backgroundImage && (
            <div className={cn(s.backgroundImage)}>
              <div className={s.imageContainer}>{backgroundImage}</div>
            </div>
          )}
        </div>

        <BlockBackground style={style} opacity={backgroundOpacity} />

        <div className={cn(s.shortFeaturesInner)}>
          <BlockIntro
            alignment={contentAlignment}
            inverted={inverted}
            title={title}
            description={text}
            buttons={buttons}
            width="contained"
          />

          <div className={widthStyle}>
            <ul className={cn(s.features, s[actualColumns])}>{children}</ul>
          </div>
        </div>
      </section>
    </>
  );
};

ShortFeatures.Feature = Feature;

export default ShortFeatures;
