import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Button from "../../ui/Button";
import CTABlockUi from "../../ui/CTABlock";
import { CtaBlockBlock } from "../../lib/storyblok/types/blocks/CtaBlockBlock";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useButtonStyle, useContentStyle } from "../../hooks/useStyle";

const CTABlock: StoryblokBlockComponent<CtaBlockBlock> = ({
  style,
  spacing,
  outerSpacing,
  width,
  text,
  title,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
}) => {
  const resolveLink = useLinks();
  const href = resolveLink(buttonLink);
  const secondHref = resolveLink(secondButtonLink);
  const { buttonVariant } = useButtonStyle(style);
  const isInverted = useContentStyle(style);

  const buttonCollection = (
    <>
      {buttonText && href && (
        <Link href={href}>
          <Button href={href} variant={buttonVariant}>
            {buttonText}
          </Button>
        </Link>
      )}
      {secondButtonText && secondHref && (
        <Link href={secondHref}>
          <Button href={secondHref} variant={buttonVariant}>
            {secondButtonText}
          </Button>
        </Link>
      )}
    </>
  );

  return (
    <CTABlockUi
      style={style}
      title={title}
      text={text}
      spacing={spacing}
      outerSpacing={outerSpacing}
      width={width}
      inverted={isInverted}
      buttons={buttonCollection}
    />
  );
};

export default CTABlock;
