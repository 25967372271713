import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { CarouselBlock } from "../../lib/storyblok/types/blocks/CarouselBlock";
import CarouselUi from "../../ui/Carousel/VideoCarousel";
import Link from "next/link";
import Button from "../../ui/Button";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useContentStyle } from "../../hooks/useStyle";

const VideoCarousel: StoryblokBlockComponent<CarouselBlock> = ({
  title,
  description,
  buttons,
  items,
  style,
  spacing,
  outerSpacing,
  effect,
  carouselWidth,
}) => {
  const isInverted = useContentStyle(style);
  const resolveLink = useLinks();

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType ?? "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  return (
    <CarouselUi
      title={title}
      description={description}
      buttons={buttonCollection}
      items={items}
      carouselWidth={carouselWidth}
      style={style}
      spacing={spacing}
      outerSpacing={outerSpacing}
      effect={effect}
      inverted={isInverted}
    />
  );
};

export default VideoCarousel;
