import {
  ImageVaultFieldType,
  StoryblokAsset,
} from "../lib/storyblok/types/fieldtypes/imagevaultFieldtype";
import Image, { ImageLoader, ImageProps } from "next/image";
import { NextRequest } from "next/server";
import { ImageSizes } from "../lib/utils/imageUtils";
import { useCallback, useEffect, useMemo, useState } from "react";

if (process.env.IMAGEVAULT_URL === "undefined")
  throw new Error("Tobii ImageVault URL .env is missing");

type Props = {
  image: ImageVaultFieldType & Partial<StoryblokAsset>;
  sizes?: ImageSizes;
  layout?: string;
  contain?: boolean;
  width?: number;
  height?: number;
};

type RemainingImageProps = Omit<
  ImageProps,
  "width" | "height" | "src" | "sizes"
>;

const getStoryblokImageSrcSize = (
  url: string
): { width: number; height: number } => {
  const match = url.match(/\/f\/\d+\/(\d+)x(\d+)\//);
  return match
    ? { width: parseInt(match[1], 10), height: parseInt(match[2], 10) }
    : { width: 0, height: 0 };
};

const getImageSize = (
  url: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () =>
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    img.onerror = () => resolve({ width: 0, height: 0 });
  });
};

const getImageSizeFromCrop = (data?: {
  x1?: number;
  x2?: number;
  y1?: number;
  y2?: number;
}) => {
  if (
    !data ||
    typeof data.x1 !== "number" ||
    typeof data.x2 !== "number" ||
    typeof data.y1 !== "number" ||
    typeof data.y2 !== "number"
  ) {
    return { width: 0, height: 0 };
  }
  const width = Math.abs(data.x2 - data.x1);
  const height = Math.abs(data.y2 - data.y1);
  return { width, height };
};

const ImageVaultImage = ({
  image,
  layout,
  sizes,
  contain,
  ...imageProps
}: RemainingImageProps & Props) => {
  const isStoryblokAsset = image && "filename" in image && "fieldtype" in image;
  const isImageVaultWithItem =
    image &&
    "plugin" in image &&
    image.plugin === "image-vault" &&
    "item" in image &&
    image.item !== undefined &&
    image.item !== null;
  const storyblokAsset = isStoryblokAsset
    ? (image as StoryblokAsset)
    : undefined;

  const src = useMemo(() => {
    if (!image || Object.keys(image).length === 0) {
      return "";
    }

    if (storyblokAsset) {
      const cropData = storyblokAsset.meta_data?.crop;
      const cropURI = getStoryblokCropURI(cropData);
      return storyblokAsset.filename + cropURI;
    } else if (isImageVaultWithItem && image.item?.MediaConversions?.[0]?.Url) {
      return (
        "/" + image.item.MediaConversions[0].Url.split("/").slice(3).join("/")
      );
    }
    return "";
  }, [image, isImageVaultWithItem, storyblokAsset]);

  const altText = useMemo(() => {
    if (!image) return "";

    if (storyblokAsset) {
      return storyblokAsset.alt || "";
    }
    return isImageVaultWithItem && image.item?.Metadata
      ? image.item.Metadata.find((meta) => meta.MetadataDefinitionId === 1082)
          ?.Value || ""
      : "";
  }, [image, isImageVaultWithItem, storyblokAsset]);

  const loader: ImageLoader = useCallback(
    ({ src, width, quality }) =>
      storyblokAsset
        ? storyblokLoader({ src, width, quality })
        : imageVaultLoader({ src, width, quality }),
    [storyblokAsset]
  );

  const [imageSize, setImageSize] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 }
  );

  useEffect(() => {
    if (typeof window === "undefined" || layout === "fill" || !storyblokAsset) {
      return;
    }

    if (
      storyblokAsset.meta_data?.crop &&
      storyblokAsset.meta_data?.crop?.x1 &&
      storyblokAsset.meta_data?.crop?.x2 &&
      storyblokAsset.meta_data?.crop?.y1 &&
      storyblokAsset.meta_data?.crop?.y2
    ) {
      setImageSize(getImageSizeFromCrop(storyblokAsset.meta_data.crop));
    } else {
      const { width, height } = getStoryblokImageSrcSize(
        storyblokAsset.filename
      );
      if (width && height) {
        setImageSize({ width, height });
      } else {
        getImageSize(storyblokAsset.filename).then(setImageSize);
      }
    }
  }, [storyblokAsset, layout]);

  // Perform an additional validation
  const isValidImage = !!src && (isStoryblokAsset || isImageVaultWithItem);

  if (!isValidImage) {
    return null;
  }

  return (
    <>
      <Image
        lazyBoundary="600px"
        loader={loader}
        alt={altText}
        src={src}
        layout={layout}
        objectFit={contain ? "contain" : "cover"}
        width={
          layout !== "fill"
            ? storyblokAsset
              ? imageSize.width
              : image.item?.MediaConversions?.[0]?.Width ?? 0
            : undefined
        }
        height={
          layout !== "fill"
            ? storyblokAsset
              ? imageSize.height
              : image.item?.MediaConversions?.[0]?.Height ?? 0
            : undefined
        }
        sizes={layout === "fill" || layout === "responsive" ? sizes : undefined}
        quality={80}
        {...imageProps}
      />
    </>
  );
};

export function rewriteImageVaultImageUrl(url: NextRequest["nextUrl"]) {
  const [, , , hash, file] = url.pathname.split("/");
  const { w, q } = Object.fromEntries(
    url.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
  );
  url.pathname = "/_next/image";
  url.search = `?url=${
    process.env.IMAGEVAULT_URL
  }/publishedmedia/${hash}/${file}&w=${w ?? 3840}&q=${q ?? 100}`;
}

export const getStoryblokCropURI = (data?: {
  x1?: number;
  x2?: number;
  y1?: number;
  y2?: number;
}) => {
  if (
    !data ||
    typeof data.x1 !== "number" ||
    typeof data.x2 !== "number" ||
    typeof data.y1 !== "number" ||
    typeof data.y2 !== "number"
  ) {
    return "/m/0x0:0x0";
  }
  return `/m/${data.x1}x${data.y1}:${data.x2}x${data.y2}`;
};

export const imageVaultLoader = ({ src, width, quality }): string => {
  // This url is rewritten to "_next/image/..." in middleware.ts
  // with the rewriteImageVaultImageUrl function
  return `/imagevault${src}?w=${width}&q=${quality || 75}`;
};

export const storyblokLoader = ({ src, width, quality }): string => {
  return `${src}/${width}x0/filters:format(webp):quality(${quality || 75})`;
};

export default ImageVaultImage;
