import React, { useRef, useEffect, useState, useCallback } from "react";
import cn from "classnames";
import s from "../InteractiveGrid.module.scss";
import Loader from "../../Common/Loader";

interface VideoPlayerProps {
  videoUrl: string | undefined;
  isHovered: boolean;
  title?: string;
  maxPlayCount?: number;
}

const DEFAULT_MAX_PLAY_COUNT = 2;

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoUrl,
  isHovered,
  title,
  maxPlayCount = DEFAULT_MAX_PLAY_COUNT,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playCount, setPlayCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const unmountingRef = useRef(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || unmountingRef.current) return;

    if (isHovered && playCount < maxPlayCount) {
      video.play().catch((error) => {
        if (error.name !== "NotAllowedError") {
          // console.error("Play error:", error);
        }
      });
    } else {
      video.pause();
      video.currentTime = 0;
    }
  }, [isHovered, playCount, maxPlayCount]);

  // Handle video end
  const handleVideoEnd = useCallback(() => {
    if (unmountingRef.current) return;
    if (playCount < maxPlayCount - 1) {
      setPlayCount((prev) => prev + 1);
    }
  }, [playCount, maxPlayCount]);

  // Add event listeners
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.addEventListener("ended", handleVideoEnd);

    const videoElement = video;

    return () => {
      videoElement.removeEventListener("ended", handleVideoEnd);
    };
  }, [handleVideoEnd]);

  useEffect(() => {
    const video = videoRef.current;

    return () => {
      unmountingRef.current = true;
      if (video) {
        video.pause();
        video.src = "";
        video.load();
      }
    };
  }, []);

  const handleCanPlay = useCallback(() => {
    if (!unmountingRef.current) {
      setIsLoading(false);
    }
  }, []);

  if (!videoUrl) return null;

  return (
    <div
      className={cn(s.videoPlayer, { [s.active]: isHovered })}
      data-loading={isLoading}
    >
      {isLoading && <Loader variant="white" size="small" />}
      <video
        ref={videoRef}
        className={s.video}
        src={videoUrl}
        controls={false}
        playsInline
        onCanPlay={handleCanPlay}
        aria-hidden={!isHovered}
        aria-label={title}
        muted
      >
        <track kind="captions" label="No captions available" />
      </video>
    </div>
  );
};

export default React.memo(VideoPlayer);
