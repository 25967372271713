// Accordion.tsx
import React from "react";
import cn from "classnames";
import s from "./Accordion.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import BlockIntro from "../Common/BlockIntro";
import BlockBackground from "../Common/BlockBackground";
import Item from "./components/Item";

type Props = {
  title: string;
  description: string;
  buttons?: React.ReactNode;
  innerSpacing: string;
  outerSpacing: string;
  style: string;
  width: string;
  children: React.ReactElement[];
  expanded: string | false;
  inverted?: boolean;
  handleChange: (
    panel: string
  ) => (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const subComponents = { Item };
type SubComponents = typeof subComponents;

const Accordion: CWS<Props, SubComponents> = ({
  title,
  description,
  buttons,
  style,
  innerSpacing,
  outerSpacing,
  width,
  children,
  expanded,
  handleChange,
  inverted,
}) => {
  return (
    <section
      className={cn(s.accordion, bs[innerSpacing], bs[outerSpacing], c[width], {
        [s.roundedCorners]: width !== "fluid" && width !== undefined && !!width,
      })}
    >
      <BlockBackground style={style} opacity={100} />

      <div className={cn(s.accordionInner, c.wide)}>
        <BlockIntro
          alignment={"alignCenter"}
          title={title}
          description={description}
          buttons={buttons}
          inverted={inverted}
        />
        <div className={cn(s.items)}>
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              expanded: expanded === `panel${index}`,
              onChange: handleChange(`panel${index}`),
            })
          )}
        </div>
      </div>
    </section>
  );
};

Accordion.Item = Item;

export default Accordion;
