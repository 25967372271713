import React, { useState } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import FeaturedTabsUi from "../../ui/FeaturedTabs";
import { FeaturedTabsBlock } from "../../lib/storyblok/types/blocks/FeaturedTabsBlock";
import { MobileMenu } from "../../ui/AnchorMenu";
import VideoPlayer from "../../ui/Common/VideoPlayer";
import { DynamicBlock } from "../DynamicBlock";
import { useTranslation } from "next-i18next";

const { TabList, Tab } = FeaturedTabsUi;

const FeaturedTabs: StoryblokBlockComponent<FeaturedTabsBlock> = ({
  title,
  mobileMenuTitle,
  items,
  backgroundVideo,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const tabs = items.map((item) => item.tabTitle);

  const { t } = useTranslation();

  return (
    <FeaturedTabsUi
      titleLabel={title}
      title={tabs[activeIndex]}
      tabs={
        <TabList
          mobileMenu={
            <MobileMenu
              title={mobileMenuTitle}
              close={t("close")}
              onCloseClick={() => setIsMobileMenuOpen(false)}
              isFeaturedTabs
            >
              {tabs.map((tab, i) => (
                <Tab
                  key={tab}
                  text={tab}
                  onClick={() => {
                    setActiveIndex(i);
                    setIsMobileMenuOpen(false);
                  }}
                  active={i === activeIndex}
                  isMobileTab
                />
              ))}
            </MobileMenu>
          }
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={() => setIsMobileMenuOpen(true)}
        >
          {tabs.map((tab, i) => (
            <Tab
              key={tab}
              text={tab}
              onClick={() => setActiveIndex(i)}
              active={i === activeIndex}
            />
          ))}
        </TabList>
      }
    >
      {items.length > 0 && (
        <DynamicBlock
          overrides={""}
          content={{
            ...items[activeIndex],
            ...{
              parallaxVideo: backgroundVideo.filename && (
                <VideoPlayer videoUrl={backgroundVideo.filename} />
              ),
            },
          }}
        />
      )}
    </FeaturedTabsUi>
  );
};

export default FeaturedTabs;
