import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import SearchPageGrid from "../../ui/PageTemplates/SearchPage";
import { Configure, InstantSearch } from "react-instantsearch";
import SearchBox from "../Nestable/SearchBox";
import InfiniteHits from "../Nestable/InfiniteHits";
import RefinementList from "../Nestable/RefinementList";
import { useRouter } from "next/router";
import { SearchClient } from "algoliasearch";

type Props = {
  indices: Record<string, string>;
  children: React.ReactNode;
  locale: string;
};

const SearchPage = ({ indices, children, locale }: Props) => {
  const { t } = useTranslation(["common"]);
  const [searchClient, setSearchClient] = useState<null | SearchClient>(null);

  useEffect(() => {
    // Dynamically import initAlgoliaSearch on mount
    const loadSearchClient = async () => {
      const { initAlgoliaSearch } = await import(
        "../../lib/utils/AlgoliaSearchHelpers"
      );
      const client = initAlgoliaSearch();
      setSearchClient(client);
    };

    loadSearchClient();
  }, []);

  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;

  if (!searchClient) {
    return <div>{t("loading")}</div>;
  }

  return (
    <InstantSearch indexName={indices[locale]} searchClient={searchClient}>
      <Configure
        hitsPerPage={9}
        attributesToSnippet={["content:40", "metaDescription:40"]}
        query={initialValue}
      />
      {children}
      <SearchPageGrid>
        <SearchPageGrid.Toolbar>
          <SearchPageGrid.Input>
            <SearchBox defaultValue={initialValue} type="large" />
          </SearchPageGrid.Input>
        </SearchPageGrid.Toolbar>
        <SearchPageGrid.Filter>
          <RefinementList layout="expand" />
        </SearchPageGrid.Filter>
        <SearchPageGrid.Body>
          <InfiniteHits />
        </SearchPageGrid.Body>
      </SearchPageGrid>
    </InstantSearch>
  );
};

export default SearchPage;
