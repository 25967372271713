import React from "react";
import Button from "../../../../ui/Button";
import s from "../FilterPage.module.scss";

type FilterProps = {
  children?: string;
  clearLabel?: string;
  onClearClick?: () => void;
};

const Heading = ({ clearLabel, onClearClick }: FilterProps) => {
  return (
    <div className={s.clearButton}>
      {clearLabel && onClearClick && (
        <Button
          icon="cancel"
          onClick={onClearClick}
          variant="default"
          size="small"
        >
          {clearLabel}
        </Button>
      )}
    </div>
  );
};

export default Heading;
