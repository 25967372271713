import React, { ReactNode, Children, useRef, useState, useEffect } from "react";
import cn from "classnames";
import s from "../TabsGrid.module.scss";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import useMediaQuery from "../../../hooks/useMatchMedia";
import { SliderButton } from "../../ContentGridSlider";

type Props = {
  children: ReactNode;
  layout: string;
};

const gridVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const TabGrid = ({ layout, children }: Props) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [scrollState, setScrollState] = useState({
    hasScroll: false,
    isAtStart: true,
    isAtEnd: false,
  });
  const isDesktopDown = useMediaQuery("(max-width: 1024px)");
  const { t } = useTranslation();
  const totalSlides = Children.count(children);

  const updateArrows = () => {
    const list = listRef.current;
    if (!list) return;

    const { scrollLeft, scrollWidth, clientWidth } = list;
    const hasScrollableContent = scrollWidth > clientWidth;

    setScrollState({
      hasScroll: hasScrollableContent,
      isAtStart: scrollLeft <= 1,
      isAtEnd: hasScrollableContent
        ? Math.abs(scrollWidth - clientWidth - scrollLeft) <= 1
        : true,
    });
  };

  useEffect(() => {
    updateArrows();
    window.addEventListener("resize", updateArrows);

    return () => window.removeEventListener("resize", updateArrows);
  }, []);

  useEffect(() => {
    const list = listRef.current;
    if (!list) return;

    list.addEventListener("scroll", updateArrows);
    return () => list.removeEventListener("scroll", updateArrows);
  }, []);

  const scroll = (direction: number) => {
    const list = listRef.current;
    if (!list) return;

    list.scrollTo({
      left: list.scrollLeft + direction * list.clientWidth * 0.8,
      behavior: "smooth",
    });
  };

  const showNavigation =
    totalSlides > 2 && isDesktopDown && scrollState.hasScroll;

  return (
    <div className={s.tabGridContainer}>
      <motion.ul
        className={cn(s.grid, s[layout])}
        initial="hidden"
        animate="visible"
        variants={gridVariants}
        ref={listRef}
        role="list"
        aria-label={t("gridItems")}
        data-item-count={totalSlides}
      >
        {children}
      </motion.ul>

      {showNavigation && (
        <div className={s.sliderNavigation}>
          {!scrollState.isAtStart && (
            <SliderButton
              onClick={() => scroll(-1)}
              align="prev"
              label={t("previous")}
              inverted={false}
            />
          )}
          {!scrollState.isAtEnd && (
            <SliderButton
              onClick={() => scroll(1)}
              align="next"
              label={t("next")}
              inverted={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TabGrid;
