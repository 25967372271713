import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { Language } from "../../../hooks/useLanguageSelect";
import { useLinks } from "../../../lib/storyblok/useLinks";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { HeaderBlock } from "../../../lib/storyblok/types/blocks/HeaderBlock";
import SubNavigation from "./components/SubNavigation";
import { StoryblokBlockComponent } from "../../../lib/storyblok/types/StoryblokComponent";

import HeaderUi, {
  DesktopMenu,
  DesktopNav,
  DesktopNavItem,
  DesktopSubNavigationWrapper,
  DesktopSubNavigationWrapperRef,
  MobileMenu,
  MobileInner,
  MobileHeader,
  MobileFooterItem,
  MobileNav,
  MobileNavList,
} from "../../../ui/Header";
import { MobileNavItem } from "../../../ui/Header/components/mobile/MobileNavItem";

type HeaderProps = HeaderBlock & {
  onLangClick: () => void;
  languages: Language[];
};

const Header: StoryblokBlockComponent<HeaderProps> = ({
  headerNavigation,
  tobiiGroup,
  searchPageLink,
  onLangClick,
}) => {
  const resolveLink = useLinks();
  const { t } = useTranslation();
  const { events, push } = useRouter();

  const [scrolled, setScrolled] = useState(false);
  const [isDesktopSubNavOpen, setIsDesktopSubNavOpen] = useState(false);
  const [activeDesktopNavItemIndex, setActiveDesktopNavItemIndex] = useState<
    number | null
  >(null);
  const desktopNavRef = useRef<HTMLElement>(null);
  const navItemRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const subNavWrapperRef = useRef<DesktopSubNavigationWrapperRef>(null);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeMobileNavItemIndex, setActiveMobileNavItemIndex] = useState(0);
  const [isMobileSubNavOpen, setIsMobileSubNavOpen] = useState(false);

  const handleCloseSubNav = useCallback(() => {
    setIsDesktopSubNavOpen(false);
    setTimeout(() => setActiveDesktopNavItemIndex(null), 300);
  }, []);

  const handleNavItemClick = useCallback(
    (index: number, hasSubNav: boolean) => {
      if (hasSubNav) {
        if (activeDesktopNavItemIndex === index) {
          handleCloseSubNav();
        } else {
          setActiveDesktopNavItemIndex(index);
          setIsDesktopSubNavOpen(true);
        }
      }
    },
    [activeDesktopNavItemIndex, handleCloseSubNav]
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        isDesktopSubNavOpen &&
        desktopNavRef.current &&
        !desktopNavRef.current.contains(event.target as Node) &&
        subNavWrapperRef.current?.subNavRef.current &&
        !subNavWrapperRef.current.subNavRef.current.contains(
          event.target as Node
        )
      ) {
        handleCloseSubNav();
      }
    },
    [isDesktopSubNavOpen, handleCloseSubNav]
  );

  useEffect(() => {
    const closeMenu = () => {
      setIsMobileMenuOpen(false);
      handleCloseSubNav();
    };
    events.on("routeChangeComplete", closeMenu);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      events.off("routeChangeComplete", closeMenu);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [events, handleClickOutside, handleCloseSubNav]);

  const activeSubNav = useMemo(() => {
    if (activeDesktopNavItemIndex === null) return null;
    const subNavItems =
      headerNavigation[activeDesktopNavItemIndex]?.subNavigation;
    if (!subNavItems) return null;

    return (
      <DesktopSubNavigationWrapper
        ref={subNavWrapperRef}
        isOpen={isDesktopSubNavOpen}
      >
        <SubNavigation subNavigation={subNavItems} view="desktop" />
      </DesktopSubNavigationWrapper>
    );
  }, [activeDesktopNavItemIndex, isDesktopSubNavOpen, headerNavigation]);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 160 && !scrolled) {
      setScrolled(true);
    } else if (window.scrollY <= 160 && scrolled) {
      setScrolled(false);
    }
  }, [scrolled]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <HeaderUi
        isDesktopSubNavOpen={isDesktopSubNavOpen}
        isTobiiGroup={tobiiGroup}
        activeSubNav={activeSubNav}
        onCloseSubNav={handleCloseSubNav}
        stickToTheTop={scrolled}
      >
        <DesktopMenu
          onLangClick={onLangClick}
          onLogoClick={(e) => {
            e.preventDefault();
            push("/");
          }}
          onSearchClick={(e) => {
            e.preventDefault();
            push(resolveLink(searchPageLink));
          }}
          searchHref={resolveLink(searchPageLink)}
          isTobiiGroup={tobiiGroup}
          stickToTheTop={scrolled}
        >
          <DesktopNav
            activeItemIndex={activeDesktopNavItemIndex}
            navRef={desktopNavRef}
          >
            {headerNavigation?.map((item, i) => {
              const hasSubNav =
                item.subNavigation && item.subNavigation.length > 0;
              return (
                <DesktopNavItem
                  ref={(el) => (navItemRefs.current[i] = el)}
                  title={item.title}
                  isActive={activeDesktopNavItemIndex === i}
                  onClick={() => handleNavItemClick(i, hasSubNav)}
                  href={resolveLink(item.link)}
                  key={item._uid}
                  isTobiiGroup={tobiiGroup}
                  hasSubNav={hasSubNav}
                />
              );
            })}
          </DesktopNav>
        </DesktopMenu>
      </HeaderUi>
      <MobileMenu isOpen={isMobileMenuOpen} isTobiiGroup={tobiiGroup}>
        <MobileHeader
          onLangClick={onLangClick}
          onSearchClick={(e) => {
            e.preventDefault();
            push(resolveLink(searchPageLink));
          }}
          searchHref={resolveLink(searchPageLink)}
          isOpen={isMobileMenuOpen}
          toggleOpen={() => {
            setIsMobileMenuOpen(!isMobileMenuOpen);
            setIsMobileSubNavOpen(false);
          }}
          isTobiiGroup={tobiiGroup}
        />
        <MobileInner>
          <MobileNav>
            {isMobileMenuOpen && (
              <MobileNavList isOpen={isMobileSubNavOpen} level={0}>
                {headerNavigation?.map((item, i) => {
                  return (
                    <MobileNavItem
                      key={item._uid}
                      text={item.title}
                      href={resolveLink(item.link)}
                      directLink={item.subNavigation?.length === 0}
                      index={i}
                      tab={false}
                      onClick={() => {
                        setActiveMobileNavItemIndex(i);
                        setIsMobileSubNavOpen(true);
                      }}
                    />
                  );
                })}
              </MobileNavList>
            )}
            {isMobileSubNavOpen && activeMobileNavItemIndex !== null && (
              <SubNavigation
                subNavigation={
                  headerNavigation[activeMobileNavItemIndex].subNavigation
                }
                view="mobile"
                title={headerNavigation[activeMobileNavItemIndex].title}
                href={resolveLink(
                  headerNavigation[activeMobileNavItemIndex].link
                )}
                setIsMobileSubNavOpen={setIsMobileSubNavOpen}
              />
            )}
          </MobileNav>
          {isMobileMenuOpen && (
            <>
              {!tobiiGroup && (
                <MobileFooterItem
                  iconSuffix="arrow-up-right"
                  text={t("tobii-corporate")}
                  href="https://corporate.tobii.com/"
                  siteTitle={""}
                  onLangClick={onLangClick}
                />
              )}
              {tobiiGroup && (
                <MobileFooterItem
                  iconSuffix="arrow-up-right"
                  text={t("tobii-com")}
                  href="https://www.tobii.com/"
                  siteTitle={""}
                  onLangClick={onLangClick}
                />
              )}
            </>
          )}
        </MobileInner>
      </MobileMenu>
    </>
  );
};

export default Header;
