import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./TechSpec.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import c from "../../styles/container.module.scss";
import Expandable from "./components/Expandable";
import ExpandButton from "./components/ExpandButton";
import Tables from "./components/Tables";
import Table from "./components/Table";
import TableRow from "./components/TableRow";
import TableNotes from "./components/TableNotes";
import TableNote from "./components/TableNote";
import {
  InnerSpacingVariant,
  OuterSpacingVariant,
  StyleVariant,
} from "../../lib/storyblok/types/shared";
import BlockBackground from "../Common/BlockBackground";
import BlockIntro from "../Common/BlockIntro";

type Props = {
  title: string;
  text: string;
  expandButton?: ReactNode;
  children: ReactNode;
  spacing: InnerSpacingVariant;
  style: StyleVariant;
  outerSpacing: OuterSpacingVariant;
  inverted?: boolean;
};

const subComponents = {
  Expandable,
  ExpandButton,
  Tables,
  Table,
  TableRow,
  TableNotes,
  TableNote,
};
type SubComponents = typeof subComponents;

const TechSpec: CWS<Props, SubComponents> = ({
  title,
  text,
  expandButton,
  children,
  spacing = "both",
  style = "signature",
  outerSpacing,
  inverted = true,
}) => (
  <section
    className={cn(s.techSpecs, c.fluid, bs[outerSpacing], bs[spacing], {
      [s.inverted]: inverted,
    })}
  >
    <BlockBackground style={style} opacity={100} />
    <div className={cn(s.wrapper, c.xWide)}>
      <BlockIntro
        title={title}
        description={text}
        alignment={"alignLeft"}
        inverted={inverted}
        width="contained"
      />
      {expandButton}
    </div>
    <div className={cn(s.techSpec, c.xWide)}>{children}</div>
  </section>
);

TechSpec.Expandable = Expandable;
TechSpec.ExpandButton = ExpandButton;
TechSpec.Tables = Tables;
TechSpec.Table = Table;
TechSpec.TableRow = TableRow;
TechSpec.TableNotes = TableNotes;
TechSpec.TableNote = TableNote;

export default TechSpec;
