import React, { useState } from "react";
import cn from "classnames";
import s from "./Alert.module.scss";
import { AlertVariant } from "../../../lib/storyblok/types/shared";
import Icon from "../../Icon";
import { useTranslation } from "next-i18next";

export type IconType = "info" | "check-circled" | "warning";

interface AlertProps {
  variant: AlertVariant;
  title?: string;
  message?: string;
  dismissible?: boolean;
  onClose?: () => void;
}

const getIconType = (variant: AlertVariant): IconType => {
  switch (variant) {
    case "success":
      return "check-circled";
    case "warning":
    case "danger":
      return "warning";
    default:
      return "info";
  }
};

const Alert: React.FC<AlertProps> = ({
  variant = "info",
  message,
  title,
  dismissible = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  if (!visible) {
    return null;
  }

  const iconType = getIconType(variant);

  return (
    <div
      className={cn(s.alert, s[variant], {
        [s.dismissible]: dismissible,
      })}
      role="alert"
    >
      <Icon
        icon={iconType}
        width={20}
        color={s.alertIconColor}
        className={s.icon}
      />

      <div className={s.content}>
        {title && <span className={s.title}>{title}</span>}
        {message && <span className={s.message}>{message}</span>}
      </div>

      {dismissible && (
        <button
          type="button"
          className={s.closeButton}
          aria-label={t("close")}
          onClick={handleClose}
        >
          <Icon icon="cancel" width={16} color={s.iconColor} />
        </button>
      )}
    </div>
  );
};

export default Alert;
