import React from "react";
import cn from "classnames";
import s from "../Testimonials.module.scss";
import Icon from "../../Icon";

const Testimonial = ({ quote, name, roleAndCompany, logo, buttons }) => {
  return (
    <div className={cn(s.testimonial)}>
      <div className={s.quoteWrapper}>
        <Icon width={48} icon="quote" className={s.icon} />
        <q className={s.quote}>{quote}</q>
      </div>
      <div className={s.companyWrapper}>
        <p className={s.name}>{name}</p>
        <p className={cn("label", s.roleAndCompany)}>{roleAndCompany}</p>
        {logo && <div className={s.logoWrapper}>{logo}</div>}
        {buttons && <div className={s.buttons}>{buttons}</div>}
      </div>
    </div>
  );
};

export default Testimonial;
