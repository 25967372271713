import React, { ChangeEvent, HTMLAttributes, useRef, useState } from "react";

import s from "./SearchInput.module.scss";
import cn from "classnames";
import Icon from "../Icon";

export type SearchInputProps = HTMLAttributes<HTMLInputElement> & {
  label: string;
  clearLabel: string;
  placeholder: string;
  value?: string;
  defaultValue?: string;
  type?: "mini" | "small" | "large";
  onClearClick?: () => void;
};

const SearchInput = ({
  label,
  clearLabel,
  onChange: _onChange,
  type = "large",
  onClearClick,
  value,
  defaultValue,
  ...props
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [empty, setEmpty] = useState(!defaultValue);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (value !== undefined) {
      setEmpty(!value.length);
    } else {
      setEmpty(!event.target.value.length);
    }

    if (_onChange) {
      _onChange(event);
    }
  };

  const clear = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setEmpty(true);
      if (onClearClick) {
        onClearClick();
      }
    }
  };

  const isSmall = type === "small";
  const isMini = type === "mini";

  const iconSize = isMini ? 12 : isSmall ? 16 : 24;

  return (
    <label
      className={cn(s.searchInput, {
        [s.searchInput_small]: isSmall,
        [s.searchInput_mini]: isMini,
      })}
    >
      <span className="visuallyHidden">{label}</span>
      <Icon icon="search" width={iconSize} />
      <input
        {...props}
        type="search"
        ref={inputRef}
        onChange={onChange}
        className={s.input}
        value={value}
        defaultValue={defaultValue}
      />
      <button
        className={s.clear}
        onClick={clear}
        disabled={value !== undefined ? !value.length : empty}
      >
        <span className="visuallyHidden">{clearLabel}</span>
        <Icon icon="cancel" width={iconSize} />
      </button>
    </label>
  );
};

export default SearchInput;
