import { ReactNode } from "react";
import { Markdown } from "../../../lib/storyblok/types/blocks/MarkdownBlock";
import cn from "classnames";
import s from "../ColumnGrid.module.scss";
import BlockIntro from "../../Common/BlockIntro";

type Props = {
  image?: ReactNode;
  video?: ReactNode;
  title: string;
  markdown: Markdown;
  children: ReactNode;
  imageKeepProportions?: boolean;
  inverted?: boolean;
};

const Item = ({
  image,
  video,
  title,
  markdown,
  children,
  imageKeepProportions,
  inverted,
}: Props) => (
  <article
    className={cn(s.item, {
      [s.itemImage]: !video && image,
      [s.itemVideo]: !image && video,
    })}
  >
    {image && (
      <div
        className={cn(s.imageContainer, {
          [s.imageKeepProportions]: imageKeepProportions,
        })}
      >
        {image && image}
      </div>
    )}
    {video && <div className={s.videoContainer}>{video}</div>}
    <BlockIntro
      title={title}
      description={markdown}
      alignment={"alignLeft"}
      inverted={inverted}
      width={"full"}
      buttons={children}
      headingTag="h3"
      descriptionStyle="faded"
      descriptionSize="small"
    />
  </article>
);

export default Item;
