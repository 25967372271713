/* eslint-disable */
import { z } from "zod";
import { publicationAxios } from "./publicationAxios";
export interface Tag {
  id: number;
  name: string;
  count: number;
}

export interface PublicationPageState {
  prodTagsList: Tag[];
  fieldOFResTagsList: Tag[];
  filteredPubTags: Tag[];
  yearsList: Tag[];
}
export interface PublicationItems {
  id: number;
  title: string;
  source_Title: string | null;
  date: string;
  abstract: string;
  doi: string;
  fieldOfResearchTags?: { id: number; name: string }[];
  productTags?: { id: number; name: string }[];
  publicationTags?: { id: number; name: string }[];
  publicationAuthors?: {
    id: number;
    family: string;
    given: string;
    affiliations: { name: string }[];
  }[];
}

export const getPublications = async (
  page,
  yearFilters,
  prodTagFilters,
  fieldOFResTagFilters,
  pubTagFilters,
  keyword
) => {
  try {
    console.log("Making API call to get publications...");

    const res = await publicationAxios.get<z.infer<typeof schema>>(
      "/GetPublicationsWithMetaDataV1",
      {
        params: {
          Page: page,
          PageSize: 8,
          code: "v3bAJQOnIUy_hACQo5dlr1dHnGCpBwG8vQJY77tFA_3jAzFuHxBSSw==",
          YearFilter: yearFilters ? yearFilters.join(",") : "",
          ProdTagFilter: prodTagFilters ? prodTagFilters.join(",") : "",
          FieldOFResTagFilter: fieldOFResTagFilters
            ? fieldOFResTagFilters.join(",")
            : "",
          PubTagFilter: pubTagFilters ? pubTagFilters.join(",") : "",
          KeyWordFilter: keyword || "",
        },
      }
    );

    const { data } = res;

    schema.parse(data);
    return data;
  } catch (error) {
    console.error("Error:", error);

    let errorMessage = "An unexpected error occurred. Please try again later.";

    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode >= 500) {
        errorMessage = "The server is currently down. Please try again later.";
      } else if (statusCode >= 400 && statusCode < 500) {
        errorMessage =
          "There was an issue with your request. Please check the filters or try again.";
      }
      console.log("Error Status Code:", statusCode);
    } else if (error.request) {
      errorMessage =
        "Unable to connect to the server. Please check your connection.";
    } else {
      errorMessage = "There was an issue while setting up the request.";
    }

    throw new Error(errorMessage);
  }
};

export const schema = z.object({
  totalCount: z.number(),
  pageCount: z.number(),
  currentPage: z.number(),
  pageSize: z.number(),
  publications: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      source_Title: z.string().nullable(),
      abstract: z.string(),
      doi: z.string(),
      date: z.string(),
      fieldOfResearchTags: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
        })
      ),
      productTags: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
        })
      ),
      publicationTags: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
        })
      ),
      publicationAuthors: z.array(
        z.object({
          id: z.number(),
          family: z.string(),
          given: z.string(),
          affiliations: z.array(
            z.object({
              name: z.string(),
            })
          ),
        })
      ),
    })
  ),
  fieldOfResearchTagGroups: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      count: z.number(),
    })
  ),
  productTagGroups: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      count: z.number(),
    })
  ),
  publicationTagGroups: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      count: z.number(),
    })
  ),
  yearGroups: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      count: z.number(),
    })
  ),
});
