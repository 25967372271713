import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import s from "./StackCarousel.module.scss";
import TextAndImage from "../../../components/Nestable/TextAndImage";
import BlockIntro from "../../Common/BlockIntro";
import bs from "../../../styles/block.spacing.module.scss";

interface ScrolledItem {
  index: number;
  offset: number;
  scale: number;
}
const StackCarousel = ({
  stackingItems,
  title,
  description,
  buttons,
  inverted,
  layout,
  spacing,
  outerSpacing,
}) => {
  const [scrolledItems, setScrolledItems] = useState<ScrolledItem[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    const container = containerRef.current;

    if (container) {
      const items = Array.from(container.children);

      const newScrolledItems = items.map((item, index) => {
        const rect = item.getBoundingClientRect();
        const offset = Math.max(0, rect.top / window.innerHeight);

        let scale = 1;

        if (index < items.length - 1) {
          const nextItem = items[index + 1];
          const nextRect = nextItem.getBoundingClientRect();
          const nextItemHeight = nextRect.height;

          const visiblePercentage =
            Math.min(
              Math.max(0, window.innerHeight - nextRect.top),
              nextItemHeight
            ) / nextItemHeight;

          if (visiblePercentage >= 0.55) {
            scale = 1 - (items.length - index - 1) * 0.1 + offset * 0.4;
            scale = scale > 0.5 ? scale : 0.5;
          }
        }

        return { index, offset, scale };
      });

      setScrolledItems(newScrolledItems);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className={cn(bs[spacing], bs[outerSpacing])}>
      <BlockIntro
        alignment={"alignCenter"}
        inverted={inverted}
        title={title}
        description={description}
        buttons={buttons}
        width={"contained"}
      />
      <section
        ref={containerRef}
        className={cn(s.scrollTriggered, s.noGapForStackCarousel)}
      >
        {stackingItems &&
          stackingItems.map((item, index) => {
            const scale = scrolledItems[index]?.scale || 1;
            const isSticky =
              index === 0 ? { position: "sticky" as const, top: 0 } : {};

            return (
              <div
                id={`block${index}`}
                className={cn(s.stackItem)}
                key={item._uid}
                style={{
                  transform: `scale(${scale})`,
                  ...isSticky,
                }}
              >
                <TextAndImage
                  className={s.noGapStackCarousel}
                  _uid={item._uid}
                  component={item.component}
                  _editable={item._editable}
                  text={item.text}
                  image={item.image}
                  title={item.title}
                  reversed={true}
                  buttonText={item.buttonText}
                  buttonLink={item.buttonLink}
                  secondButtonText={item.secondButtonText}
                  secondButtonLink={item.secondButtonLink}
                  style={
                    item.style === "none" ||
                    item.style === "white" ||
                    item.style === "gray"
                      ? "frostBlue"
                      : item.style
                  }
                  mediaBackground={item.mediaBackground}
                  layout={layout}
                  typeformSize={100}
                  typeformButtonStyle={item.typeformButtonStyle}
                  imageKeepProportions={false}
                  typeformCopy={item.typeformCopy}
                  typeformID={item.typeformID}
                  outerSpacing={"outerNone"}
                />
              </div>
            );
          })}
      </section>
    </div>
  );
};

export default StackCarousel;
