import React, { HTMLProps } from "react";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "../ContentGridSlider.module.scss";

const Navigation = ({ children }: HTMLProps<HTMLDivElement>) => (
  <div className={cn(c.xWide, s.navigation)}>{children}</div>
);

export default Navigation;
