import { ReactNode } from "react";
import cn from "classnames";
import s from "./FilterCard.module.scss";
import { useTranslation } from "next-i18next";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";

type ImageAlignment = "center" | "left";

type Props = {
  image?: ReactNode;
  type?: string;
  title: string | ReactNode;
  date?: string;
  startDate?: string;
  location?: string;
  author?: string;
  readTime?: string;
  link: ReactNode;
  children?: ReactNode;
  label?: ReactNode;
  categories?: string[];
  pageType?: string;
  imageAlignment?: ImageAlignment;
};

const FilterCard = ({
  image,
  type,
  title,
  date,
  startDate,
  location,
  children,
  author,
  readTime,
  pageType,
  imageAlignment,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cn(s.filterCard)}>
      {image && (
        <div className={cn(s.imageContainer, s[`align${imageAlignment}`])}>
          {image}
        </div>
      )}

      <div className={cn(s.content)}>
        {type && <p className={cn("label")}>{type}</p>}

        <h3 className={s.title}>{title}</h3>
        {children && <div className={s.body}>{children}</div>}
      </div>

      {(startDate || date || location || author || readTime) && (
        <ul className={s.details}>
          {author && (
            <li>
              <label className={cn("author", s.filterCardLabel)}>
                {author}
              </label>
            </li>
          )}

          {pageType !== "resourceFinderPage" &&
            (startDate && date ? (
              <li className={cn("date")}>{FormatDateRange(startDate, date)}</li>
            ) : date ? (
              <li className={cn("date")}>{FormatDateRange(date)}</li>
            ) : null)}

          {location && (
            <li className={cn("location")}>{t(location, { ns: "tags" })}</li>
          )}
          {readTime && (
            <li
              className={cn({
                [s.divider]: (author || date || location) && readTime,
              })}
            >
              {readTime + " " + t("minutesShort")}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default FilterCard;
