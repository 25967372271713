import React, { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import s from "./ListPressReleases.module.scss";
import WithSidebar from "../PageTemplates/WithSidebar";
import Share from "../../ui/Share";
import ShareItems from "../Share/components/ShareItems";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import Button from "../../ui/Button";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import Loader from "../Common/Loader";

const getQueryParam = (queryKey, router) => {
  const decodedUrl = decodeURIComponent(router.asPath);
  const match = decodedUrl.match(new RegExp(`[&?]${queryKey}=(.*)(&|$)`));
  return match ? match[1] : null;
};

const ListPressReleases = () => {
  const router = useRouter();
  const [iframeHeight, setIframeHeight] = useState(0);
  const [loading, setLoading] = useState(!!getQueryParam("s", router));
  const { Sidebar } = WithSidebar;
  const { t } = useTranslation(["pressreleasepage", "common"]);
  const { locale, globalLinks } = useViewmodel();
  const resolveLink = useLinks();

  const currentSlug = getQueryParam("s", router);
  const ref = useRef<HTMLIFrameElement>(null);
  const lastHeightRef = useRef(0);

  useEffect(() => {
    if (currentSlug) {
      setLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentSlug]);

  useEffect(() => {
    const handleResizeMessage = (event) => {
      if (event.data.type === "resizeIframe") {
        const newHeight = Math.max(100, event.data.height);

        if (Math.abs(newHeight - lastHeightRef.current) > 5) {
          lastHeightRef.current = newHeight;

          if (ref.current) {
            const heightDiff = Math.abs(newHeight - iframeHeight);
            ref.current.style.transition =
              heightDiff > 100 ? "none" : "height 0.2s ease-in-out";
          }

          setIframeHeight(newHeight);
        }
      }
    };

    window.addEventListener("message", handleResizeMessage);
    return () => window.removeEventListener("message", handleResizeMessage);
  }, [iframeHeight]);

  const handleIframeLoad = () => {
    setLoading(false);

    if (ref.current) {
      ref.current.style.transition = "none";
      setIframeHeight(500);
      lastHeightRef.current = 500;
    }
  };

  return (
    <div className={cn(s.ListPressReleases, c.xWide)}>
      {loading && <Loader size="small" variant="black" />}
      {currentSlug ? (
        <div className={s.iframeSidebarContainer}>
          <iframe
            className={cn(s.iframe, s.iframeWrapper)}
            title="Press Releases"
            src={`/press-releases/single.html?language=${locale}&slug=${currentSlug}`}
            ref={ref}
            style={{
              height: `${iframeHeight}px`,
              border: "none",
              opacity: loading ? 0 : 1,
            }}
            onLoad={handleIframeLoad}
          />
          <div className={s.sidebarWrapper}>
            <Sidebar>
              <Share>
                <ShareItems />
              </Share>
              <Button
                variant="cta"
                href={
                  globalLinks.pressreleaseFilterPage &&
                  resolveLink(globalLinks.pressreleaseFilterPage)
                }
              >
                {t("see-all-releases", { ns: "pressreleasepage" })}
              </Button>
            </Sidebar>
          </div>
        </div>
      ) : (
        <iframe
          className={s.iframe}
          title="Press Releases"
          src={`/press-releases/list.html?language=${locale}`}
          ref={ref}
          style={{
            height: `${iframeHeight}px`,
            border: "none",
            opacity: loading ? 0 : 1,
          }}
          onLoad={handleIframeLoad}
        />
      )}
    </div>
  );
};

export default ListPressReleases;
