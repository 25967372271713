import React from "react";
import Image from "next/image";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ParallaxCTABlockUi from "../../ui/ParallaxCTABlock";
import { ParallaxCTABlockBlock } from "../../lib/storyblok/types/blocks/ParallaxCTABlockBlock";
import Button from "../../ui/Button";
import VideoPlayer from "../../ui/Common/VideoPlayer";
import useMediaQuery from "../../hooks/useMatchMedia";
import WaveDivider from "../../ui/WaveDivider";
import { useLinks } from "../../lib/storyblok/useLinks";

const ParallaxCTABlock: StoryblokBlockComponent<ParallaxCTABlockBlock> = ({
  title,
  text,
  buttonText,
  buttonLink,
  video,
  featured,
  divider,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const resolveLink = useLinks();

  return (
    <ParallaxCTABlockUi
      title={title}
      text={text}
      button={
        buttonText &&
        buttonLink && (
          <Button href={resolveLink(buttonLink)} variant="inverted">
            {buttonText}
          </Button>
        )
      }
      video={video?.filename && <VideoPlayer videoUrl={video.filename} />}
      featured={featured}
      divider={
        divider && (
          <WaveDivider
            image={
              <Image
                priority
                alt=""
                src={`/images/divider_bottom${isMobile ? "_half" : ""}.png`}
                layout="fill"
                quality={100}
              />
            }
            flipped
          />
        )
      }
    />
  );
};

export default ParallaxCTABlock;
