import { useState } from "react";
import { useTranslation } from "next-i18next";
import { Locale } from "../lib/routing/locales";
import { Site } from "../lib/routing/site";
import { resolveSlug } from "../lib/utils/resolveSlug";
import { match } from "ts-pattern";
import { Story } from "../lib/storyblok/types/story";

export type Language = {
  locale: Locale;
  label: string;
  href: string;
  sites: Site[];
};

const buildLanguages = (
  languages: Pick<Language, "locale" | "label" | "sites">[],
  currentLocale: Locale,
  currentSite: Site,
  alternates: { full_slug: string }[] = [],
  currentSlug: string
): Language[] => {
  const alternateHrefs = alternates.reduce<{
    en?: string;
    zh?: string;
    ja?: string;
    es?: string;
    de?: string;
    fr?: string;
    sv?: string;
  }>((previous, { full_slug }) => {
    const [locale] = full_slug.split("/");
    return {
      ...previous,
      [locale]: full_slug,
    };
  }, {});

  return languages
    .filter((language) => language.sites.includes(currentSite))
    .map((language) => {
      const domain = match({ language, currentLocale, currentSite })
        .with(
          { currentSite: "investor" },
          () => process.env.NEXT_PUBLIC_INVESTOR_DOMAIN
        )
        .with(
          { currentSite: "main", language: { locale: "zh" } },
          () => process.env.NEXT_PUBLIC_CHINESE_DOMAIN
        )
        .otherwise(() => process.env.NEXT_PUBLIC_MAIN_DOMAIN);

      const url =
        currentLocale === language.locale
          ? resolveSlug(currentSlug)
          : resolveSlug(
              alternateHrefs[language.locale] ??
                `${language.locale}/${currentSite}/`
            );

      return {
        ...language,
        href:
          process.env.NEXT_PUBLIC_USE_DOMAIN_SPLIT === "TRUE"
            ? `${domain}${url}`
            : `${url}`,
      };
    });
};

const useLanguageSelect = (locale: Locale, site: Site, story: Story) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const locales: Pick<Language, "locale" | "label" | "sites">[] = [
    { locale: "en", label: t("english"), sites: ["main", "investor"] },
    { locale: "zh", label: t("chinese"), sites: ["main"] },
    { locale: "ja", label: t("japanese"), sites: ["main"] },
    { locale: "de", label: t("german"), sites: ["main"] },
    { locale: "fr", label: t("french"), sites: ["main"] },
    { locale: "es", label: t("spanish"), sites: ["main"] },
    { locale: "sv", label: t("swedish"), sites: ["investor"] },
  ];

  const alternates = story?.alternates ?? [];

  const languages = buildLanguages(
    locales,
    locale,
    site,
    alternates,
    story?.full_slug || ""
  );

  const handleLanguageSelect = (open: boolean) => () => setShow(open);

  return {
    show,
    setShow,
    handleLanguageSelect,
    languages,
  };
};

export default useLanguageSelect;
