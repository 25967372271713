import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import s from "../Speakers.module.scss";
import { ShareItem } from "../../Share";
import ExpandButton from "./ExpandButton";

type Props = {
  image: ReactNode;
  name: string;
  title: string;
  description: string;
  showMoreLabel: string;
  showLessLabel: string;
  linkedinLink?: string;
};

const LINE_HEIGHT = 18;

const Speaker = ({
  image,
  name,
  title,
  description,
  showMoreLabel,
  linkedinLink,
  showLessLabel,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);

  const descRef = useRef<HTMLParagraphElement>(null);

  const setMaxHeight = useCallback(() => {
    const p = descRef.current;

    if (p) {
      p.style.minHeight = "0";
      p.style.maxHeight = LINE_HEIGHT * 5 + "px";
      p.style.minHeight = LINE_HEIGHT * 5 + "px";

      if (p.scrollHeight > LINE_HEIGHT * 5) {
        setShowExpandButton(true);
      } else {
        setShowExpandButton(false);
      }
    }
  }, [setShowExpandButton]);

  useEffect(() => {
    setMaxHeight();
    window.addEventListener("resize", setMaxHeight);
    return () => window.removeEventListener("resize", setMaxHeight);
  }, [setMaxHeight]);

  useEffect(() => {
    const p = descRef.current;

    if (p)
      if (isExpanded) {
        p.style.webkitLineClamp = "unset";
        p.style.maxHeight = `${p.scrollHeight}px`;
      } else {
        p.style.maxHeight = LINE_HEIGHT * 5 + "px";
      }
  }, [isExpanded]);

  return (
    <li className={s.speaker}>
      <div className={s.details}>
        {image && <div className={s.image}>{image}</div>}
        <div className={s.detailsWrapper}>
          <p className={s.name}>{name}</p>
          <p className={s.role}>{title}</p>
          {linkedinLink && (
            <ul className={s.share}>
              {linkedinLink && (
                <ShareItem icon="linkedin" href={linkedinLink} />
              )}
            </ul>
          )}
        </div>
      </div>
      <p
        ref={descRef}
        className={cn(s.description, {
          [s.noExpandButton]: !showExpandButton,
        })}
      >
        {description}
      </p>
      {showExpandButton && (
        <ExpandButton
          text={isExpanded ? showLessLabel : showMoreLabel}
          isExpanded={isExpanded}
          toggleExpanded={() => setIsExpanded(!isExpanded)}
        />
      )}
    </li>
  );
};

export default Speaker;
