import React, { HTMLAttributes } from "react";
import cn from "classnames";
import s from "../TechSpec.module.scss";
import Icon from "../../Icon";

type Props = HTMLAttributes<HTMLButtonElement> & {
  isAllExpanded: boolean;
  inverted?: boolean;
};

const ExpandButton = ({
  isAllExpanded,
  inverted,
  children,
  ...props
}: Props) => (
  <button
    {...props}
    className={cn(s.expandButton, {
      [s.open]: isAllExpanded,
    })}
    data-testid="expand-button"
  >
    {children}
    <Icon
      icon={isAllExpanded ? "minus" : "add"}
      width={10}
      color={inverted ? s.iconColorInverted : s.iconColor}
      className={s.icon}
    />
  </button>
);

export default ExpandButton;
