import React from "react";
import cn from "classnames";
import s from "../VideoCarousel.module.scss";
import Video from "../../../../components/Nestable/Video";
import BlockBackground from "../../../Common/BlockBackground";
import { useContentStyle } from "../../../../hooks/useStyle";
import BlockIntro from "../../../Common/BlockIntro";

const CarouselItemVideo = ({ title, description, video, style }) => {
  const isInverted = useContentStyle(style);

  if (
    style === "gradientRadialTopBlueSignature" ||
    style === "gradientRadialCenterBlueSignature" ||
    style === "gradientLinearBlueSignature"
  ) {
    style = "blue";
  }

  return (
    <>
      <div className={cn(s.item, { [s.inverted]: isInverted })}>
        <div className={cn(s.videoContainer)}>
          <Video
            video={video}
            _uid={video[0]?._uid}
            _editable={video[0]?._editable}
            component={"video"}
          />
        </div>

        <div className={cn(s.inner)}>
          <div className={s.content}>
            <BlockBackground style={style} opacity={100} />
            <BlockIntro
              title={title}
              description={description}
              descriptionSize={"small"}
              headingTag={"h4"}
              alignment={"alignLeft"}
              width={"full"}
              inverted={isInverted}
              spaceHorizontal={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselItemVideo;
