import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ColumnGridImageItemBlock } from "../../lib/storyblok/types/blocks/ColumnGridImageItemBlock";
import ColumnGrid from "../../ui/ColumnGrid";
import ImageVaultImage from "../ImageVaultImage";
import { useLinks } from "../../lib/storyblok/useLinks";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";
import Button from "../../ui/Button";
import { useContentStyle, useButtonStyle } from "../../hooks/useStyle";

const { Item } = ColumnGrid;

const ColumnGridImageItem: StoryblokBlockComponent<
  ColumnGridImageItemBlock
> = ({
  _uid,
  image,
  title,
  markdown,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
  imageKeepProportions,
}) => {
  const resolveLink = useLinks();
  const effectiveStyle = style || "none";
  const { buttonVariant } = useButtonStyle(effectiveStyle);
  const contentStyle = useContentStyle(style);
  const isInverted = style === "" || style === undefined ? false : contentStyle;

  return (
    <Item
      key={_uid}
      image={
        isValidImageData(image) ? (
          <ImageVaultImage
            image={image}
            layout="fill"
            sizes={imageSizes("100vw", { tablet: "640px" })}
          />
        ) : null
      }
      title={title}
      markdown={markdown}
      imageKeepProportions={imageKeepProportions}
      inverted={isInverted}
    >
      {buttonText && buttonLink && (
        <Button href={resolveLink(buttonLink)} variant={buttonVariant}>
          {buttonText}
        </Button>
      )}
      {secondButtonText && secondButtonLink && (
        <Button href={resolveLink(secondButtonLink)} variant={buttonVariant}>
          {secondButtonText}
        </Button>
      )}
    </Item>
  );
};

export default ColumnGridImageItem;
