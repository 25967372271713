import { useTranslation } from "next-i18next";
import Link from "next/link";
import { BreadCrumbsViewModel } from "../../lib/viewModel/createBreadcrumbsViewModel";
import Breadcrumbs from "../../ui/Breadcrumbs";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";

const BreadCrumbsUi = ({ crumbs }: BreadCrumbsViewModel) => {
  const { t } = useTranslation();
  const { site } = useViewmodel();
  return (
    <Breadcrumbs site={site}>
      {crumbs.map(({ href, name, isRoot }, i) => (
        <Link key={`${href}-${i}`} href={href} prefetch={false} title={name}>
          {isRoot ? t("navigation-root") : name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadCrumbsUi;
