/* eslint-disable */
import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { useRefinementList } from "react-instantsearch";
import FilterList from "../../ui/FilterList";

type FacetId =
  | "types"
  | "product"
  | "solution"
  | "other"
  | "languages"
  | "categories"
  | "availability";

interface RefinementFilterListProps {
  id: FacetId;
  attribute: string;
  sort?: string;
  hiddenFacets?: Array<FacetId | string>;
}

const RefinementFilterList = ({
  id,
  attribute,
  sort = "",
  hiddenFacets = [],
}: RefinementFilterListProps) => {
  const { t } = useTranslation(["searchpage", "tags"]);

  if (hiddenFacets.includes(attribute)) {
    return null;
  }

  const translateLabel = useCallback(
    (items) => {
      items = items.map((item) => ({
        ...item,
        label: t(`${item.value}`, { ns: "tags" }),
      }));
      if (sort === "reverse") return items.reverse();
      return items;
    },
    [sort]
  );

  const { items, refine } = useRefinementList({
    limit: 99,
    attribute,
    transformItems: translateLabel,
  });

  const visibleItems = items.filter(
    (item) => !hiddenFacets?.some((facet) => facet === item.value)
  );

  return (
    <FilterList key={`filterList-${id}`} label={t(`categories.${id}`)}>
      {visibleItems.map(({ label, count, value, isRefined }) => (
        <FilterList.Filter
          key={`${id}-${value}`}
          amount={count}
          value={value}
          checked={isRefined}
          onChange={() => refine(value)}
        >
          {label}
        </FilterList.Filter>
      ))}
    </FilterList>
  );
};
export default RefinementFilterList;
